.modal-content {
  .file-pdf-none {
    display: none;
  }

  .btn-blue.btn-modal-employee:disabled {
    opacity: 0.65;
  }

  .btn-blue.btn-modal-employee:not(:disabled) {
    cursor: pointer;
  }

  .input-employee-infos {
    margin-bottom: 10px;
  }

  .btn-blue.btn-modal-employee {
    width: 48%;
    margin-top: 0px;
  }

  .btn-white.btn-modal-employee {
    width: 48%;
    margin-top: 0px;
  }

  .tooltip-link {
    .tooltip-pool-employee {
      left: -7px;
      width: 300px;
      top: 120px;
    }
  }

  .call-email-employee {
    margin-top: 15px;
  }

  .wants-callback {
    display: flex;

    label {
      font-size: 12px;
      margin-top: 0px;
      margin-left: 5px;
    }

    input {
      opacity: 25%;
      width: 12px !important;
      height: 12px !important;
      margin: 5px;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance:none;
      outline: 2px solid $blue-500;
      box-shadow: none;
      font-size: 2em;

      &::after {
        content: '';
        display: none;
        width: 5px;
        height: 10px;
        border: solid $blue-500;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        margin-left: 2px;
      }

      &:checked {
        &::after {
          display: block;
        }
      }
    }
  }
}
