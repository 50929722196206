
.about-main-pic {
  width: 300px;
  margin-top: 1.5rem;
}

.about-image-feature {
  width: 110px;
}

.about-image-brand-col {
  width: 150px;
  margin-right: 3px;
  margin-left: 3px;
}

.about-img-link-video {
  width: 250px;
  margin-right: 3px;
  margin-left: 3px;
}

.about-timeline-image {
  width: 340px;
}


.about-margins {
  margin: 30px 0px;
}

.about-margin-top {
  margin-top: 30px;
}
