/* MENU */
// COLOR SELECTOR
// FONT FAMILY SELECTOR
// UTILITIES
// GLOBAL WRAPPER
// TOP WRAPPER
//  - PHOTO
//  - STYLE & POSITION
// BOTTOM WRAPPER
//  - SUB-SECTION
//  - STYLE & POSITION
//  - FONT FAMILY STYLE
//  - FONT FAMILY AMATIC
//  - FONT FAMILY CAVEAT

/* COLOR SELECTOR */
.announcement-pdf-color-white {
  color: white;
}
.announcement-pdf-color-black {
  color: black;
}
.announcement-pdf-color-bordeaux {
  color: #9a3434;
}
.announcement-pdf-color-brown {
  color: #9a7123;
}

/* FONT FAMILY SELECTOR */
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Style+Script&display=swap");
.announcement-pdf-family-style {
  p {
    font-family: "Style Script" !important;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');
.announcement-pdf-family-amatic {
  p {
    font-family: 'Amatic SC', cursive !important;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600&display=swap');
.announcement-pdf-family-caveat {
  p {
    font-family: 'Caveat', cursive  !important;
  }
}

/* UTILITIES */
.text-center {
  text-align: center;
}

/* GLOBAL WRAPPER */
.announcement-pdf-container {
  height: 296.9mm;
  width: 210mm;
  background-size: cover;
  box-sizing: border-box;
  position: absolute;
  line-height: normal;
}

/* TOP WRAPPER */
.announcement-pdf-wrapper-top {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 178.9mm;

  /* PHOTO */
  .announcement-pdf-photo {
    height: 50%;
    width: 65%;
    border-radius: 5%;
    margin: 0.5rem;
    object-fit: cover;
    object-position: center;
  }

  /* STYLE & POSITION */
  .announcement-pdf-message {
    font-size: 30pt;
    margin-top: 30mm;
    margin-left: 20mm;
    margin-right: 20mm;
    margin-bottom: 5mm;
  }

  .announcement-pdf-names {
    font-size: 40pt;
    margin-top: 5mm;
    margin-bottom: 0;
  }
}

/* BOTTOM WRAPPER */
.announcement-pdf-wrapper-bottom {
  display: flex;
  align-items: end;
  flex-direction: column;
  text-align: right;
  height: 118mm;

  /* MARGIN */
  .announcement-pdf-margin-middle {
    margin-top: 10mm;
    padding-right: 10mm;
    margin-bottom: 0;
  }

  /* The position is managed according to the font family */
  /* class name is announcement-pdf-margin-bottom-THE_FONT */

  /* FONT FAMILY STYLE */
  .announcement-pdf-margin-bottom-style {
    margin-top: 10mm;
    padding-right: 33mm;

    /* SPECIAL MARGIN */
    .announcement-pdf-margin {
      margin-top: 6mm;
      font-size: 25pt;
      margin-bottom: 0;
    }
  }

  /* FONT FAMILY AMATIC */
  .announcement-pdf-margin-bottom-amatic {
    margin-top: 16mm;
    padding-right: 33mm;

    /* SPECIAL MARGIN */
    .announcement-pdf-margin {
      margin-top: 10mm;
      font-size: 25pt;
      margin-bottom: 0;
    }
  }

  /* FONT FAMILY CAVEAT */
  .announcement-pdf-margin-bottom-caveat {
    margin-top: 16mm;
    padding-right: 33mm;

    /* SPECIAL MARGIN */
    .announcement-pdf-margin {
      margin-top: 10mm;
      font-size: 25pt;
      margin-bottom: 0;
    }
  }

  /* STYLE & POSITION */
  .announcement-pdf-birthinfo {
    font-size: 25pt;
    margin: 0;
  }
}
