h1,
h2,
h3,
h4 {
  color: $apricot;
  font-family: $headers-font;
  font-weight: bold;
  text-align: center;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1.125rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  font-size: 1rem;
  font-family: $body-font;
}

a,
.link {
  color: $apricot;
  font-family: $headers-font;
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &.text-light {
    color: $middle-gray;
  }

  p {
    font-weight: normal;
  }
}

.link-grassy {
  color: $grass;
}

.link {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
}

.font-s {
  font-size: small;
}

.font-l {
  font-size: large;
}

.font-xl {
  font-size: larger;
}

.font-xxs {
  font-size: 10px;
}

.font-xxl {
  font-size: xx-large;
}

.active-bookings {
  a {
    color: $grass !important;
  }
}
