// NAVIGATION //
$navbar-height: 75px;
// Web
$menu-height-big: 9vh;

// MAIN //
$page-container-padding-height: 1vh;
$page-container-padding-width: 2vw;

$page-container-padding: $page-container-padding-height $page-container-padding-width;
$page-container-negative-padding: #{-$page-container-padding-height} #{-$page-container-padding-width};

$page-container-height: 100vh - ($page-container-padding-height * 2);
$page-container-width: 100vw - ($page-container-padding-width * 2);

// Desktop
$desktop_page-container-height: calc(#{$page-container-height} - $navbar-height);
$desktop_page-container-height_no-padding: calc(100vh - $navbar-height);
// Mobile
$mobile_page-container-height: calc(#{$page-container-height} - #{$navbar-height * 2});
$mobile_page-container-height_no-padding: calc(100vh - #{$navbar-height * 2});
