.journal-week-list {
  overflow-y: scroll;
  max-height: calc(100vh - 19.5rem);
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    padding: 0.2rem 0;
    font-weight: normal;
  }

  a.pending {
    color: $dark_gray;
  }

  a.approved {
    color: $middle_gray;
  }

  a.approved::after {
    content: "✓";
    border: $border-small $grass solid;
    height: 1rem;
    display: inline-flex;
    border-radius: 50%;
    width: 1rem;
    justify-content: center;
    align-items: center;
    color: $white;
    background: $grass;
    margin-left: 0.2rem;
  }
}

.week-name {
  width: 100;
  font-weight: bold;
  text-align: center;
}

.edit-journal-wrapper {
  padding: 0 0.85rem;
  height: 100%;
}

.day-book-entries {
  margin-bottom: 2rem;

  h3 {
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
}

.book-entry-card {
  margin-bottom: 2rem;

  img {
    margin-bottom: 0.5rem;
  }

  label {
    position: relative;
  }

  fieldset input[type="checkbox"] + label::after {
    content: "";
    width: 80%;
    height: $border-big;
    position: absolute;
    top: 42%;
    background: $dark_gray;
    left: 10%;
    transform: rotate(-45deg);
  }

  fieldset input[type="checkbox"]:checked + label::after {
    content: none;
  }

  .book-entry-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    .ownership-status {
      display: flex;
      font-weight: bold;

      p {
        font-weight: 600;
        margin: 0;
      }
    }

    .content-status {
      color: $apricot;
    }

    .content-status.excluded {
      color: $dark_gray;
      text-decoration: line-through;
      font-weight: normal;
    }
  }

  .book-entry-text {
    padding: 0 1rem;
    border-left: $border-small $middle_gray solid;

    &.no-border {
      border: none;
    }

    p {
      font-size: 0.85rem;
    }

    .entry-content {
      margin-top: 0;
    }

    .entry-content.clamped {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow-y: hidden;
    }

    .entry-title {
      font-weight: bold;
      margin: 0;
    }
  }

  .book-entry-text.excluded {
    color: $middle_gray;

    .clamped {
      -webkit-line-clamp: 1;
    }
  }

  fieldset input[type="checkbox"] + label > img {
    border-radius: $component-radius-medium;
    border: $border-big solid transparent;
    max-width: 8rem;
    max-height: 8rem;
    filter: grayscale(1);
  }
  fieldset input[type="checkbox"]:checked + label > img {
    border: $border-big solid $apricot;
    filter: grayscale(0);
  }

  input[name="book_entry[include_photos]"] {
    display: none;
  }

  input[type="submit"] {
    color: $dark_gray;
    font-weight: normal;
    font-size: 0.85rem;
  }

  .content-status {
    padding: 0 0.5rem;
  }
}
