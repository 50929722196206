#book_pay_wrapper {
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  overflow: visible;

  .page-splitter {
    box-sizing: border-box;
    position: relative;

    overflow-x: visible;

    .btn-return-to-top {
      height: 32px;
      width: 32px;

      padding: 0;
      margin: 0;

      display: none;
      justify-content: center;
      align-items: center;

      position: fixed;
      top: #{$navbar-height + 16px};
      right: 16px;
      z-index: 100;

      background-image: $sunny;
      border: none;

      i {
        color: $white;
        padding-bottom: 5px;
      }
    }

    &.for-book-preview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      overflow: hidden;

      #full_book_wrapper {
        height: 100%;
        width: 100%;
        margin: 0 auto;

        box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.5) inset;

        .full-book-preview {
          height: 100%;
          width: 100%;
          transform-origin: top left;
        }
      }
    }
  }

  @media(max-width: 990px) {
    flex-direction: column;

    .page-splitter {
      min-height: $mobile_page-container-height;
      width: 100%;

      &.for-book-preview {
        min-height: $mobile_page-container-height_no-padding;

        h3 {
          margin-top: 0;
        }

        #full_book_wrapper {
          overflow: auto;
        }
      }
    }

    #pay_modal_wrapper {
      .simple_form.book_payment {
        .introduction {
          h1 {
            margin: 0;
            margin-bottom: 0.5em;
            padding: 0 32px;
          }

          p {
            font-size: 0.8em;

            &:first-of-type {
              font-size: 1em !important;
            }
          }
        }
      }
    }
  }

  @media(min-width: 991px) {
    height: $mobile_page-container-height_no-padding;
    margin: -1vh 0;

    flex-direction: row;
    overflow: hidden;

    .page-splitter {
      height: 100%;
      width: 50%;

      overflow: auto;

      &.for-book-preview {
        box-sizing: content-box;

        order: 0;

        height: $mobile_page-container-height_no-padding;
        margin: -1vh 0;

        position: relative;
        top: 1vh;

        background-color: $white;
        box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2) inset,
                    0 0 15px hsla(0, 0%, 0%, 0.1) inset;
        
        overflow: auto;

        h3 {
          display: none;
        }
      }

      &.for-pay-modal {
        order: 1;

        height: 80%;
        margin: auto 0;

        overflow: visible;

        #pay_modal_wrapper {
          .btn-return {
            height: 48px;
            width: 48px;
            top: -20px;
            left: -26px;
          }
        }
      }

      .btn-return-to-top {
        display: none;
      }
    }
  }

  #pay_modal_wrapper {
    box-sizing: border-box;

    min-width: 280px;
    max-width: 430px;
    width: 100%;
    min-height: 390px;
    height: 100%;

    margin: 0 auto;
    padding: 16px;

    border-radius: 32px;

    border: none;
    box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.2),
                0 1px 2px hsla(0, 0%, 0%, 0.2);

    position: relative;

    display: flex;
    flex-direction: column;

    .btn-return {
      height: 32px;
      width: 32px;

      margin: 0;
      padding: 0;

      position: absolute;
      top: 12px;
      left: 12px;

      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      background-image: $sunny;
      color: $white;
    }

    .simple_form.book_payment {
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .introduction {
        h1 {
          margin: 0;
          margin-bottom: 0.5em;
          padding: 0 32px;
        }

        p {
          margin: 0 0 0.2em;
          padding: 0 16px;

          text-align: center;
          font-weight: 100;

          &:first-of-type {
            margin-bottom: 0.5em;

            font-size: 1.2em;
            font-weight: bold;
            color: $apricot;
          }
        }
      }

      .pay-section {
        width: 100%;
        margin: 0 auto 12px;

        display: flex;
        flex-direction: column;

        em {
          font-style: normal;
          font-weight: 600;

          color: $apricot;
        }

        > em {
          margin-bottom: 0.3em;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.address-inputs {
          .form-group {
            margin: 0;
          }
        }

        &.command-detail {
          .price-detail {
            max-height: 11vh;
            width: 80%;

            margin-left: auto;
            padding-right: 12px;

            overflow-y: auto;

            @media(max-height: 630px) {
              max-height: 11vh;
            }

            @media(min-height: 631px) and (max-height: 750px) {
              max-height: 14vh;
            }

            @media(min-height: 751px) {
              max-height: 20vh;
            }
          }
        }

        &.address-go-back {
          a {
            @media(max-width: 540px) {
              $oversize-x: 26px;

              width: calc(100% + $oversize-x * 2); 
              left: -$oversize-x;
              right: -$oversize-x;
            }

            @media(min-width: 541px) {
              $oversize-x: 36px;

              width: calc(100% + $oversize-x * 2); 
              left: -$oversize-x;
              right: -$oversize-x;
            }

            box-sizing: border-box;

            position: relative;

            margin: 0 auto;

            border-radius: 12px;

            padding: 10px;

            border: 2px solid $middle_gray;
            background-color: $white;

            span {
              display: flex;
              flex-direction: column;

              font-size: 0.9rem;
              line-height: 1.1em;

              p {
                margin: 0;

                font-size: 0.9rem;
                font-weight: 100;
                color: $dark_gray;
              }

              em {
                line-height:  0.9em;
                text-align: right;
                align-self: end;
              }
            }
          }
        }

        &.no-logo-checkbox {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;

          label {
            display: flex;
            flex-direction: column;

            p {
              margin: 0;

              &:first-child {
                font-size: 1.2rem;

                color: $dark_apricot;
              }

              &.price {
                align-self: end;
                font-style: italic;
                font-weight: normal;
                font-size: 1.2rem;

                color: $gray_850;

                &::before {
                  content: "+";
                  font-size: 1rem;
                }
              }
            }
          }

          input {
            display: block;
            transform: scale(2);
          }
        }

        &.final-price {
          box-sizing: content-box;
          width: 70%;
          margin: 0 auto;
        }
      }

      .btn-submit {
        box-sizing: border-box;

        width: 80%;

        margin: 0.8em auto 0;
        padding: 0.4em;

        font-size: 1.6rem !important;
        text-align: center;
        line-height: 1.25em;
      }
    }
  }
}
