.squared-text-background {
  padding: 0.1rem 0.5rem;
  border-radius: $component-radius;
  color: white;
}

.squared-text-background-apricot {
  background: $dark_apricot;
}
.squared-text-background-grass {
  background: $dark_grass;
}
.squared-text-background-sunny {
  background: $sun_510;
}
.squared-text-background-baby-blue {
  background: $baby_blue;
}
