.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-end {
  align-content: flex-end;
}

.flex-start {
  align-content: flex-start;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.align-self-center {
  align-self: center;
}

.justify-self-center {
  justify-self: center;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-s {
  gap: 0.5rem;
}
.gap-m {
  gap: 1rem;
}
.gap-l {
  gap: 1.5rem;
}
