.create-profile {
  text-align: center;
  margin: 30px;

  .choice-profile-link {
    font-size: 12px;
    color: $apricot;
    width: 280px;
    background-color: $light_apricot;
    margin: auto;
    border: none;
    height: 34px;
    border-radius: 8px;
    margin-top: 15px;
  }

  .fa-thumbs-up {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .btn-back-profile {
    font-weight: bold;
    font-size: 20px;
    background-color: #FFF4EA;
    width: 23px;
    text-align: center;
    padding-right: 10px;
    padding-left: 18px;
    height: 36px;
    border-radius: 50%;
  }


  .text-btn-choice {
    font-size: 12px;
    color: $middle_gray;
  }

  .progress-container {
    width: 100%;
    background-color: $light_apricot;
    border-radius: 8px;
    margin-bottom: 50px;
  }

  .progress-bar {
    width: 33%;
    height: 4px;
    background-color: $apricot;
    border-radius: 10px;
  }

  .second-progress-bar {
    width: 66%;
    height: 4px;
    background-color: $apricot;
    border-radius: 10px;
  }

  .progress-text {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .fa-envelope-open {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fa-hand-holding-heart {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .btn-start {
    border-radius: 8px;
    background: linear-gradient(to right, $apricot, $peach);
  }

  .user_birthday {
    div {
      width: 100%;

      .input-birthday {
        width: 100% !important;
      }
    }

  }

  .baby_birthday {
    div {
      width: 100%;

      .baby-birthday {
        width: 100% !important;
      }
    }

  }

  h1 {
    color: $black;
    font-size: 18px;
  }

  p {
    color: $middle_top_grey;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 30px;
  }
}
