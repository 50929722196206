// preg announcement

/* SKINS PREVIEW RADIO BUTTONS */
.skin-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.skin-wrapper input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.skin-wrapper label {
    display: block;
    cursor: pointer;
    width: 141px;
    height: 199px;
    padding: 5px;
    border: 1px solid #EAEAEA;
    border-radius: 4px;
}

.skin-wrapper label:hover {
  background-color: $light_apricot;
}

.skin-wrapper input[type="radio"]:checked + label {
  background-color: $apricot_200;
  border-color: $apricot;
}

.announcement-container {
  width: 100%;
  height: 100%
}

.announcement {
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.announcement-card {
  box-shadow: $grey-box-shadow;
  height: 98vmin;
  width: 98vmin;
  max-width: 30rem;
  max-height: 30rem;
  margin-bottom: 1rem;
  background-size: cover;
  padding: 1rem;
  box-sizing: border-box;

  &.loss {
    background-image: cdn_path('showcase_site_assets/testimony_card/green_without_logo.jpg');
  }

  &.birth {
    background-image: cdn_path('showcase_site_assets/testimony_card/apricot_without_logo.jpg');
  }
}

.announcement-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: white;
  height: 100%;
  width: 100%;

  p {
    margin: 0.5rem;
  }

  .logo {
    display: flex;
    background: $white;
    padding: 0.1rem 0.4rem;
    border-radius: $component-radius-big;
  }

  .signature {
    display: flex;
    align-items: left;
    justify-content: center;
    width: 100%;

    span {
      margin-right: 0.5rem;
    }
  }
}

.announcement-photo {
  height: 55%;
  width: 80%;
  border-radius: 10%;
  margin: 0.5rem;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1;
}

.announcement-logo {
  height: 0.7rem;
}

.baby-names {
  margin: 0;
  color: $white;
}

.links-group {
  text-align: center;
}

.links-group a {
  display: inline-block;
  margin: $margin-small auto;
}

.welcome-baby {
  text-align: center;
  margin-bottom: 2rem;
}

.create-announcement {
  text-align: center;

  img {
    width: 55%;
    box-shadow: $grey-box-shadow;
    border-radius: $component-radius-big;
  }

  .main-cta {
    margin-top: 2rem;
  }
}
