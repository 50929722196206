@import "components/journal_description";
@import "components/pricing_description";

.book-guide-section {
  max-width: 850px;
  margin: 0 auto 4rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  span > {
    p, ol {
      li {
        margin: 0.2em 0;
        margin-left: 2em;
      }
    }

    h3 {
      margin-bottom: 0.5em;
    }

    p {
      margin: 0.5em 0;
      text-align: center;
    }

    ol {
      margin-bottom: 0;
    }
  }

  .cards-container {
    margin: 0;

    .card {
      p {
        text-align: left;
        letter-spacing: 0.05em;
      }
    }
  }


  .table {
    margin: 0 auto;

    p {
      margin: 0.5em;
    }

    tbody {
      th {
        .side-header {
          font-size: 0.8em;
        }
      }
    }
  }

  @media(max-width: 642px) {
    margin-bottom: 2rem;

    span {
      ol {
        padding: 0;
      }

      p {
        text-align: justify;
        margin: 0.2em;
      }

      .cards-container {
        .card {
          margin: 1rem;
          height: initial;
          min-height: 17rem;

          p {
            text-align: justify;
            word-spacing: -2px;
          }
        }
      }

      h3 {
        margin-top: 1rem;
      }
    }
  }
}
