// Absolute positions within an A4 page

.cover {
  .date, .title, .subtitle, .author, .logo {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 2;
  }

  .date, .title {
    text-align: center;
    justify-content: center;
    line-height: 1;
  }

  .logo {
    left: 66mm;
    top: 272mm;
    width: 77mm;
    height: 18.5mm;
    object-fit: contain;
  }
}

.cover.photo-variant {
  .date {
    left: 50%;
    top: 12.6mm;
    width: 53mm;
    height: 7.5mm;

    transform: translateX(-50%);
  }
  .title {
    left: 58mm;
    top: 208mm;
    width: 128mm;
    height: 18mm;
  }
  .subtitle {
    left: 96mm;
    top: 226.3mm;
    width: 90mm;
    height: 9mm;
  }
  .author {
    left: 96mm;
    top: 235.3mm;
    width: 90mm;
    height: 9mm;
  }
  .photo {
    position: absolute;
    left: 27.7mm;
    top: 64.6mm;
    width: 110mm;
    height: 154mm;
    object-fit: cover;
  }
}

.cover.text-variant {
  .date {
    left: 78.7mm;
    top: 205mm;
    width: 53mm;
    height: 12mm;
  }
  .title {
    left: 40.8mm;
    top: 128mm;
    width: 128.4mm;
    height: 19mm;
  }
  .subtitle {
    left: 40.8mm;
    top: 147mm;
    width: 128.4mm;
    height: 14.5mm;
    text-align: center;
    justify-content: center;
  }
  .author {
    left: 40.8mm;
    top: 163mm;
    width: 128.4mm;
    height: 10.6mm;
    text-align: center;
    justify-content: center;
  }
  .photo {
    display: none;
  }
}
