.slider-team {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-snap-align: start;
  .slide {
    width: 80%;
    min-height: 600px;
    box-shadow: 5px 5px 15px 5px rgba(254, 179, 114, 0.2);
    border-radius: $component-radius;
    padding: 1rem 2rem;
    margin: 1rem 1rem;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 3rem;
    .slide-picture {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .slide-description {
      h3,
      p {
        text-align: justify;
      }
    }
  }
}
.slider-team::-webkit-scrollbar-track {
  background: transparent;
}
.slider-team::-webkit-scrollbar {
  width: 0.625rem;
  height: 0.625rem;
}
.slider-team::-webkit-scrollbar-thumb {
  background: $apricot;
  border-radius: $component-radius;
}
.slider-team::-webkit-scrollbar-track {
  background: transparent;
}

@media (max-width: 780px) {
  .slide {
    flex-direction: column;
  }
}

.slider-pro {
  width: 80%;
  height: auto;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-snap-align: start;
  .slide {
    width: 80%;
    min-height: 200px;
    border: 1px solid $grass;
    border-radius: $component-radius;
    padding: 1rem 2rem;
    margin: 1rem 1rem;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 3rem;
    .slide-picture {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3 {
        text-align: center;
        color: $dark_gray;
      }
    }
  }
}

.slider-minimal {
  width: 100%;
  height: auto;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-snap-align: start;
  margin-bottom: 2rem;
}
