
.bordered-container {
  margin: $margin-large auto;
  border: 1px solid $grass;
  border-radius: 10px;
  border-radius: $component-radius;
  padding: 1rem 2rem;
  line-height: 1.5rem;
  width: 70%;
  h1 {
    color: $dark_gray;
  }
}


.filter-container {
  margin: 1.5rem 0.5rem 0.5rem 0.5rem;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
  border-radius: $component-radius;
  padding: 0.5rem;
  line-height: 1.5rem;
  p {
    text-align: center;
  }
}
