.outline-key {
  align-items: center;
  justify-content: space-around;

  i {
    margin-right: 8px;
  }

  p {
    font-size: 0.75rem;
  }

  .fa-pen-fancy {
    color: $dark-apricot;
  }

  .fa-eye {
    color: $apricot;
  }
}

.edit-outline-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.6rem;
  border-radius: $component-radius;
  position: relative;
  font-weight: bold;
  margin: 0.2rem 0;
  margin-right: 1rem;
  background: $white;
  color: $dark_gray;

    .outline-item-icon {
      width: 2rem;
      height: 2rem;
      position: absolute;
      right: -1rem;
      top: calc((2.6rem - 2rem - (2 * $outline-medium-width)) / 2);
      background: $white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

  &.done {
    border: $outline-medium-width solid $grass;
    color: $middle_gray;
    background: $light_gray;

    .outline-item-icon {
      border: $outline-medium-width solid $grass;
      color: $grass;
    }
  }

  &.write {
    border: $outline-medium-width solid $dark-apricot;

    .outline-item-icon {
      border: $outline-medium-width solid $dark-apricot;
      color: $dark-apricot;
    }
  }

  &.approve {
    border: $outline-medium-width solid $apricot;

    .outline-item-icon {
      border: $outline-medium-width solid $apricot;
      color: $apricot;
    }
  }

  p {
    margin: 0;
    padding: 0 0.5rem;
  }

  &.section-edit {
    font-size: 0.75rem;
    height: 2rem;
    margin-right: 2rem;
    margin-left: 1rem;

    .outline-item-icon {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}
