// General form class
form.simple_form {
  display: flex;
  flex-direction: column;

  &.hidden {
    display: none;
  }
}

label,
legend {
  color: $dark_gray;
  font-family: $headers-font;
  font-size: 0.88em;
  font-weight: bold;
  margin: 1vh;
  margin-left: 0;
}

abbr[title] {
  text-decoration: none;
}

.input-date {
  width: 30%;
  margin-right: 10px;
}

.custom-input {
  color: $dark_gray;
  font-family: $body-font;
  font-size: 1rem;
  font-weight: normal;
  padding: 1vh 0;
  outline: none;
  border: none;
  border-bottom: $border-small solid $middle_gray;
  margin-bottom: 0.5rem;
  width: 100%;
  flex-grow: 1;
  background: $white;
}

.custom-input:focus {
  outline: none;
  border-bottom: $border-big solid $apricot;
}

.custom-input::placeholder {
  color: $middle_gray;
}

.invalid-feedback {
  font-size: small;
  font-family: $body-font;
  font-weight: normal;
  color: $apricot;
}

.errors {
  color: $dark-apricot;
}

.form-check {
  min-height: 5vh;
  display: flex;
  align-items: center;
  border: none;

  .no-btn {
    display: none;
  }
}

textarea {
  min-height: 3vh;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
}

.center-action {
  display: flex;
  justify-content: center;
}

// Form new baby

.form-inputs-baby {
  padding: 0 1rem;
}

// Outline border form
.outline-group {
  border: $border-small solid $middle_gray;
  border-radius: $component-radius-big;
  overflow: hidden;
  margin: 2vh 0;
  align-items: center;

  label {
    margin-right: 1rem;
  }

  .custom-input {
    color: $dark_gray;
    font-family: $body-font;
    font-size: 0.88rem;
    font-weight: normal;
    margin: 0;
    outline: none;
    border: none;
    width: auto;
  }
}

.outline-group:focus-within {
  border: $border-medium solid $apricot;
}

.apricot-outline-group {
  border: $border-medium solid $apricot;

  .custom-input {
    margin: 0 $margin-medium;
  }
}

// Column form
.column-group {
  // margin: 2vh 0;
  display: flex;
  flex-direction: column;
}

// Line form
.line-group {
  margin: 2vh 0;
  display: flex;
  flex-direction: row;

  .inline-input {
    flex-grow: 5;
  }
}

.wrapper-inline {
  padding: 0 0.85rem;
  width: auto;
}

.form-inline {
  width: auto;

  form.simple_form {
    display: flex;
    flex-direction: row;
  }

  input {
    margin: 0 1vw;
  }
}

// Check-boxes form
.checkbox-group,
.form-group.boolean {
  span {
    display: flex;
    align-items: center;
  }

  input {
    margin: 0;
    margin-right: 1rem;
    padding: 0;
    height: 1rem;
  }

  input[type="checkbox"]:before {
    position: relative;
    display: flex;
    height: 1rem;
    width: 1rem;
    top: -0.25rem;
    border: $border-medium solid $middle_gray;
    content: "";
    background: $white;
  }

  input[type="checkbox"]:not(:disabled):checked:after {
    position: relative;
    display: flex;
    height: 1rem;
    width: 1rem;
    justify-content: center;
    align-items: center;
    top: -1.5rem;
    border: $border-medium solid $apricot;
    font-family: "Font Awesome 5 Free";
    content: "\f14a";
    overflow: hidden;
    font-size: 1.5rem;
    color: $dark_apricot;
  }

  label {
    margin: 0;
    padding: 0;
  }
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;

  .form-check {
    margin: 0 auto;
  }

  input[type="checkbox"]:not(:disabled):checked + label {
    font-weight: 700;
  }

  label {
    font-weight: 400;
  }
}

// Radio-buttons form
.radio-group {
  span {
    display: flex;
    align-items: center;
  }

  input {
    margin: 0 2vh;
    padding: 0;
  }

  input[type="radio"]:before {
    position: relative;
    top: -6px;
    right: 6px;
    display: flex;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: $border-medium solid $middle_gray;
    content: "";
    background: $white;
  }

  input[type="radio"]:not(:disabled):checked:after {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: relative;
    top: -30px;
    right: 6px;
    border: $border-medium solid $apricot;
    font-family: "Font Awesome 5 Free";
    overflow: hidden;
    font-size: 3rem;
    color: $dark_apricot;
    content: "\f192";
    // background-color: $dark_apricot;
  }

  label {
    margin: 0;
    padding: 0;
  }
}

.line-radio {
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    margin: 0 3vh 0 0;
  }
}

// Slider form (range)
.slider-group {
  .slider-elements {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2vh 0;

    label {
      font-family: $body-font;
      font-weight: normal;
      font-size: 1rem;
    }

    input[type="range"]:focus {
      outline: none;
    }

    input[type="range"] {
      -webkit-appearance: none;
      margin: 2vh 0;
      width: 100%;
      height: 5px;
      background: $middle_gray;
      border-radius: $component-radius-small;
    }

    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: $border-small solid $dark_apricot;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background: $apricot;
    }

    input[type="range"]::-moz-range-thumb {
      -webkit-appearance: none;
      border: $border-small solid $dark_apricot;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background: $apricot;
    }
  }
}

// Search-bar
.search-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2vw;
  margin: 2vh 0;
  border-radius: 1rem;
  box-shadow: $outline-box-shadow;
  border: $border-medium solid transparent;

  input {
    font-family: $body-font;
    font-size: 1rem;
    border: none;
    width: 100%;
    outline: none;
  }
  input::placeholder {
    color: $middle_gray;
  }
}

form.simple_form.search-container {
  flex-direction: row;
}

.search-btn {
  color: $apricot;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 1.26rem;
  font-family: $headers-font;
  font-weight: bold;
  padding: 0;
  margin: 1vh 0;
}

// file upload

input[type="file"] {
  border: none;
  font-size: 0.75rem;
}

// date input
.form-group.date > div {
  flex-grow: 2;

  input[type="date"] {
    width: 100%;
    text-align: center;
  }
}

.form-group.date > label {
  min-width: 8rem;
}

// intl-tel-input
.iti {
  flex-grow: 2;
}

input[type="text"],
input[type="button"],
input[type="submit"],
input[type="select"],
input[type="checkbox"] {
  // -webkit-appearance: none;
}

// Active Admin
fieldset.inputs.program_articles > ol > li fieldset.inputs ol {
  li {
    display: flex;
    flex-direction: column;
  }

  li.boolean {
    justify-content: flex-end;
    label > input {
      width: 1rem;
    }

    label {
      flex-grow: 0;
    }
  }

  label {
    width: 100%;
  }

  input {
    width: 5rem;
  }

  input,
  select {
    font-size: small !important;
  }
}

// grass form
.grass-form {
  .custom-input:focus {
    outline: none;
    border-bottom: $border-big solid $grass;
  }
  a {
    color: $grass;
  }
}


// select2 style
// select2 container of selection
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: $grass;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
}

.select2-selection__rendered {
  color: white;
}

// croix
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: white;
  background-color: $grass;
}

// text part
.select2-selection__choice__display {
  background-color: $grass;
}


// toggle
.toggle-boolean {
  position: absolute;
  transform: scale(0);
}

.form-group.boolean label {
  padding-left: 40px;
}

.toggle-boolean+label {
  position: relative;
  display: inline-block;
}

.toggle-boolean+label::before {
  content: '';
  display: block;
  position: absolute;
  cursor: pointer;
  width: 36px;
  height: 20px;
  top: 0;
  left: 0;
  background-color: #EAEAEA;
  border-radius: 50rem;
}

.toggle-boolean:checked+label::before {
  background-color: #1EDD88;
}

.toggle-boolean+label::after {
  content: '';
  display: block;
  position: absolute;
  cursor: pointer;
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
  background-color: white;
  border-radius: 50%;
  transition: left 0.2s ease;
}

.toggle-boolean:checked+label::after {
  left: 18px;
}
.input-pregnancy {
  .custom-input {
    text-align: center;
  }

  .hidden-input {
    display: none;
  }

  .custom-input::placeholder {
    color: black;
  }

}

.input-pregnancy {

  .html-placeholder {
    padding-bottom: 30px;
    color: black;
    z-index: 10;
    display: flex;
    width: 115px;
  }
}

