.cartridge-container {
  border-radius: $component-radius;
  display: flex;
  flex-direction: column;
  flex-direction: column-reverse;
  justify-content: space-around;
  padding: 0.2rem;
  align-items: center;

  p {
    margin: 0;
    flex-shrink: 0;
  }

  .countdown {
    font-family: $headers-font;
    font-weight: bold;
    font-size: 26px;
    text-align: center;
    line-height: 2.25rem;
    color: $dark_apricot;
    display: flex;
    background-color: $light_apricot;
    border-radius: 33px;
    width: 143px;
    margin-top: 15px;
    height: 42px;
    justify-content: center;
    padding-top: 10px;

    span {
      font-size: 21px !important;
    }

    img {
      width: 24px;
      margin-top: 5px;
      height: 24px;
      margin-right: 10px;
    }


    &.long-text {
      font-size: 2rem;
    }
  }

  .dates-pregnancies-home {
    width: 100%;
    display: flex;
    margin-top: 15px;
    justify-content: space-around;
  }

  .date-home {
    color: white;
    width: 77px;
    height: 77px;
    background: linear-gradient(180deg, #39639f 0%, #22477c 100%);
    border-radius: 50%;
    text-align: center;

    h5 {
      font-size: 20px;
      margin-top: 16px;
      margin-bottom: 4px;
    }

    p {
      font-size: 10px;
      line-height: 10px !important;
    }
  }

  .mood-of-day {
    font-size: 0.75rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
  }

  .mood-tag {
    color: $dark_apricot;
    background: $white;
    padding: 0.1rem 0.6rem;
    border-radius: $component-radius-big;
    margin: 0 0.3rem;
  }

  .text-h4 {
    margin: 0;
  }
}
