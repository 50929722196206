// BASE //
$pro: linear-gradient(
  to right,
  #e3edf5 0%,
  #a0b7d8 20%,
  #769bd0 55%,
  #22477c 100%
);
$sunny: linear-gradient(
  to right,
  #fadb9e 0%,
  #feb372 20%,
  #fd9f72 55%,
  #fc8873 100%
);
$sunny_muted: linear-gradient(
  to right,
  #ffe5b1 5%,
  #ffcb9e 20%,
  #ffb998 60%,
  #feab9c 100%
);
$half-sunny: linear-gradient(to right, #fadb9e 0%, #feb372 40%, #fd9f72 110%);
$half-sunny-reversed: linear-gradient(
  to left,
  #fadb9e 0%,
  #feb372 40%,
  #fd9f72 110%
);
$yellowy: linear-gradient(to right, #ffe5b1 35%, #feb372 100%);
$grassy: linear-gradient(to right, #e3edf5, #39639f);

$outline-sunny: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
  $sunny;
$outline-grassy: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
  $grassy;
$outline-yellowy: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  ),
  $yellowy;

// 50% TRANSPARENT //
$sunny_transparency-50: linear-gradient(
  to right,
  #fadb9e80 0%,
  #feb37280 20%,
  #fd9f7280 55%,
  #fc887380 100%
);
$sunny_muted_transparency-50: linear-gradient(
  to right,
  #ffe5b180 5%,
  #ffcb9e80 20%,
  #ffb99880 60%,
  #feab9c80 100%
);
$yellowy_transparency-50: linear-gradient(
  to right,
  #ffe5b180 35%,
  #feb37280 100%
);

// 25% TRANSPARENT //
$sunny_transparency-25: linear-gradient(
  to right,
  #fadb9ebf 0%,
  #feb372bf 20%,
  #fd9f72bf 55%,
  #fc8873bf 100%
);
$sunny_muted_transparency-25: linear-gradient(
  to right,
  #ffe5b1bf 5%,
  #ffcb9ebf 20%,
  #ffb998bf 60%,
  #feab9cbf 100%
);
$yellowy_transparency-25: linear-gradient(
  to right,
  #ffe5b1bf 35%,
  #feb372bf 100%
);
