.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-size {
  height: 100%;
  width: 100%;
}

.width-100 {
  width: 100px;
}

.height-100 {
  height: 100px;
}

.fit-content {
  width: fit-content;
}

.margin-right {
  margin-right: $margin-medium;
}

.margin-left {
  margin-left: $margin-medium;
}

.margin-right-sm {
  margin-right: $margin-small;
}

.margin-left-sm {
  margin-left: $margin-small;
}

.margin-left-lg {
  margin-left: $margin-large;
}

.margin-top-bottom {
  margin: $margin-medium 0;
}

.margin-top-bottom-lg {
  margin: $margin-large 0;
}

.margin-top-bottom-sm {
  margin: $margin-small 0;
}

.margin-top-sm {
  margin-top: $margin-small;
}

.margin-top {
  margin-top: 5rem;
}

.margin-bottom {
  margin-bottom: 5rem;
}

.margin-sides-sm {
  margin: 0 $margin-small;
}

.margin-sides {
  margin: 0 $margin-large;
}

@media (max-width: 1024px) {
  .margin-sides {
    margin: 0 $margin-large;
  }
}

.negative-margin {
  margin: -1rem 1rem;
}

.margin-auto {
  margin: auto
}

.grid {
  display: grid;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.border-apricot {
  border: $border-medium solid $dark-apricot;
}

.border-grass {
  border: $border-medium solid $grass;
}

.relative {
  position: relative;
}

.datefield-without-calendar::-webkit-calendar-picker-indicator {
  display: none;
}

.text-align-center {
  text-align: center;
}

.p5 {
  padding: 5px;
}

.margin-reset {
  margin: 0 !important;
}
