.introductory-text {
  text-align: justify;
  margin: 5rem 0;
  border-radius: $component-radius;
  line-height: 1.5rem;

  h1 {
    margin-bottom: $margin-large;
  }

  p {
    text-align: center;
  }

  img {
    border-radius: $component-radius;
    width: 35rem;
  }
}

@media (max-width: 1024px) {
  .introductory-text {
    margin: 5rem $margin-large;
  }
}
