#parents-title {
  color: $apricot;
}

#professionals-title {
  color: $grass;
}

#parents-title,
#professionals-title {
  margin-top: 1rem;
}
