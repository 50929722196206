@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;700&family=Fleur+De+Leah&family=Nunito:wght@300;400;700&display=swap");

.journee-skin {
  font-family: "Crimson Text";
  font-size: 12pt;
  line-height: normal;
  color: #343;

  .cover {
    .background-illustration {
      z-index: 1;
    }
  }

  .cover {
    .title, .author, .subtitle {
      font-family: "Fleur de Leah";
      color: #ad8231;
    }

    .title {
      font-size: 48pt;
    }

    .author {
      font-size: 20pt;
    }

    .subtitle {
      font-size: 26pt;
      color: #22477c;
      font-family: "Crimson Text";
    }

    .date {
      color: #22477c;
      font-weight: 700;

    }

    &.cover_fourth {
      .photo {
        left: 55mm;
      }
    }

    &.photo-variant {
      .subtitle {
        font-size: 20pt;
      }
      .date > span {
        background: #d4e3ce;
        width: fit-content;
        padding: 0 0.8rem;
        border-radius: 4mm;
      }
    }
  }

  .babies {
    .title, .name, .date, .weight, .height, .message {
      z-index: 2;
    }

    .title, .name {
      font-family: "Fleur de Leah";
      color: #ad8231;
      font-size: 48pt;
    }

    .date, .weight, .height, .message {
      font-size: 18pt;
      color: #343;
      font-family: "Crimson Text";
    }

    .baby-photo {
      height: 88mm;
    }
  }

  .summary {
    color: #343;

    .title {
      color: #ad8231;
      font-family: "Fleur de Leah";
      font-size: 96pt;
    }

    .summary-chapter {
      .name {
        font-size: 20pt;
        font-family: "Crimson Text";
        line-height: 1.75;
      }

      .number {
        font-size: 44pt;
        font-family: "Fleur de Leah";
        line-height: 1;
      }

      .page {
        font-size: 20pt;
        font-family: "Crimson Text";
        line-height: 1.75;
      }
    }

    .summary-section {
      font-family: "Crimson Text";
      font-size: 15pt;
      color: #9b7123;
    }
  }

  .header {
    color: #22477c;
  }

  .footer {
    .logo-footer {
      path {
        fill:#22477c;
      }
    }

    .page-number {
      font-family: "Fleur de Leah";
      font-size: 16pt;
      color: #343;
    }
  }

  .quotes {
    .quote-open, .quote-close {
      font-size: 34pt;
      color: #22477c;
      font-family: "Crimson Text";
    }

    .quote-text {
      font-family: "Fleur de Leah";
      font-size: 18pt;
      color: #343;
      line-height: 1.3;
    }

    .quote-author {
      span {
        font-size: 16pt;
        color: #ad8231;
        font-family: "Crimson Text";
        border-top: 1pt solid;
        padding: 0.5rem 1rem;
      }
    }
  }

  .parents {
    .title, .description, .name, .nickname {
      z-index: 2;
    }
    .title, .name {
      color: #ad8231;
    }

    .title, .name {
      font-family: "Fleur de Leah";
    }

    .title {
      font-size: 48pt;
    }

    .name {
      font-size: 20pt;
    }

    .nickname {
      font-size: 16pt;
    }

    .description {
      font-size: 14pt;
    }

    .nickname, .description {
      font-family: 'Crimson Text';
    }

    .profile-duo {
      .photo {
        top: 80mm;
      }
    }

    .profile-1 {
      .photo {
        left: 32mm;
      }
    }
  }

  .chapter {
    .number, .title {
      font-family: "Fleur de Leah";
    }

    .number {
      font-size: 196pt;
      color: #ad8231;
    }

    .title {
      font-size: 73pt;
      color: #343
    }
  }

  .journal {
    .journal-section-header {
      object-fit: cover;
      object-position: bottom;
    }

    .journal-section-title {
      top: 13mm;
      font-family: "Fleur de Leah";
      font-size: 48pt;
      color: #ad8231;

      &.journal-t1, &.journal-delivery {
        color: #22477c;
      }

      &.journal-t2, &.journal-first-months {
        color: #9b7123;
      }

      &.journal-t3 {
        color: #704d0a;
      }
    }

    .background-illustration.first-page {
      display: none;
    }
  }

  .entries {
    .name {
      color: #ad8231;
      font-size: 16pt;
      font-family: "Fleur de Leah";
      font-weight: 800;
    }

    .title {
      font-size: 14pt;
      font-weight: 700;
    }

    .date-illustration {
      align-items: flex-end;
    }

    .text-0-photo-landscape .entry-filler-illustration {
      top: 27mm;
      left: 16mm;
      width: initial;
    }

    .text-0-photo-portrait .entry-filler-illustration {
      width: initial;
    }

    .filler-illustration-1 {
      width: 183mm;
      height: 105mm;
    }
  }

  .letter {
    .photo {
      border-radius: 50%;

      img {
        object-fit: cover;
      }
    }

    .title, .signature, .text, .subtitle {
      z-index: 2;
    }

    .title {
      font-family: 'Fleur de Leah';
      font-size: 36pt;
      color:  #ad8231;
    }

    .text, .subtitle, .signature {
      font-size: 12pt;
      line-height: 1.3rem;
      font-family: "Crimson Text";
      color: #343;
    }

    .signature {
      font-weight: 700;
    }
  }

  .special-events {
    &.special-events-10 {
      .bloc-illustration {
        top: -1mm;
        position: absolute;
      }
    }
    &.special-events-16 {
      .bloc-photo-1, .bloc-photo-2 {
        top: 4.5mm;
      }
    }

    .title {
      font-family: "Fleur de Leah";
      font-size: 48pt;
      color: #ad8231;
    }

    .bloc-date {
      font-size: 14pt;
    }

    .bloc-text, .bloc-date {
      color: #343;
      font-family: "Crimson Text";
    }
  }

  .mosaic {
    .overlay {
      display: none;
    }

    .title {
      font-family: "Fleur de Leah";
      font-size: 38pt;
      color: #dcdddc;
    }

    .logo-footer {
      path {
        fill:#dcdddc;
      }
    }
  }

  .stats {
    .title {
      font-family: "Fleur de Leah";
      font-size: 32pt;
      color: #ad8231;
    }

    .graph-title {
      font-size: 16pt;
      color: #343;
      font-family: "Crimson Text";
      font-weight: 700;
    }
  }
}
