// Absolute positions within an A4 page

.summary {
  .title, .table {
    position: absolute;
  }

  .summary-chapter, .summary-section {
    display: flex;
    justify-content: space-between;
  }

  .summary-chapter {
    height: 22mm;
    position: relative;
  }

  .summary-section {
    height: 13.5mm;
  }

  .summary-decoration {
    position: absolute;
    width: 100%;
    height: 3mm;
    bottom: -2mm;
  }

  .name, .number, .page {
    display: flex;
    align-items: flex-end;
  }

  .name {
    flex-grow: 2;
    text-align: left;
    margin-left: 4mm;
  }

  .number {
    width: 23.5mm;
    text-align: right;
    justify-content: flex-end;
  }

  .page {
    width: 21mm;
    text-align: right;
  }

  .title {
    left: 45mm;
    top: 27mm;
    width: 130mm;
    height: 44mm;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table {
    left: 18mm;
    top: 65mm;
    width: 174mm;
    height: 211mm;
  }
}
