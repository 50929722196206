.fixed {
  position: fixed;
  z-index: 10;

  &.top {
    top: 3rem;
  }
  &.right {
    right: 1rem;
  }
  &.right-15vw {
    right: 15vw;
  }
  &.left {
    left: 1rem;
  }
}

.cover-object-fit {
  object-fit: cover;
}
