.loader-discovery-size {
  font-size: 1.25rem;

  height: 1em;
  width: 1em;

  &.size-lrg { font-size: 32px; }
  &.size-big { font-size: 48px; }

  &.border-thin {
    .lds-ring {
      div { border-width: 0.1em; }
    }
  }

  &.no-margin {
    .lds-ring { margin: 0; }
  }

  &.middle_gray {
    .lds-ring {
      div { border-color: $middle_gray transparent transparent transparent; }
    }
  }

  &.apricot {
    .lds-ring {
      div { border-color: $apricot transparent transparent transparent; }
    }
  }

  &.peach {
    .lds-ring {
      div { border-color: $peach transparent transparent transparent; }
    }
  }

  &.grass {
    .lds-ring {
      div { border-color: $grass transparent transparent transparent; }
    }
  }

  .lds-ring {
    width: 100%;
    height: 100%;

    display: inline-block;
    position: relative;

    font-size: 1em;

    @keyframes lds-ring {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    div {
      box-sizing: border-box;

      width: 100%;
      height: 100%;
      border-radius: 50%;

      display: block;
      position: absolute;
      top: 0;

      border: 0.15em solid $white;
      border-color: $white transparent transparent transparent;

      font-size: 1em;

      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

      &:nth-child(1) { animation-delay: -0.45s; }
      &:nth-child(2) { animation-delay: -0.3s; }
      &:nth-child(3) { animation-delay: -0.15s; }
    }
  }
}
