// SIZE //
$border-big: 3px;
$border-medium: 2px;
$border-small: 1px;
$border-extra-small: 0.5px;

// RADIUS //
$component-radius-round: 50%;

$component-radius-big: 25px;
$component-radius-medium: 15px;
$component-radius: 10px;
$component-radius-small: 5px;
