.invoice-informations {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  h3, p {
    color: $dark-gray;
  }
  .invoice-information-left {
    h3, p {
      text-align: left;
    }
  }
  .invoice-legal-notice {
    width: 350px;
  }
  .invoice-information-right-aligned {
    h3, p {
      text-align: right;
    }
  }
  .invoice-information-right-not-aligned {
    min-width: 350px;
    h3, p {
      text-align: left;
    }
  }
}

.pro-user-invoice-references {
  display: flex;
  flex: flex-wrap;
  justify-content: space-between;
  h3, p {
    color: $dark-gray;
    line-height: 0.7rem;
  }
  .banking-coordinate {
    h3, p {
      text-align: left;
    }
  }
  .pro-user-invoice-references {
    h3, p {
      text-align: right;
    }
  }
}


.invoice-title {
  display: flex;
  align-items: center;
  gap: 2rem;
  h1 {
    color: $dark_gray;
  }
}

.status-invoice-label {
  padding: 1rem;
  color: white;
}
.status-invoice-label-pending {
  background-color: $apricot;
}
.status-invoice-label-paid {
  background-color: $grass;
}

.vat-information-label {
  font-style: italic;
}

.icon-rotation-180 {
  transform: rotate(180deg);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.icon-rotation-180-back {
  transform: rotate(0deg);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fa-arrow-down {
  border: 1px solid black;
  padding: 0.4rem;
  border-radius: $component-radius;
}
