.skin-moodboard-card  {
  padding: 1rem;
  flex-basis: 50%;
  border: $border-big solid $light-gray;
  border-radius: $component-radius-medium;
  align-items: center;
  padding: 0.2rem 0.4rem;
  background: $white;

  p {
    margin: 0;
  }

  .carousel-wrapper {
    height: 350px;
    width: 248px;

    .carousel {
      img {
        height: 350px;
        width: 248px;
      }
    }
  }
}

input[type=radio]:checked + label {
  .skin-moodboard-card {
    border: $border-big solid $apricot;

    p {
      font-weight: bold;
    }
  }
}
