.moods-swiper {
  width: 100%;
  height: 100%;

  .btn-mood-start {
    width: 100%;
    background-color: $white;
    border-radius: 10px;
    color: $black;
  }

  input[type='range'] {
    width: 80%;
    height: 10px;
    border-radius: 10px;
    -webkit-appearance: none;
    background-color: $white;
  }

  input[type='range']::-webkit-slider-thumb {
    width: 30px;
    -webkit-appearance: none;
    height: 30px;
    border-radius: 50%;
    cursor: ew-resize;
    background: $white;
  }


  .mood-stats-container {
    margin-top: 15px;
    width: 83%;
  }

  .swiper-pagination {
    bottom: 25%;
    left: 42.5%;
    width: 15%;
    display: flex;
    justify-content: space-between;
  }

  .swiper-pagination-bullet {
    height: 15px;
    width: 15px;
    background-color: $white;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: $gray_middle;
  }

  .swiper-button-next {
    top: 95% !important;
    background-color: $white;
    color: $black;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .swiper-button-next:after {
    font-weight: 700;
    font-size: 20px;
  }

  .moods-wrapper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 100% !important;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 0px !important;
    width: 0;

  }
}

html {
  max-height: 100vh;
}

.swiper-mood-1 {
  background-image: url("../../assets/images/slide-mood-1.png");
  background-size: 100% 100%;
}

.swiper-mood-2 {
  background-image: url("../../assets/images/slide-mood-2.png");
  background-size: 100% 79%;
}

.swiper-mood-3 {
  background-image: url("../../assets/images/slide-mood-3.png");
  background-size: 100% 86%;
}

.mood-description {
  background-color: $apricot_300;
  height: 30%;
  width: 104%;
  margin-left: -2%;
  margin-bottom: -2%;
  border-radius: 5% 5% 0 0;

  .text-mood {
    padding-top: 20px;
    text-align: center;
    width: 75%;
    margin: auto;

    p {
      font-size: 12px;
      margin-top: -10px;
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }

    h5 {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 700;
      margin-bottom: 15px;
      margin-top: 20px;
    }

    h6 {
      font-size: 13px;
      margin-top: 10px;
      margin-bottom: -9px;
    }

    .security-mood-text {
      margin-bottom: 9%;
    }
  }
}

@media(max-width: 380px) {

  .moods-swiper {
    .swiper-pagination {
      bottom: 34% !important;
    }

    .text-mood {
      padding-top: 0px;

      h5 {
        margin-bottom: 10px !important;
        margin-top: 35px !important;
      }

    }
    .mood-description {
      height: 40% !important;
    }

  }
}

@media(min-width: 700px) {
  .moods-swiper {
    .swiper-pagination {
      bottom: 28%;
      left: 40%;
      width: 20% !important;
    }

    .swiper-pagination-bullet {
      height: 25px;
      width: 25px;
    }

    .text-mood {
      padding-top: 8% !important;

      h5 {
        line-height: 17.5px;
        font-size: 20px;
      }

      p {
        font-size: 20px;
        line-height: 20px;
        margin-top: 0px !important;
      }
    }
  }
}
