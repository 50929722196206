#contact-form-container.professional {
  .custom-input:focus {
    border-bottom: $border-big solid $grass;
  }

  .invalid-feedback {
    color: $grass;
  }

  input[type="submit"] {
    background-color: $grass;
  }
}
