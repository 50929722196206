.citation {
  width: 50%;
  padding-left: 50px;
}

.artifika-regular {
  font-family: "Artifika", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 150px;
  margin: 0px;
  color: $blue-500;
  opacity: 20%;
}

.artifika-regular-top {
  margin-left: -20px;
  margin-top: 50px;
  margin-bottom: -30px;
}

.artifika-regular-bottom {
  margin-right: 19px;
  margin-top: -30px;
  transform: rotate(180deg);
}

.charm-regular {
  font-family: "Charm", serif;
  font-weight: 400;
  font-style: normal;
  line-height: 26px;
  color: $blue-cobalt;
  font-size: 20px;
  text-align: center;
  padding-right: 20px;
}

@media (max-width: 600px) {
  .citation {
    display: none;
  }
}
