.downloads-reminder {
  margin: $web-margin-large 0;

  .app-stores {
    padding: $margin-large 0;
  }
}
.downloads-reminder h3:first-of-type {
  margin-top: 4rem;
}

.plateforms-stores {
  display: flex;
  flex-direction: column;
  align-items: center;

  .app-stores {
    margin-bottom: $web-margin-medium;
    justify-content: center;
  }

  .app-stores a {
    margin: 0 $margin-medium;
  }
}
