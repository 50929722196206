.progress-container {
  margin: auto;
  position: relative;
  width: 95%;
}

.progress-bar-background {
  position: absolute; /* Rester en position absolue */
  top: 85%;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: $apricot_200;
  border-radius: 10px;
  transform: translateY(-50%); /* Centrer verticalement */
}

.progress-bar-filled {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: $grass;
  border-radius: 10px;
  transition: width 0.3s ease;
  width: 5%;
}

.progress-steps {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 20px;
}

.progress-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 105px;
}

.progress-step-title {
  background-color: $apricot_200;
  color: $medium_apricot;
  padding: 6px 8px;
  border-radius: 6px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 8px;
}

.progress-step-title.passed {
  background-color: $grass;
  color: $white;
}

.progress-step-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: $apricot_200;
  border: 2px solid $apricot_200;
}

.progress-step-circle.passed {
  background-color: $grass;
  border-color: $grass;
}

@media (max-width: 780px) {
  .progress-bar-filled {
    width: 7%;
  }
}

@media (max-width: 1000px) {
  .progress-bar-filled {
    width: 7%;
  }
}

@media (max-width: 650px) {
  .progress-step {
    width: 33.33%;
    margin-bottom: 15px;
  }

  .progress-step-title {
    font-size: 10px;
    padding: 4px 8px;
    height: 30px;
    line-height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .progress-step-circle {
    width: 14px;
    height: 14px;
  }

  .progress-bar-background {
    height: 6px;
    top: 70%;
  }
}

@media (max-width: 370px) {
  .progress-container {
    width: 100%;
  }
}




