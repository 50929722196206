.table-line-article-wrapper {
  margin: 2rem auto;
  width: 70%;
}

.table {
  border-collapse: collapse;

  thead {
    th {
      padding: 0;

      border-bottom: 1px solid $dark_gray;

      text-align: center;

      .top-header {
        margin: 0 auto 0.5em;

        line-height: 1em;
      }
    }
  }

  tbody {
    tr {
      td {
        border: 1px solid $middle_gray;

        background-color: $white;

        letter-spacing: 0.05em;

        &:first-of-type {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }

        * {
          font-size: 1em;
        }
      }

      &:first-child {
        td {
          border-top: none;
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }

      &:nth-child(even) {
        td {
          background-color: $light_gray;
        }
      }
    }
  }

  &.first-col-header {
    thead {
      th {
        border-bottom: 1px solid $dark_gray;

        &:first-child {
          border: none;
        }
      }
    }

    tbody {
      tr {
        th {
          padding-right: 0.2em;

          border-right: 1px solid $dark_gray;

          text-align: right;
        }
      }
    }

    &.floating-side-header {
      thead th:first-of-type, tbody tr th {
        position: relative;
        padding: 0;

        * {
          position: absolute;
          left: -0.5em;

          transform: translate(-100%, -50%);
        }
      }
    }
  }

  &.shadowed-table {
    tbody {
      box-shadow: 0 0 10px $gray_300,
                  0 3px 3px $gray_300;
    }
  }

  &.round-table {
    $target-radius: 0.5em;
    tbody {
      border-radius: $target-radius;

      tr {
        &:first-of-type {
          td {
            &:first-of-type {
              border-radius: $target-radius 0 0 0;
            }
            &:last-of-type {
              border-radius: 0 $target-radius 0 0;
            }
          }
        }

        &:last-of-type {
          td {
            &:first-of-type {
              border-radius: 0 0 0 $target-radius;
            }
            &:last-of-type {
              border-radius: 0 0 $target-radius 0;
            }
          }
        }
      }
    }
  }

  &.apricot-table {
    thead th {
      color: $apricot;
    }

    tbody {
      tr {
        td {
          border-top: none;
          border-bottom: none;
          border-color: $light_gray;
        }
      }
    }

    thead, tbody tr {
      th {
        border: none;
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          td {
            background-color: $light_apricot;
            border-color: $white;
          }
        }
      }
    }
  }

  @media(max-width: 642px) {
    font-size: 0.7em;
  }
}

// in new invoice
.lines-articles {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
}

.line-article {
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}

.line-article td, .line-article th {
  text-align: center;
  padding: 0.5rem;
}

.line-article td {
  border-right: 1px solid #f8f8f8;
  // font-size: 12px;
}

.line-article thead th {
  color: white;
  background: $grass;
}


.line-article thead th:nth-child(odd) {
  color: white;
  background:  #22477c;
}

.line-article tr:nth-child(even) {
  background: #FFEEDF;
}

@media (max-width: 767px) {
  .line-article {
    display: block;
    width: 100%;
  }
  .table-wrapper:before{
    content: "Scroll horizontally >";
    display: block;
    text-align: right;
    color: white;
    padding: 0 0 0.625rem;
  }
  .line-article thead, .line-article tbody, .line-article thead th {
    display: block;
  }
  .line-article thead th:last-child{
    border-bottom: none;
  }
  .line-article thead {
    float: left;
  }
  .line-article tbody {
    width: auto;
    position: relative;
    overflow-x: auto;
  }
  .line-article td, .line-article th {
    padding: 1.25rem 0.625rem 0.625rem 0.625rem;
    height: 3.75rem;
    vertical-align: middle;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    width: 7.5rem;
    text-overflow: ellipsis;
  }
  .line-article thead th {
    text-align: left;
    border-bottom: 1px solid #f7f7f9;
  }
  .line-article tbody tr {
    display: table-cell;
  }
  .line-article tbody tr:nth-child(odd) {
    background: none;
  }
  .line-article tr:nth-child(even) {
    background: transparent;
  }
  .line-article tr td:nth-child(odd) {
    background: #FFEEDF;
    border-right: 1px solid #E6E4E4;
  }
  .line-article tr td:nth-child(even) {
    border-right: 1px solid #E6E4E4;
  }
  .line-article tbody td {
    display: block;
    text-align: center;
  }
}
