$arrow_inner_spacing: 5px;
$arrow_outer_spacing: 5px;
$calendar_cell_size: 36px;
$calendar_navigation_width: 30px;

.simple-calendar {
  .calendar-heading {
    width: 100%;
    margin: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    a, span {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a {
      height: $calendar_navigation_width;
      width: $calendar_navigation_width;
      padding: $arrow_inner_spacing;

      border-radius: 50%;

      background-image: $sunny;
      color: white;

      &:first-child {
        margin-right: $arrow_outer_spacing;
      }
      &:last-child {
        margin-left: $arrow_outer_spacing;
      }
    }
  }

  .table {
    border: none;
    
    thead {
      border-bottom: 2px solid $apricot;
      box-shadow: 0 0 0 0.1 black;
    }

    th, td {
      border: none;
      padding: 1px;

      p, a {
        height: $calendar_cell_size;
        width: $calendar_cell_size;
        padding: 0;
        margin: 0;

        border-radius: 50%;
        border: 2px solid transparent;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    td {
      &.prev-month, &.next-month {
        background-color: $gray_300 !important;
      }

      &.day.has-events {
        p, a {
          border-color: $apricot;

          &.selected-day, &:not(.selected-day):hover {
            border: none;
            padding: 2px;
            color: white;
          }

          &.selected-day {
            background-image: $sunny;
          }

          &:not(.selected-day):hover {
            background-image: $sunny_muted;
          }
        }
      }

      &.today {
        background: none;
      }
    }

    .current-month {
      color: $middle_gray;
    }
    .prev-month, .next-month {
      color: $middle_gray;
    }
  }
}
