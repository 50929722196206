@media (max-width: 1024px) {
  .values {
    flex-direction: column;
    gap: 2rem;
  }
  h3 {
    margin-top: $margin-large;
  }
}

.values {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 5rem 0;

  h1 {
    margin-top: 0;
  }

  h2 {
    color: black;
  }

  p {
    margin-bottom: $margin-medium;
    color: $dark_gray;
  }

  img {
    height: 29.375rem;
  }

  .values-section {
    min-height: 29.375rem;
    max-width: 29.375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 $margin-medium;
  }

  .values-container {
    display: flex;
    flex-direction: column;
  }

  .value-container:last-child {
    margin-bottom: $margin-large;
  }

  .value-container {
    display: flex;
    align-items: center;
    margin: 0.75rem 0;
  }

  .letter-container {
    min-width: 4rem;
    min-height: 4rem;
    border-radius: $component-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.2rem;
  }

  .letter-container.peach-background {
    background-color: #ff8070;
  }
  .letter-container.apricot-background {
    background-color: #ffaf6c;
  }
  .letter-container.sunny-background {
    background-color: #ffd99a;
  }
  .letter-container.dark-green-background {
    background-color: #627f71;
  }
  .letter-container.light-green-background {
    background-color: #e3f6eb;
  }
}
