@import "flower_menu";
@import "user-card";
@import "offer_demand";
@import "offer_filter";
@import "pro";

.text-like {
  color: black;
}

.btn-light-apricot {
  .text-like-no-active {
    color: $apricot;
  }
}


.icone-event-collective {
  height: 27px;
  margin-right: 10px;
  margin-top: -7px;
}


.btn-active-offer {
  height: 35px;

  .div-btn-active {
    height: 24px;
  }
}

.btn-apricot {
  .text-like-no-active {
    color: $white;
  }
}

.offers-filters-container {
  padding: 5px;

  .selected-btn {
    margin: 0.4rem;
    border-radius: 100rem;
    padding: 0.4rem 1rem;
    font-weight: normal !important;
    background-image: $sunny;
    border: 0.5px solid $sun-transparency-50;
    color: $black;
  }
  .unselected-btn {
    margin: 0.4rem;
    border-radius: 100rem;
    padding: 0.4rem 1rem;
    font-weight: normal !important;
    background-color: $sun-transparency-50;
    border: 0.5px solid $sun-transparency-50;
    color: $black;
  }
}

.number-offer {
  margin-left: 5%;
}

.location-filter {
  display: flex;
  justify-content: space-around;
  margin-bottom: 4%;
  margin-top: 10px;

  a {
    margin: 0px !important;
  }
}
