#book_orders {
  box-sizing: border-box;

  height: 100%;
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 4px;

  display: flex;

  overflow-y: auto;

  .book-order-card {
    box-sizing: border-box;

    font-size: 1rem;

    display: block;

    box-shadow: none;

    button {
      box-sizing: border-box;

      width: 148px;
      height: 220px;

      margin: 0  4px;
      padding: 8px 16px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      border: 2px solid $dark_apricot;
      background: none;

      color: $black;

      box-shadow: none;

      &:disabled {
        cursor: default;

        border: 2px solid $light_gray;
      }

      h1, h2, p {
        margin: 0
      }

      h1, h2 {
        font-size: 1rem;
        color: $dark_gray;

        overflow: clip;
        text-overflow: "...";
      }

      .order-header {
        .order-date {
          color: $middle_gray;
          font-weight: 100;
        }
      }

      .no-pdf-message {
        text-align: center;
        letter-spacing: 0.05em;
      }

      .loader-discovery-size {
        margin-bottom: 16px;
      }

      .pdf-icon {
        font-size: 64px;

        margin: auto;

        text-align: center;

        i {
          margin: 0;
          color: $peach_700;
        }
      }

      .order-action {
        color: $apricot;
      }
    }
  }
}
