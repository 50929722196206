.event-bloc {
  &.filler {
    filter: grayscale(0.8);
  }
}

#special_events_edit {
  .book-entry-card {
    .simple_form.special_event, .special-event-data {
      box-sizing: border-box;
    }

    .simple_form.special_event {
      width: 20%;
    }

    .special-event-data {
      width: 80%;
      padding: 1rem 0 0 1rem;

      border-left: 1px solid $apricot;

      .special-event-photos {
        height: 150px;
        width: 150px;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 100%;
          width: 80%;

          object-fit: cover;

          border-radius: 15px;
          border: 3px solid $apricot;
        }
      }
    }
  }
}
