.all-pro {
  margin-top: 10px;
}

.card-pro {
  margin: auto;
  width: 90%;
  margin-bottom: 20px;
  padding: 20px;

  .flex-card-user {
    justify-content: space-between;
    width: 100%;
  }

  .description-pro-mobile {
    display: none;
  }

  .description-pro {
    margin-right: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .img-user {
    height: 96px;
    border-radius: 8px;
    margin-right: 15px
  }

  .no-img-user {
    margin-right: 15px;
    height: 92px;
    width: 92px;
  }

  .name-pro {
    margin-top: 0px;
    font-size: 20px;
    color: $grass;
  }

  .card-offer-infos {
    width: 225px;
  }

  .link-offers {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba($blue-500, 0.5);
    margin-bottom: 15px;
    width: 150px;
    margin-top: -15px;
  }

  .link-card-pro {
    font-size: 13px;
    color: $blue-500;
  }

  .text-blue {
    color: $grass;
  }

  .info-service-icone {
    p {
      margin-top: 0px;
      color: $blue-500;
      font-size: 13px;
      margin-bottom: 3px;
    }
  }

  .tag-pro {
    background: $blue-100;
    color: $grass;
    border-radius: 7px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 11px;
    height: 24px;
  }

  .img-medical {
    width: 24px;
    height: 24px;
    margin-top: -3px;
    margin-left: 10px;
    margin-right: 20px;
  }

  .icone-pro {
    height: 12px;
    width: 12px;
    margin-top: 2px;
    margin-right: 5px;
  }

  .icone-date {
    width: 8px !important;
  }

  .btn-reservation {
    width: 170px;

    img {
      width: 18px;
      margin-right: 10px;
    }
  }

  .btn-chat {
    width: 170px;
    padding: 10px;
    border: none;
    height: 100%;
    line-height: 20px;

    img {
      margin-left: 15px;
      width: 18px;
    }
  }

  .btn-on-demand-offer {
    display: flex;
    align-items: center;

    form {
      height: 100%;
    }
  }
}

@media (max-width: 800px) {
  .card-pro {
    display: block;

    .description-pro {
      display: none;
    }

    .description-pro-mobile {
      display: block;
    }
  }
}

@media (max-width: 500px) {
  .card-pro {
    .infos-service {
      margin: auto;
    }

    .flex-card-user {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 550px) {
  .card-pro {
    display: block;

    .card-offer-infos {
      width: 195px;
    }

    .btn-reservation {
      width: 150px;
    }
  }
}
