.app-stores {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

// TODO : à revoir
.app-stores-btn {
  width: 9vw;
  height: 4vh;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}
