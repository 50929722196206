.simple-calendar {
  table {
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgb(221, 221, 221);
    border-collapse: collapse;
    box-sizing: border-box;
    max-width: 100%;
    width: 5rem;
  }

  tr {
    border-collapse: collapse;
  }

  th {
    padding: 6px;
    border-bottom: 2px solid rgb(221, 221, 221);
    border-collapse: collapse;
    border-left: 1px solid rgb(221, 221, 221);
    border-right: 1px solid rgb(221, 221, 221);
    border-top: 0px none rgb(51, 51, 51);
    box-sizing: border-box;
    text-align: left;
  }

  td {
    padding: 6px;
    vertical-align: top;
    width: 14%;

    border: 1px solid #ddd;
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(221, 221, 221);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(221, 221, 221);
    border-left-style: solid;
    border-left-width: 1px;
  }

  .day {
    height: 2rem;
  }

  .wday-0 {
  }
  .wday-1 {
  }
  .wday-2 {
  }
  .wday-3 {
  }
  .wday-4 {
  }
  .wday-5 {
  }
  .wday-6 {
  }

  .today {
    background: #ffffc0;
  }

  .past {
  }
  .future {
  }

  .start-date {
  }

  .prev-month {
    background: #ddd;
  }
  .next-month {
    background: #ddd;
  }
  .current-month {
  }

  .has-events {
  }
}
