.modal-invitation {
  .tooltip-pool-invitation {
    top: -100px;
    left: 80px;
  }

  .tooltip-pool-employee-email {
    top: -100px;
    left: 200px;
    width: 350px;
  }

  .tooltip-pool-employee {
    top: -145px;
    left: 130px;
   }
}
.tooltip-link {
  margin-top: 6px;

  .tooltip-i-infos {
    margin-left: 0px;
  }

  .tooltip-pool-link {
    left: 11px;
    width: 300px;
    top: 183px;
  }
}

.tooltip-pool-infos {
  background-color: $white;
  color: $blue-500;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: absolute;
  top: -60%;
  left: 160px;
  display: none;

  p {
    font-size: 11px;
    margin: 0px;
  }
}

.tooltip-pool-infos::after {
  content: "";
  position: absolute;
  bottom: -15px; /* Positionnée en dessous du tooltip */
  left: 45%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: $white transparent transparent transparent; /* Flèche pointant vers le bas */
}


.tooltip-i {
  width: 16px;
  font-size: 11px;
  color: $grey_tooltip;
  margin-left: 10px;
  text-align: center;
  border-radius: 50%;
  height: 15px;
  padding-bottom: 2px;
  margin-top: 0px;
  border: solid 1px $grey_tooltip;
}


@media (max-width: 600px) {
  .tooltip-pool-infos {
    left: 120px;
  }
}

@media (max-width: 450px) {
  .tooltip-pool-infos {
    left: 55px;
    top: -45%;
  }
}
