// Article previews
.preview-article-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: $outline-small-width solid $apricot;
  max-height: 42vh;

  .preview-article-image {
    width: 100%;
    height: 25vh;

    img {
      border-radius: $component-radius $component-radius 0 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .preview-article-text {
    padding: 1vh;
    margin: 0 0 1vh 0;

    p {
      color: $dark_gray;
    }
  }
}


.border-bottom-faq-beomai {
  border-bottom: $border-small solid $apricot;
}
.border-bottom-faq-grass {
  border-bottom: $border-small solid $grass;
}
.border-bottom-faq-dark-grass {
  border-bottom: $border-small solid $dark_grass;
}

.faq-articles-list-container {
  margin-bottom: 70px;
}
// Article small cards on the articles index page
.card-faq-article-container {
  width: 100%;
  padding: 1vh 0;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 0.5rem;

  .article-image-small {
    height: 5rem;
    width: 5rem;
    align-self: center;

    img {
      border-radius: $component-radius-small;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .article-elements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
  }

}

.article-title,
.article-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0.5vh 0;
}

.article-title {
  color: $dark_gray;
  -webkit-line-clamp: 2;
  text-align: left;
}

.article-text {
  -webkit-line-clamp: 4;
  font-size: 0.875rem;
}

// Full page articles
.article-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vh;
}

.article-cover-photo {
  height: 30vh;
  max-height: 14rem;
  width: 100%;

  img {
    border-radius: $component-radius;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.article-photo-caption {
  text-align: right;
  color: $dark-gray;
  font-size: 0.75rem;
  margin-top: 0.2rem;
}

.article-info {
  font-size: 0.75rem;
}

.article {
  font-family: $body-font;

  h1,
  h2,
  h3,
  h4 {
    text-align: left;
  }

  h1 {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }

  .trix-content {
    line-height: 1.5rem;
  }
}


span.divider {
  border-bottom: $light-divider;
  margin: 1rem 2rem;
  color: $middle-gray;
  font-size: x-small;
  text-align: center;
}

.query {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .tag {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    flex-direction: row;
    margin-left: 0.5rem;
  }

  .query-reset {
    font-size: 0.6rem;
  }
}

.tag-tabs {
  display: flex;
  width: 100%;
  overflow-x: hidden;
}


.tag-tab-list {
  display: flex;
  overflow-x: scroll;
  padding-left: 0.2rem;
  border-left: $border-medium $apricot solid;

  &::-webkit-scrollbar {
    display: none;
  }
}

// tag checkbox for subcategory (article, citations, temoignages)
.tag-checkbox-article {
  display: none;
  margin-left: 0.625rem;
}

.tag-checkbox-article:checked+label {
  color: white;
  background-image: $sunny;
}

.tag-article {
  margin-left: 1rem;
  background-color: $sun-transparency-50;
  border-radius: 100rem;
  padding: 0.4rem 1rem;
}

@media (max-width: 400px) {
  .search-container {
    .tag-type-article {
      margin-left: 0px;
    }
  }
}
