/* Index */

#female-ranking {
  color: $apricot;
}

.link-reset-password {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

#male-ranking,
#male-list-ranking {
  h2,
  h3,
  p,
  .name {
    color: $grass;
  }

  .toggle .background.other-choice {
    background: $grass;
  }

  .baby-name-card {
    border-color: $grass;
  }

  .plus-btn {
    background-color: $grass;
  }

  .apricot-outline-group {
    border-color: $grass;
  }
}

#male-list-ranking {
  // .baby-name-card {
  //   border-color: $grass !important;
  // }
  .baby-name-card {
    display: grid;
    grid-template-columns: 1fr 1fr 4fr 1fr 1fr;
    border: $outline-medium-width solid $grass;
    border-radius: $component-radius;
    height: 3.2rem;
    align-content: center;
    margin-bottom: 0.4rem;
    grid-auto-columns: 1fr;

    i {
      margin: auto;
      color: $middle-gray;
    }

    &.user {
      font-weight: 700;
      border-color: $grass;
    }

    &.first {
      font-weight: 700;
      background-color: $dark_grass !important;
      background-image: none;
    }

    &.second {
      font-weight: 700;
      background-color: $grass;
      background-image: none;

    }

    &.third {
      font-weight: 700;
      background-color: $light_grass;
      background-image: none;
    }

    .action {
      display: flex;
      justify-content: center;
      grid-column: 5 / 6;

      button {
        margin: 0;
        border: none;
        background: none;
        padding: 0;
      }
    }
  }
}

.rank-bucket {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  min-height: 2rem;
}

/* Adding */
#new_baby_name {
  position: relative;

  .invalid-feedback {
    padding: 0 1rem;
    font-size: 1rem;
    position: absolute;
    bottom: -0.63rem;
  }
}

/* Cards */
.baby-name-card {
  display: grid;
  grid-template-columns: 1fr 1fr 4fr 1fr 1fr;
  border: $outline-medium-width solid $light-apricot;
  border-radius: $component-radius;
  height: 3.2rem;
  align-content: center;
  margin-bottom: 0.4rem;
  grid-auto-columns: 1fr;

  i {
    margin: auto;
    color: $middle-gray;
  }

  &.user {
    font-weight: 700;
    border-color: $apricot;
  }

  &.first {
    font-weight: 700;
    background-image: $sunny_transparency_25;
  }
  &.second {
    font-weight: 700;
    background-image: $sunny_muted_transparency_25;
  }
  &.third {
    font-weight: 700;
    background-image: $yellowy_transparency_25;
  }

  .action {
    display: flex;
    justify-content: center;
    grid-column: 5 / 6;

    button {
      margin: 0;
      border: none;
      background: none;
      padding: 0;
    }
  }

  .handle {
    grid-column: 1 / 3;
    grid-row: 1;
    height: 3.2rem;
    line-height: 3.2rem;
    width: 100%;
    text-align: left;
    padding-left: 1rem;
    touch-action: none;
  }

  .avatar {
    grid-column: 2;
    grid-row: 1;
    margin: auto 0;
  }

  .favorite {
    grid-column: 4 / 5;
    grid-row: 1;
    &.fas {
      color: $peach;
    }
  }
}

.rank_number {
  padding-left: 1.125rem;
  display: flex;
  align-items: center;
}

.name-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  grid-column: 3 / 4;
  grid-row: 1;
  text-align: center;

  .name {
    text-transform: capitalize;
  }
}

.name-message {
  font-size: 0.8rem;
  margin: 0 2rem;
  line-height: 1;
}

.baby-name-container {
  margin: 2vw;
}

.user-baby-names {
  h3 {
    text-align: left;
  }
}

.veto {
  background: $light-gray;
  color: $middle-gray;
  border-color: $middle-gray !important; // priority over .user

  .name {
    text-decoration: line-through;
  }
}

/* Filters */
.filters {
  margin: 2vw;
  display: flex;
  justify-content: center;
}

.toggle {
  border: $outline-medium-width solid $apricot;
  border-radius: $component-radius-big;
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  cursor: pointer;
  margin: 0 3vw;
  position: relative;
  box-shadow: $inner-white-border;
  overflow: hidden;

  .option {
    color: $middle-gray;
    padding: 0.2rem 0.6rem;
    grid-row: 1;
    border-radius: $component-radius-big;
    overflow: hidden;
  }

  i {
    font-size: 1rem;
    line-height: 1.4;
  }

  p {
    margin: 0;
  }

  .active {
    color: $white;
  }

  .background {
    background: $half-sunny-reversed;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 50%;
    border-radius: $component-radius-big;
    margin: 1px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;

    &.other-choice {
      left: 50%;
      background: $half-sunny;
    }
  }
}
