.banner {
  height: 400px;
  background-size: cover;
  background-position: center;
  margin-bottom: $web-margin-large;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 0 $margin-large;

  h1 {
    color: white;
    font-size: 2rem;
    line-height: initial;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    margin: 1rem auto;
  }

  a {
    margin: 1rem auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .banner-text {
    padding: 0 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .banner-text {
    padding: 0 3rem;
  }
}
