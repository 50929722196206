// Absolute positions within an A4 page

.letter {
  .photo, .title, .text, .signature, .subtitle {
    position: absolute;
  }

  .photo {
    left: 24mm;
    top: 43mm;
    width: 60mm;
    height: 60mm;
    object-fit: cover;
  }

  .title {
    left: 105mm;
    top: 43mm;
    width: 92.5mm;
    height: 19mm;
  }

  .text {
    left: 105mm;
    top: 66mm;
    width: 92.5mm;
    height: 151mm;
    white-space: pre-line;
  }

  .signature {
    left: 105mm;
    top: 221mm;
    width: 92.5mm;
    height: 8mm;
  }

  .subtitle {
    left: 105mm;
    top: 229mm;
    width: 92.5mm;
    height: 8mm;
  }
}
