.modal-user {
  .info-professional-modal {
    border: none !important;
  }

  .service-availablity-text {
    color: $middle_top_grey;
    font-weight: 700;
    margin-bottom: 10px;
    margin-left: 20px;
  }

  .selected-btn {
    padding: 3px 7px !important;
  }

  .unselected-btn {
    padding: 5px 7px !important;
  }

  h6 {
    font-size: 16px;
    color: $grass;
    font-weight: 700;
    margin-bottom: 0px;
    margin-top: 30px;
    margin-left: 15px;
  }

  .time-service {
    color: $white;
    width: 49px;
    text-align: center;
    margin-left: 13px !important;
    height: 31px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .location-service {
    color:$black;
    font-style: italic;
    font-weight: 400;
  }

  .filter-location {
    margin-left: 7px;
  }
}

