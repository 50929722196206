#buy_book_wrapper {
  max-width: 900px;
  width: 100%;
  margin: 2vw 1vh;
  margin: auto;

  overflow-x: hidden;

  h1 {
    margin-top: 0.1em;
  }

  .side-scroller-wrapper {
    min-width: 100%;
  }

  .buy-book-emphazed {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    padding: 2vh 0;
    overflow-x: auto;

    font-size: 0.8rem;

    .buy-pdf, .buy-top {
      min-width: 152px;
      max-width: 280px;
      width: 30vw;

      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .buy-pdf, .buy-top {
      border-radius: 2vmin;
      padding: 0;
      margin: 0;

      height: 100%;
      width: 100%;

      border: none;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      font-size: 1em;
      background-color: transparent;

      box-shadow: 0 2px 2px hsla(0, 0%, 0%, 0.3),
                  0 0 12px hsla(0, 0%, 0%, 0.2);

      &::before {
        background-image: $sunny;
      }

      .buy-content, .buy-main-content {
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .buy-content {
        h2 {
          margin: 0;
          padding: 0.4em 0;
          border-radius: 2vmin 2vmin 0 0;

          background-image: $sunny;

          font-size: 2em;
          color: $white;
        }

        .buy-main-content {
          padding: 2vw;

          color: $gray_850;

          .description {
            p {
              font-weight: normal;
              text-align: left;

              &:first-child {
                margin-top: 0;

                font-weight: bold;
                text-align: center;
              }
            }
          }

          .price-range {
            min-width: 4em;
            max-width: 6em;
            width: 100%;
            margin-left: auto;
          }
        }

        .price-detail {
          width: 90%;
          margin-left: auto;
        }
      }
    }

    .buy-top {
      background-color: $light_apricot;
    }
  }

  .buy-book-text {
    margin-top: 0.5em;

    .main-description {
      padding: 0 1em;

      text-align: justify;
      letter-spacing: 0.1em;
    }
  }

  .buy-detailed-books-wrapper {
    padding: 1vh 2vw;

    .buy-detailed-book {
      min-height: 450px;
      margin-top: 1.5vh;

      display: flex;
      position: relative;

      box-shadow: 0 2px 2px hsla(0, 0%, 0%, 0.3),
                  0 0 12px hsla(0, 0%, 0%, 0.2);

      .book-format-outline {
        position: relative;
        overflow: hidden;

        .text-overlay, img {
          position: absolute;
        }

        .text-overlay {
          top: 1em;
          left: 1em;
          z-index: 2;

          h1, p {
            width: fit-content;
          }

          h1 {
            font-size: 3rem;
            text-shadow: 0 0 10px hsla(29, 50%, 20%, 0.5);
          }
        }
        img {
          top: 0;
          left: 0;

          filter: brightness(0.8) contrast(0.7);
        }
      }

      .book-format-data {
        padding: 2vmax 2vmax 2vmax;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        background-color: $white;

        h1, h2 {
          text-align: left;
        }

        .format-description {
          span {
            p {
              margin-left: 1em;
            }
          }
        }

        .format-specifications {
          margin: 0;
          margin-left: 1em;
          padding: 0;

          li {
            &::marker {
              content: "";
            }

            ul {
              padding-left: 1em;

              em {
                font-weight: normal;
              }
            }

            em {
              width: 8em;

              font-weight: 600;
              font-style: normal;
              text-align: right;

              &::after {
                content: ":"
              }
            }

            span {
              display: flex;
              flex-direction: row;

              em, p {
                margin: 0;
              }

              p {
                margin-left: 0.5em;

                font-weight: 200;
                color: $gray_850;
              }
            }
          }
        }

        .format-pricing {
          margin-top: 1em;
          
          display: flex;
          flex-direction: column;

          align-items: flex-end;

          .price-range {
            width: 132px;
            margin-right: 0.5em;
          }

          .btn-command-format {
            min-width: 140px;
            margin: 0;
            margin-top: 1em;
            margin-left: auto;
  
            font-size: 1.5rem;
            text-align: center;
          }
        }
      }
    }
  }

  .books-pricing {
    h1 {
      margin: 1.5em 0 1em;
    }

    .table-wrapper {
      padding: 0 1vw 12px;

      overflow-x: auto;

      &::before {
        display: none;
      }

      .price-table {
        margin: 0 auto;
      }
    }
  }

  @media(max-width: 642px) {
    .buy-detailed-book {
      border-radius: 1vmax 1vmax 0 0;
  
      flex-direction: column;
  
      .book-format-outline {
        min-height: 20vh;
  
        img {
          width: 100%;
          border-radius: 1vmax;
        }
      }

      .book-format-data {
        height: 60%;
  
        border-left: 4px solid $apricot;

        h2 { margin: 0.3em 0; }

        .format-description {
          h1 {
            margin: 0;
          }

          p {
            margin: 0.3em 0 !important;
          }
        }

        .format-specifications {
          margin-left: 0 !important;

          li {
            em {
              width: 50% !important;
            }
            p{
              width: 50%;
            }
          }
        }
      }

      .format-pricing {
        margin-top: 2em !important;

        flex-direction: row !important;
        justify-content: flex-end !important;
        align-items: center !important;

        .btn-command-format {
          margin: 0 !important;
        }
      }
    }
  }
  
  @media(min-width: 643px) {
    .buy-detailed-book {
      border-radius: 1vmax 0 0 1vmax;

      flex-direction: row;
  
      .book-format-outline {
        width: 40%;
  
        img {
          height: 100%;
          border-radius: 1vmax;
        }
      }

      .book-format-data {
        width: 60%;
  
        border-top: 4px solid $apricot;
  
        box-shadow: -1px 0 20px hsla(0, 100%, 100%, 0.5);

        .format-specifications {
          li {
            margin-top: 0.4em;

            &:first-child {
              margin-top: 0;
            }

            ul {
              margin-top: 0.2em;

              li {
                margin-top: 0.1em;
              }
            }
          }
        }
      }
    }
  }  
}
