button,
.btn {
  padding: 1vh 2vh;
  border-radius: 100rem;
  font-family: $headers-font;
  font-weight: bold;
  font-size: 1rem;
  outline: none;
  margin: 2vh 0;
  cursor: pointer;
}

.link-blue-back {
  justify-content: center;
  display: flex;
  padding-top: 5px;
  margin-top:-5px;
  text-align: center;
  width: 15px;
  height: 28px;
  border-radius: 12px;
  background-color: $blue-25;
  color: $blue-500;
  padding-right: 8px;
  padding-left: 8px;
}

.btn-white {
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid $blue-500;
  border-radius: 8px;
  color: $blue-500;
  background-color: $white;
  text-align: center;
  width: 43%;
  font-size: 13px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.light-blue-btn {
  border-radius: 8px;
  color: $blue-500;
  background-color: $blue-25;
  text-align: center;
  width: 48%;
  font-size: 13px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.light-blue-btn.link-employee {
  width: 100%;
  height: 47px;
}

.btn-dashboard {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.btn-blue {
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  color: $white;
  background-color: $grass;
  text-align: center;
  width: 43%;
  font-size: 13px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-wite-blue {
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid $grass;
  border-radius: 8px;
  color: $grass;
  background-color: $white;
  text-align: center;
  width: 48%;
  margin-top: 0px;
  font-size: 13px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-reset {
  border: none;
  background-color: white;
  cursor: pointer;
  font-weight: normal;
}

.btn-round {
  $base-size: 32px;

  border-radius: 50%;

  height: $base-size;
  width: $base-size;

  padding: 0;
}

.btn-shadowed {
  box-shadow: 0 0 5px $gray_300,
              0 1px 1px $middle_gray;
}

.break-pre-line {
  white-space: pre-line;
}

.main-cta {
  margin: 1rem auto;
  max-width: 80vw;
}

.main-cta-extra-margin {
  margin: 2rem auto;
  max-width: 80vw;
}

.center-fit {
  margin: 0 auto;
}

.btn-apricot {
  background-color: $apricot;
  color: $white;
  border: none;
}

.btn-sunny {
  background-color: $apricot_510;
  color: $white;
  border: none;
}

.btn-pro {
  background-image: $pro;
  color: $white;
  border: none;
}

.btn-light-pro {
  background-color: $white;
  color: $grass;
  border: $border-small solid $grass;
  font-weight: normal;
  padding: 0.5vh 1.5vh;
}

.outline-apricot {
  background-color: $white;
  color: $apricot;
  border: $outline-width solid $apricot;

  &.btn {
    padding: calc(1vh - $outline-width) 2vh;
  }
}

.outline-sunny {
  background-image: $outline-sunny;
  color: $apricot;
  border: $outline-width solid transparent;
}

// .outline-grass {
//   background-image: $outline-grassy;
//   color: $grass;
//   border: $outline-width solid transparent;
// }

.btn-grass {
  background-color: $grass;
  color: $white;
  border: none;
}

.btn-grassy {
  background-color: $grass;
  color: $white;
  border: none;
}

.outline-grass {
  background-color: $white;
  color: $grass;
  border: $outline-width solid $grass;
}

.outline-grassy {
  background-image: $outline-grassy;
  color: $grass;
  border: $outline-width solid transparent;
}

.btn-gray {
  background-color: $middle_gray;
  color: $white;
  border: none;
}

.outline-gray {
  border: $outline-small-width solid $middle_gray;
}

a.outline-gray {
  color: $middle_gray;
  font-weight: normal;
}

.btn-light-apricot {
  background-color: $white;
  color: $apricot;
  border: $border-small solid $apricot;
  font-weight: normal;
  padding: 0.5vh 1.5vh;
}

// Plus buttons
.plus-btn {
  background-color: $apricot;
  border: none;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .plus {
    color: $white;
    font-size: 1.5rem;
  }

  &:focus {
    background-color: $dark_apricot;
  }
}

// Edit buttons
.btn-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2%;
  font-size: 75%;
  width: 22px;
  height: 22px;
}

.btn-edit-gray {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $middle_gray;
  font-size: 100%;
  width: 22px;
  height: 22px;
  border: none;
  background-color: $white;
}

.edit-btn {
  .apricot-bg {
    color: $apricot;
    font-size: 1.5rem;
  }

  .edit {
    color: $white;
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
}

.btn-fa {
  background: none;
  border: none;

  border-radius: 50%;

  i {
    font-size: 2rem;
  }

  &.btn-fa-big {
    i {
      font-size: 3rem;
    }
  }

  &.right {
    color: $grass;
  }

  &.wrong {
    color: $peach;
  }
}

// Delete buttons
.btn-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2%;
  font-size: 75%;
  width: 22px;
  height: 22px;
}

.btn-edit-gray {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $middle_gray;
  font-size: 100%;
  width: 22px;
  height: 22px;
  border: none;
  background-color: $white;
}

.delete-btn {
  .apricot-bg {
    color: $apricot;
    font-size: 1.5rem;
  }

  .delete {
    color: $white;
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
}

// close buttons
.btn-close {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2%;
  font-size: 50%;
  width: 20px;
  height: 20px;
  margin: 0;
}

.position-close-btn {
  position: absolute;
  top: -5px;
  right: -4px;
}

// Back buttons
.btn-back {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $apricot;
  background-color: $white;
  border: none;

  .text-back {
    font-size: 1rem;
    margin: 0 2vw;
  }
}

// Like & dislike buttons
.icons-opinion {
  display: flex;
  align-items: center;
  margin: 1vh 0;

  .btn-opinion {
    background-color: $white;
    border: none;
    padding: 0;
    margin: 0 2vw;
    font-size: 1.6rem;
  }

  .btn-like {
    color: $apricot;
  }

  .btn-dislike {
    transform: rotate(-180deg);
    color: $dark_gray;
  }
}

// Add an avatar btn
.avatar-plus {
  border-radius: 50%;
  color: $middle_gray;
  font-size: 2rem;
  box-sizing: content-box;
}

// Search-bar
.search-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2vw;
  margin: 2vh 0;
  border-radius: $component-radius;

  .form-group {
    width: 90%;
  }

  input {
    font-family: $body-font;
    font-size: 1rem;
    border: none;
    width: 100%;
    outline: none;
  }
  input::placeholder {
    color: $middle_gray;
  }
}

.search-btn {
  color: $apricot;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 1.26rem;
  font-family: $headers-font;
  font-weight: bold;
  padding: 0;
  margin: 1vh 0;
  z-index: 1;
}

@media (max-width: 700px){
  .btn-blue {
    width: 41%;
  }

  .btn-white {
    width: 41%;
  }
}
