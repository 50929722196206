$hr-color: $apricot_200;
$hr-width: 1px;
hr {
  margin: 0;
  &.vertical-hr {
    border-left: $hr-width solid $hr-color;
    color: transparent;
  }
  &.horizontal-hr {
    margin: 0;
    border-top: $hr-width solid $hr-color;
    color: transparent;
  }
}

.border-radius-10 {
  border-radius: $component-radius;
}
