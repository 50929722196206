@import "books/buy";
@import "books/pay";
@import "books/order";
@import "books/guide";

#book_global_authorizations {
  height: 15rem;
  width: 100%;
}

.book-cards {
  display: grid;
  align-self: center;
  justify-items: center;

  .btn-light-apricot {
    padding: 0.2vh 1.2vh;
    font-weight: bold;
  }

  @media screen and (min-width: $breakpoint-phone) and (max-width: $breakpoint-tablet) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: $breakpoint-phone) {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: $breakpoint-tablet) {
  .book-cards {
    display: flex;
    align-self: center;
    flex-wrap: wrap;
    justify-content: center;
    min-width: 80vw;
  }
}

.book-project-intro {
  margin: 0 2rem;
  justify-content: center;

  img {
    border: $light_gray solid $border-small;
    max-width: 40vh;
    max-height: 90vw;
    min-height: 30vh;
  }
}

.book-base-wrapper {
  display: flex;
  flex-direction: column;
}

.book-index-container {
  width: 100%;
}

.book-show-container {
  min-height: 80vh;
}

.book-decorated {
  width: 79.5vw; // TODO: check where is lost this .5vw
  margin: auto;
}

.book-project-management {
  flex-grow: 1;
  flex-basis: 40vh;
}

.book-project-checklist {
  list-style: none;
  width: fit-content;
  align-self: center;
  margin-left: -1.5rem;

  .product-status {
    &.done {
      color: $grass;
    }

    &.to-do {
      color: $dark_apricot;
    }
  }
}

.book-product-presentation {
  margin-top: 2rem;

  p {
    padding: 2rem;
    align-self: center;
    text-align: center;
    @media screen and (min-width: $breakpoint-phone) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  img {
    object-fit: cover;
  }

  .section-1 {
    img,
    p {
      width: 50%;
      max-height: 16rem;
    }
  }

  .section-2 {
    img {
      width: 40%;
    }
  }

  .section-3 {
    img {
      height: 10rem;
      max-width: 30rem;
      align-self: center;
    }

    ul {
      width: 60%;
      margin: auto 0;
      padding: 0;
      align-self: center;
      @media screen and (min-width: $breakpoint-phone) {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }

      li {
        margin-bottom: 0.5em;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .section-1,
  .section-2 {
    p {
      @media screen and (min-width: $breakpoint-phone) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }
}

.book-decor-right,
.book-decor-left {
  position: fixed;
  top: 0;
  width: 10vw;
  height: 100%;
  background-repeat: repeat-y;
  background-size: initial;
  background-position-y: center;

  img {
    width: 100%;
    object-fit: contain;
  }
}

.book-decor-right {
  right: 0;
  background-position-x: left;
}

.book-decor-left {
  left: 0;
  background-position-x: right;
}

.book-intro {
  margin: 0 2rem;
  justify-content: center;

  img {
    max-width: 40vh;
  }
}

.book-orders-wrapper {
  width: 100%;
  margin: 2rem auto 0;

  > h1, > h2, > h3 {
    margin: 0 0 1rem;
    color: $dark_apricot;
  }

  .detail {
    margin: 0.5em;
  }
}

$out_transition: 0.3s;
$transition_decal: 0.1s;
$decaled_out_transition: #{$out_transition - $transition_decal};
$in_transition: 0.1s;
a.return-to-book-nav {
  &::before {
    content: "<-";
    position: absolute;
    left: 0.8rem;
    transform: scale(1);
    -webkit-transform: scale(1);
    transition: transform $decaled_out_transition,
      -webkit-transform $decaled_out_transition, left $out_transition;
  }

  height: 1.2rem;
  min-width: 0.2rem;

  div {
    width: 0;
    color: transparent;
    margin-left: 0;
    transition: margin-left $out_transition;
  }

  &:hover {
    &::before {
      left: 0.6rem;
      transform: scale(1.5);
      -webkit-transform: scale(1.5);
      transition: transform $in_transition, -webkit-transform $in_transition,
        left $in_transition;
    }

    div {
      width: auto;
      color: $white;
      margin-left: 1rem;
      transition: margin-left $in_transition;
    }
  }
}
