#contact-form-container {
  textarea {
    min-height: 12vh;
    padding: 1vh;
    border-radius: $component-radius;
  }

  input {
    border-radius: $component-radius;
    padding: 1vh;
  }

  form {
    @media (min-width: 1025px) {
      min-width: 50vw;
    }
  }
}
