.menu-div-img {
  img {
    max-width: 175px;
  }
}

.navbar-container {
  &.hidden {
    display: none;
  }
}

.slided-logo {
  transition: 300ms;
  right: -120px;
}

.menu-div-links:not(.compressed-links) {
  max-height: 500px;
  transition: max-height 200ms ease-in;
}

.all-menu {
  z-index: 900;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.link-burger {

  &.native {
    display: unset;
  }

  &.menu-margins {
    top: 295px;
    bottom: $navbar-height;
  }

  &.hidden {
    display: none;
  }

  .text-green {
    color: $grass;
    font-size: 20px;
    font-weight: 700;
    font-family: $headers-font;
  }

  .text-orange {
    color: $dark_apricot;
    font-size: 20px;
    font-weight: 700;
    font-family: $headers-font;
  }

  .menu-user {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    grid-row: 1/2;
    position: absolute;
    top: 45%;
    width: 100%;

    .menu-universe-container {
      margin-left: 5%;

      .text-blue {
        font-weight: bold;
      }
    }

    .subtitle-menu {
      font-size: 13px;
      color: $grass;
      opacity: 50%;
      text-align: center;
      font-weight: bold;
      margin-top: 50px
    }
  }

  .menu-user-pro {
    position: absolute;
    top: 30%;
  }

  .menu-pro-link {
    position: relative;
  }

  .menu-beomai-pro {
    margin-top: 25%;
  }

  .menu-beomai {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-shrink: 1;
    grid-row: 2/3;
  }

  .menu-component-beomai {
    .link {
      font-weight: normal;
    }
  }

  .font-menu-p {
    font-family: $headers-font;
    margin-right: 10px;
  }

  .btn-menu-about {
    margin-right: 0px;
    width: 78px;
  }

  .menu-component {
    margin-top: 25px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    color: $dark_gray;
    font-size: 1.13rem;
    text-align: right;

    .i-width {
      text-align: center;
    }

    .fa-user {
      color: $blue-200;
      width: 25px;
    }

    .text-burger-pro {
      margin-top: 10px;
    }

    p,
    .text-component {
      margin: 5px;
      margin-left: 10px;
      text-align: right;
    }

    .text-component {
      font-size: 1rem;
      font-family: $body-font;
      font-weight: normal;
      color: $dark_gray;
      display: block;
    }

    h2 {
      color: $apricot;
      font-size: 1.3rem;
      text-align: left;
    }

    a {
      font-weight: normal;
    }
  }

  .menu-premium {
    color: $apricot;
  }
}

.menu-width-pro {
  width: 100vw;
  border-left: $outline-width solid $grass;
}

@media (max-width: 850px) {
  .menu-beomai {
    top: 55%;
  }
}

@media (max-width: 650px) {
  .link-burger {
    .menu-user {

    }
  }
}

@media (max-width: 450px) {
  .link-burger {
    .menu-user {

    }
  }
}

.current-btn {
  color: $apricot !important;
}
