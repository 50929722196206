.quote-top,
.quote-bottom {
  height: 2rem;
  width: 2rem;
}

.quote-top {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.quote-bottom {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
