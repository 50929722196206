.list-argument {
  list-style: none;
  padding-left: 0;
  li {
    margin: 1.2rem 0rem;
  }
  span {
    line-height: 1.8rem;
  }
}
