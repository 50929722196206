.colored-container-light-apricot {
  background: $light_apricot-transparency-50;
}

.colored-container-light-grass {
  background: $light_grass-transparency-50;
  h3 {
    color: $grass;
  }
}

.colored-container {
  margin: $margin-xl-large auto;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
  border-radius: $component-radius;
  padding: 2rem 2rem;
  line-height: 1.5rem;
  max-width: 80%;
  h1 {
    color: $dark_gray;
  }

  h1,
  p {
    text-align: center;
  }

  .colored-container-columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    column-gap: 5rem;
  }

  .column {
    width: 50%;
    flex-grow: 0;
  }

  .content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 480px;
  }

  .content .big-fas {
    font-size: 3rem;
  }

  .content-text {
    width: 25rem;
  }

  .content-text h3 {
    text-align: left;
  }

  .content-text p {
    text-align: justify;
  }

  .picto {
    margin-top: 18px;
    margin-right: $margin-large;
    align-self: self-start;
  }
}

@media (max-width: 767px) {
  .colored-container {
    .content {
      width: 320px;
    }
    .picto {
      align-self: center;
      width: 50px;
    }
  }
}
