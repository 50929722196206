.message-mobile {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $light_apricot;
}

.message-draft-booking {
  margin-top: 10px;
}

.message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $light_apricot;
  padding: 0.5rem;
  position: relative;
  max-height: 90vh;
  transition: max-height 0.5s ease,
              padding 0.5s linear;

  .text-p {
    margin: 0;
  }

  .message-links {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0;

    .btn {
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }

  a, .message-btn {
    font-size: 0.65rem;
    display: flex;
    align-items: center;
  }

  button.btn, a.btn {
    margin: 0.5rem 0;
    font-size: 0.75rem;
    line-height: normal;
  }

  .btn-grass.outline {
    color: $grass;
    background: $light_grass;
    border: $border-medium solid $grass;
  }

  .btn-apricot.outline {
    color: $apricot;
    background: $light_apricot;
    border: $border-medium solid $apricot;
  }
}

.message-grass {
  background: $light_grass;
}

.hidden {
  display: none;
}

@media (max-width: 767px) {
  .message-mobile {
    display: none;
  }
}


