@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital@0;1&&family=Satisfy&family=Parisienne&display=swap');

.onirique-skin {
  font-family: 'Montserrat', sans-serif;
  font-size: 12pt;
  line-height: normal;
  color: #000000;

  .watermark {
    .A4::after {
      font-family: 'Montserrat', sans-serif !important;
    }
  }

  .title {
    font-family: "Satisfy";
    font-size: 40pt;
    color: #32679b;
  }

  .entries {
    .title, .name {
      font-weight: 400;
      color: #2f3366;
      font-size: 12pt;
      font-family: 'Montserrat Alternates', sans-serif;
    }

    .date {
      color:  #ec6639;
      font-size: 12pt;
      font-family: 'Montserrat Alternates', sans-serif;
    }

    .photo {
    }

    .photo-illustration {
    }

    .content {
    }
  }

  .journal-section-title {
    font-family: "Satisfy";
    font-size: 40pt;
    color: #32679b;
    z-index: 2;
  }

  .chapter {
    .number, .title {
      font-family: "Satisfy";
      color: #32679b;
    }

    .number {
      font-size: 136pt;
    }

    .title {
    }
  }

  .cover {
    .title {
      font-size: 34pt;
      color: #ed6a4a;
      font-family: 'Satisfy', serif;
    }

    .subtitle {
      font-size: 18pt;
      color: #993333;
      font-family: 'Montserrat Alternates', sans-serif;
    }

    .author {
      font-size: 14pt;
      color: #707070;
      font-family: 'Montserrat Alternates', serif;
      font-style: italic;
    }

    .date {
      font-size: 16pt;
      color: #ed6a4a;
      font-family: 'Montserrat Alternates', serif;
    }
  }

  .parents {
    .title {
    }

    .name {
      font-size: 16pt;
      color: #32488d;
      font-family: "Montserrat Alternates";
    }

    .nickname {
      font-family: "Motserrat";
      font-style: italic;
      font-size: 13pt;
      color: #32669a;
    }

    .photo {
    }
  }

  .quotes {
    .quote-open, .quote-close {
      font-size: 66pt;
      color: #32679b;
      font-family: "Parisienne";
    }

    .quote-text {
      font-family: "Parisienne";
      font-size: 18pt;
      color: #000000;
    }

    .quote-author {
      font-size: 18pt;
      color: #32679b;
      font-family: "Parisienne";
    }
  }

  .letter {
    .title {
      font-family: 'Satisfy';
      font-weight: 200;
      font-size: 18pt;
      color:  #292929;
    }

    .subtitle {
      font-family: 'Montserrat Alternates', sans-serif;
      font-weight: 400;
      font-style: italic;
      font-size: 14pt;
      color:  #2a2a2a;
    }

    .signature {
      font-family: 'Montserrat Alternates', sans-serif;
      font-weight: 700;
      font-size: 14pt;
      color:  #2a2a2a;
    }

    .quote-text {
    }
  }

  .summary {
    font-family: "Satisfy";
    color: #9a3434;

    .title {
      font-size: 61pt;
    }


    .table {
      top: 68mm;
    }

    .summary-chapter {
      height: 19mm;

      .name {
        font-size: 16pt;
        color: #33679a;
        font-family: 'Montserrat Alternates', sans-serif;
        line-height: 1.75;
      }

      .number {
        font-size: 30pt;
        color: #33679a;
        font-family: 'Montserrat Alternates', sans-serif;
      }

      .page {
        font-size: 15pt;
        color: #33679a;
        font-family: 'Montserrat Alternates', sans-serif;
        line-height: 1.75;
      }
    }

    .summary-section {
      .name {
        font-size: 13pt;
        color: #6897c7;
        font-family: 'Montserrat Alternates', sans-serif;
      }

      .number {
        font-size: 20pt;
        color: #6897c7;
        font-family: 'Montserrat Alternates', sans-serif;
      }

      .page {
        font-size: 13pt;
        color: #6897c7;
        font-family: 'Montserrat Alternates', sans-serif;
      }
    }
  }

  .stats {
    .graph-title {
      font-family: "Montserrat Alternates" ;
      font-size: 14pt;
      color: #6898c6;
    }
  }

  .babies {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 14pt;
    color: #6898c6;

    .name {
      font-family: "Satisfy";
      font-size: 40pt;
      line-height: 40pt;
      color: #32679b;
    }
  }

  .header {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 10pt;
    color: #6898c6;
  }

  .footer {
    .logo-footer {
      path {
        fill:#ec663b;
      }
    }

    .page-number {
      font-family: "Satisfy";
      font-size: 16pt;
      color: #9a3434;
    }
  }
}
