@import "fonts";
@import "colors";
@import "gradients";

@import "sizing";
@import "spacing";

@import "borders";
@import "shadows";

@import "components";

// Global CSS variables
:root {
  --wheelsize: min(96vw, 45vh);
}

// Media queries
$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
