.sticky-menu {
  position: sticky;
  top: 0;
  z-index: 80;
}

.topnav {
  background-color: white;
  overflow: hidden;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.26);
}

.topnav a {
  float: left;
  display: block;
  font-size: 0.9rem !important;
  font-weight: 400;
  color: $dark-gray;
  text-align: center;
  padding: 1.2rem 1.2rem;
  text-decoration: none;
}

.topnav .icon {
  display: none;
  font-size: 1.3rem !important;
  color: $apricot;
}

.hamburger {
  display: none;

  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: $apricot;
  }
}

.dropdown {
  float: left;
  overflow: hidden;

  .drop-btn {
    margin: 0;
    border: none;
    outline: none;
    background-color: white;
    color: $dark_gray;
    font-size: 0.9rem !important;
    font-weight: 400;
    padding: 1.23rem 1rem;
    margin: 0;
  }
  .pro {
    color: $grass;
  }
  .user {
    color: $apricot;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 10rem;
    z-index: 1;
    border: 0.1rem solid $apricot;
    border-radius: 10px;
  }

  .dropdown-content a {
    float: none;
    color: $dark_gray;
    padding: 0.75rem 1rem;
    text-decoration: none;
    display: block;
    text-align: left;
  }
}

.topnav a:hover,
.dropdown:hover .drop-btn {
  color: $grass;
  transition: all 0.3s ease-in-out;
}

.dropdown:hover .pro {
  color: $dark_grass;
}

.dropdown:hover .user {
  color: $dark_apricot;
}

.dropdown-content a:hover {
  color: $grass;
  transition: all 0.3s ease-in-out;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 1024px) {
  .topnav a:not(:first-child),
  .dropdown .drop-btn {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }

  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .topnav.responsive .dropdown {
    float: none;
  }

  .topnav.responsive .dropdown-content {
    position: relative;
    border: none;
    background-color: $light_apricot;
    border-radius: 0;
  }

  .topnav.responsive .dropdown-content-pro {
    background-color: $light_grass;
  }

  .topnav.responsive .dropdown .drop-btn {
    display: block;
    width: 50%;
    text-align: left;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
