// Absolute positions within an A4 page

.special-events {
  .title,
  .event-blocs,
  .timeline-illustration,
  .timeline-right,
  .timeline-left,
  .bloc-date,
  .bloc-photo-1,
  .bloc-photo-2,
  .bloc-text {
    position: absolute;
  }

  .event-bloc {
    position: relative;
  }

  .bloc-photo-1,
  .bloc-photo-2 {
    object-fit: cover;
  }

  .bloc-text {
    font-family: "Montserrat";
    font-size: 11pt;
    color: #373737;
    text-align: justify;
  }

  .bloc-text.clamped {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
  }

  .bloc-date {
    font-family: "Montserrat Alternates";
    font-weight: 700;
    font-size: 14pt;
    color: #ec663a;
  }
}

.special-events-10 {
  .title {
    left: 12.6mm;
    top: 34.6mm;
    width: 185mm;
    height: 28mm;
    font-size: 40pt;
    text-align: center;
  }

  .event-blocs {
    top: 70mm;
    width: 201mm;
  }

  .event-bloc {
    margin-bottom: 2.5mm;
    width: 201mm;
    height: 36.7mm;

    object, object img {
      height: 100%;
      width: 100%;
    }

    .bloc-date {
      top: 3mm;
      width: 75mm;
      height: 8.5mm;
    }

    .bloc-text {
      width: 75mm;
      height: 19mm;
      top: 14mm;
    }

    .bloc-photo-1,
    .bloc-photo-2 {
      width: 30mm;
      height: 30mm;
      top: 3mm;
    }
  }

  .timeline-illustration {
    top: 70mm;
    width: 48mm;
    height: 230mm;
  }

  .timeline-left {
    right: 0;
  }

  .timeline-right {
    left: 0;
  }

  &.right-page {
    .event-blocs {
      left: 0;
    }

    .bloc-date {
      left: 63mm;
    }

    .bloc-text {
      left: 57mm;
    }

    .bloc-photo-1 {
      left: 134mm;
    }

    .bloc-photo-2 {
      left: 167mm;
    }
  }

  &.left-page {
    .event-blocs {
      left: 12.6mm;
    }

    .bloc-date {
      left: 71mm;
    }

    .bloc-photo-1 {
      left: 3mm;
    }

    .bloc-photo-2 {
      left: 37mm;
    }

    .bloc-text {
      left: 71mm;
    }
  }
}

.special-events-16 {
  .title {
    left: 12.6mm;
    top: 27mm;
    width: 185mm;
    height: 20mm;
    font-size: 30pt;
  }

  .event-blocs {
    top: 47mm;
    width: 201mm;
  }

  .event-bloc {
    margin-bottom: 1.5mm;
    width: 201mm;
    height: 27mm;

    object, object img {
      height: 100%;
      width: 100%;
    }

    .bloc-date {
      top: 3mm;
      width: 94mm;
      height: 7mm;
    }

    .bloc-text {
      width: 82mm;
      height: 15mm;
      top: 10.5mm;
    }

    .bloc-photo-1,
    .bloc-photo-2 {
      width: 20mm;
      height: 20mm;
      top: 3mm;
    }
  }

  .timeline-illustration {
    top: 47mm;
    width: 48mm;
    height: 237mm;
  }

  .timeline-left {
    right: 0;
  }

  .timeline-right {
    left: 0;
  }

  &.right-page {
    .event-blocs {
      left: 0;
    }

    .bloc-date {
      left: 61mm;
    }

    .bloc-text {
      left: 61mm;
    }

    .bloc-photo-1 {
      left: 148mm;
    }

    .bloc-photo-2 {
      left: 171mm;
    }
  }

  &.left-page {
    .title {
      text-align: center;
      font-size: 30pt;
    }

    .event-blocs {
      right: 0;
    }

    .bloc-date {
      left: 57mm;
    }

    .bloc-photo-1 {
      left: 10mm;
    }

    .bloc-photo-2 {
      left: 33mm;
    }

    .bloc-text {
      left: 57mm;
    }
  }
}
