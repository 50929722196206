.modal-content-validate {
  height: 40% !important;
}

.modal-content-summary {
  height: 64% !important;

  .validate-service {
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;

    .btn-validate-service {
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .summary-pool {
    margin-top: 40px;

    p {
      margin-left: 7%;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  h5 {
    text-align: center;
  }

  .service-infos {
    margin-left: 5% !important;
  }

  .modal-summary {
    margin: 20px 20px;
  }

  h6 {
    margin-left: 0px !important;
    margin-top: 10px !important;
  }

  p {
    color: $middle_top_grey;
    font-style: italic;
    font-size: 15px;
  }

  .summary-service {
    margin-bottom: 0px;
  }

  .summary-price {
    margin-top: 0px;
  }

  .location-service {
    p {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
}

