.textarea-description {
  font-size: 14px;
  border: none;
  border-bottom: solid 1px $gray_300;
  margin-top: 10px;
  color: $black;
  line-height: 20px;
  height: 50px;
  width: 255px;
}
// CARD REWORK
.card-offer {
  width: 310px;
  border: 1px solid $grass;
  padding: 1rem;
  margin: 10px;
  box-shadow: 3px 6px 9px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .services-additionnal-informations {
    margin-top: auto;
  }

  h3 {
    font-size: 14px;
    line-height: 20px;
  }

  h4 {
    font-size: 12px;
    line-height: 16px;
    margin-top: 5px;
  }

  h4, h3 {
    text-align: left;
    color: $dark_gray;
    margin: 0px 5px 0px 0px;
  }

  .card-title-subtitle{
    margin-bottom: 15px;
  }

  .card-offer-photo {
    width: 100px;
    align-self: flex-start;
    margin-bottom: 10px;
  }

  .card-offer-description-container {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;

    .card-description {
      font-size: 12px;
      text-align: left;
      line-height: 16px;
    }
  }

  .additionnal-informations {
    font-size: 12px;
    text-align: left;
    line-height: 16px;
  }

  .booking-info-message {
    font-size: 12px;
    text-align: left;
    line-height: normal;
  }

  .package-description {
    font-size: 12px;
    min-height: 50px;
    text-align: left;
    line-height: 16px;
  }

}
.pro-exclusivity {
  position: absolute;
  top: 0.5rem;
  right: -1.5rem;
  transform: rotate(45deg);
}

// used inshowcase site
.cards-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  color: $white;
  margin: $margin-xl-large 0;
  h2,
  h3 {
    margin: 0.65rem 0;
    color: $white;
  }
}

.card {
  padding: 1.5rem;
  width: 17rem;
  height: 17rem;
  margin: $margin-large;
  border-radius: $component-radius;
  text-align: justify;
  color: $white;
  box-shadow: $grey-box-shadow;

  .paragraphs {
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
  }

  i {
    margin-right: $margin-medium;
  }

  i.large {
    font-size: 5rem;
  }

  p {
    margin: 0.5rem 0;
  }
}

.cover-card {
  background-size: cover;
}

.apricot-card {
  background-color: $apricot_510;
}

.dark-green-card {
  background-color: $grass_650;
}

.peach-card {
  background-color: $peach_510;
}

.sunny-card {
  background-color: $sun_510;
}

.relative {
  position: relative;
}

.stripe {
  position: absolute;
  top: -6%;
  right: -8%;
}

// Service suggestions cards
.service-suggestions-card-wrapper {
  border: solid 1px $grass;
  border-radius: $component-radius;
  margin: 1rem 2rem;
  box-shadow: $grey-box-shadow;
  .service-suggestions-title {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: $white;
    background: $grass;
    text-align: center;
    font-weight: bold;
    padding: 0.1rem;
  }
  .package-title {
    background: $apricot;
  }
  .service-suggestion-info {
    align-self: flex-start;
    padding: 0.5rem 1rem;
  }
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-left: 5px;
  }
}

// services card
.services-container {
  margin: $margin-xl-large auto;
  border-radius: $component-radius;
  border: 1px solid $grass;
  padding: 2rem 1rem;
  line-height: 1.5rem;

  h2 {
    text-align: left;
  }

  .service-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .service-card-off {
      border: 2px solid $apricot !important;
    }

    i {
      margin-right: $margin-small;
      color: $grass;
    }

    .button-switch {
      color: $white;
      background-image: $sunny;
      border: none;
    }

    .service-card-off {
      i {
        color: $apricot;
      }

      .button-switch {
        color: $white;
        background-color: $middle_gray;
      }
    }
  }

  .service-card {
    border-radius: $component-radius;
    border: 1px solid $grass;
    box-shadow: $grey-box-shadow;
    margin: $margin-medium;
    font-size: small;
    width: 290px;

    .service-card-title {
      border-top-left-radius: $component-radius;
      border-top-right-radius: $component-radius;
      color: $white;
      background: $grass;
      text-align: center;
      font-weight: bold;
      padding: 1rem;

      i {
        color: $white;
      }
    }

    .service-card-title-off {
      background: $middle_gray;
    }

    .service-information {
      margin: 0.5rem;
      max-width: 160px;
      height: 130px;
    }

    .author {
      text-align: left;
      padding-left: 1rem;
      margin: $margin-small $margin-medium;
      font-size: small;
    }
  }
}


.booking-pro-card {
  border: solid 1px $grass;
  border-radius: $component-radius;
  padding: 1rem;
  margin: 1rem;

  .booking-pro-card-header {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 2rem;
  }
}

.status-active {
  display: flex;
  justify-content: center;

  p {
    margin-top: 0px;
    font-size: 16px;
  }
}

.status-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  margin-top: 5px;
}

.status-circle.active {
  background-color: green;
}

.status-circle.inactive {
  background-color: red;
}
