.workspace-intro {
  margin-bottom: 3rem;
  margin-top: 3rem;
  h1 {
    color: $dark-gray;
  }
  p {
    text-align: center;
  }
}

.photo-company-size {
  width: 100px;
  margin-top: 30px;
  border-radius: 8px;
}

.workspace-features-list {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 3rem;
  .feature-pro {
    border: 1px solid $grass;
    border-radius: $component-radius;
    padding: 1.2rem 1rem;
    margin: 1rem;
    width: 10rem;
    height: 8rem;
    .fas, p {
      color: $grass;
    }
    .fas {
      font-size: 4rem;
    }
    &:hover {
      border: 1px solid $dark-grass;
      .fas, p {
        color: $dark-grass;
      }
    }
  }
  .feature-pro-disabled {
    border: 1px solid #c5c5c5;
    border-radius: $component-radius;
    padding: 1.2rem 1rem;
    margin: 1rem;
    width: 10rem;
    .fas, p {
      color: #c5c5c5;
    }
    .fas {
      font-size: 4rem;
    }
    &:hover {
      border: 1px solid #c5c5c5;
      .fas, p {
        color: #c5c5c5;
      }
    }
    span {
      font-size: 10px;
      font-weight: 400;
      color: #c5c5c5;
      line-height: 90%;
    }
  }
}

.contact-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  .contact-box-item {
    width: 40%;
    min-height: 10rem;
    text-align: center;
    img {
      width: 15rem;
      height: 15rem;
      border-radius: 50%;
    }
    h2 {
      color: $dark-gray;
    }
    span {
      line-height: 3rem;
    }
  }
}

@media (max-width: 767px) {
  .contact-box {
    gap: 0;
    .contact-box-item {
      width: 80%;
      img {
        width: 13rem;
        height: 13rem;
      }
    }
  }
}
