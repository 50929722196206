$pregnancy-photo-max-width: 90%;
$pregnancy-photo-max-height: 5rem;
$pregnancy-photos-wrapper-columns: 2;
$pregnancy-photos-wrapper-rows: 7;
$pregnancy-photos-gap: 1%;
$pregnancy-photo-width: 40rem;

.preg-story,
.preg-dates,
.preg-nicknames {
  margin-bottom: 1rem;
}

.btn-delete-pregnancy {
  margin-top: 50px;
  margin-bottom: 50px;
}

a.link-cgv {
  color: #000;
  text-decoration: underline;
  font-size: 12px;
  font-weight: normal
}

.pregnancy-create {
  .tab-content {
      display: none;
    }

  .tab-content.active {
    display: block;
  }
}

.number-children {
  display: flex;
  margin: auto;

  .form-control {
    font-weight: bold;
    font-size: 20px;
    border-color: black;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 0px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: none;
    background-color: #fff;
  }

  .wrapper-inline {
    padding: 0px;
  }

  .btn {
    display: flex;
    flex-direction: column;

    button {
      width: 23px;
      height: 24px;
      background-color: white;
      border: 1px solid black;
      border-radius: 5px;
      margin: 2px;
      padding: 0px;
    }
  }
}

.tab-date {
  padding: 0px;
  display: flex;
  list-style-type: none;
  justify-content: space-around;

  .date-pregnancy {
    min-height: 93px;
    width: 94px;
    border: 1px solid #000;
    border-radius: 5%;
    padding: 10px;
    text-align: center;
    cursor: pointer;

    p {
      margin: 0px;
      margin-top: 7px;
      line-height: 16px;
    }
  }

  .date-pregnancy.active {
    background-color: #D9D9D9;
  }
}

.preg-members {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .preg-member {
    margin: 0 2vw;
    width: 23vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      height: 0;
    }

    p {
      font-size: 0.8rem;
      text-align: center;
      color: $dark-gray;
      margin-top: 5px;
    }
  }

  .remove-bg {
    color: $dark-apricot;
  }

  .remove {
    color: $white;
  }

  .fa-stack {
    position: relative;
    bottom: 1.25rem;
    left: 3vh;
    height: 0;
    font-size: 0.6rem;
  }
}

.preg-dates-details {
  padding: 0px 1rem 0px 1rem;

  ul {
    list-style: none;
    padding: 0px 0.5rem 0px 0px;
    width: 100%;
  }

  li {
    display: flex;
    justify-content: center;
  }

  .fas {
    width: 2rem;
    text-align: center;
  }
}

.new-preg-dates {
  display: flex;
  justify-content: space-around;
  padding: 0 2vw;
  width: 35%;
  margin: auto;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  li {
    display: flex;
    align-items: baseline;
  }

  .fas {
    width: 2rem;
    text-align: center;
    font-weight: 400;
  }
}

.preg-nicknames,
.preg-story {
  position: relative;
  padding: 0 1rem;

  p {
    margin: 0;
  }
}

// align turbo frames
#preg-story-info,
#preg-nicknames-info {
  .form-group {
    margin: 0.5rem 0;
    margin-top: 0;
  }

  label {
    margin-bottom: 0;
  }

  form {
    p {
      margin-top: 1rem;
    }
  }

  .column-group {
    margin-top: 0;
  }
}

.pregnancy-participation-deactivation {
  margin-bottom: 1rem;
  padding: 0 2vw;

  p {
    text-align: center;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.pregnancy-participation-deactivation {
  margin-bottom: 1rem;
  padding: 0 2vw;

  p {
    text-align: center;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.no-pregnancy-home {
  display: flex;
  flex-direction: column;

  p {
    text-align: center;
  }
}

.pregnancy-grey-divider {
  border-bottom: $grey-divider;
  margin: $margin-large;
}

#pregnancy_photos {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-height: 40rem;

  .pregnancy-list-wrapper{
    max-height: 100%;
    width: 49%;
    overflow: hidden;

    .infinite-scroll {
      height: 90%;
      overflow-y: auto;
    }

    .pregnancy-photos-wrapper {
      display: grid;
      grid-template-columns: repeat($pregnancy-photos-wrapper-columns, auto);
      grid-template-rows: repeat($pregnancy-photos-wrapper-rows, max-content);
      grid-gap: $pregnancy-photos-gap 1px;

      align-items: start;

      padding: 0 0 20% 2%;

      overflow: hidden;

      .pregnancy-photo {
        cursor: pointer;

        input[type="checkbox"] {
          display: none;
        }
        img {
          border-radius: 16px;
          max-height: $pregnancy-photo-max-width;
          max-width: $pregnancy-photo-max-width;
          border: 3px solid transparent;
        }
      }

      &.discriminated-photos {
        height: 100%;
        overflow-y: auto;
        .pregnancy-photo {
          img {
            border-color: $apricot;
            &:hover {
              border-color: $dark_grass;
            }
          }
        }
      }

      &.undiscriminated-photos {
        .pregnancy-photo {
          img {
            &:hover {
              border-color: $apricot;
            }
          }
        }
      }
    }
  }
}
