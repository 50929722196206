.book-card {
  font-family: $body-font;
  width: 7rem;
  height: 12.5rem;
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: $component-radius-medium;
  border: $apricot solid $border-medium;

  .book-preview {
    width: 5.7rem;
    height: 60%;
    margin: 0.25rem 0;
    border: $light_gray solid $border-small;
    background-color: $light_gray;
    display: flex;
    flex-grow: 1;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .book-name {
    height: 20%;
    color: $dark-gray;
    font-weight: bold;
    text-align: center;
  }

  .book-status {
    height: 10%;
    color: $dark-gray;
    font-weight: normal;
    text-align: center;
  }
}

.section-card {
  max-width: 22rem;
  border: $border-big solid $light-gray;
  border-radius: $component-radius-medium;
  align-items: center;
  padding: 0.2rem 0.4rem;
  background: $white;

  &::after {
    content: "ne pas inclure";
    display: flex;
    justify-content: center;
  }

  p {
    margin: 0;
  }

  img {
    height: 10rem;
    min-width: 0;
    min-height: 0;
    background: $light-gray;
    flex-shrink: 0;
    filter: grayscale(1);
  }

  ul {
    font-weight: normal;
    flex-grow: 1;
    flex-basis: 50%;
  }

  .section-title {
    margin: 0.2rem;
    width: 100%;
    text-align: center;
  }

}

input[type=checkbox]:checked + label {
  .section-card {
    border: $border-big solid $apricot;

    p {
      font-weight: bold;
    }

    img {
      filter: grayscale(0);
    }

    &::after {
      content: "inclure";
      color: $apricot;
    }
  }
}

// remove circle
input[name="book_content[sections][]"],
input[name="book_content[variant]"] {
  display: none;
}

.cover-preview-card {
  width: 7rem;
  border: $border-big solid $light-gray;
  border-radius: $component-radius-medium;
  align-items: center;
  padding: 0.2rem 0.4rem;
  background: $white;

  p {
    margin: 0;
    width: 100%;
    text-align: center;
  }

  img {
    height: 10rem;
    min-width: 0;
    min-height: 0;
    background: $light-gray;
    flex-shrink: 0;
    filter: grayscale(1);
  }
}

input[type=radio]:checked + label {
  .cover-preview-card {
    border: $border-big solid $apricot;

    p {
      font-weight: bold;
    }

    img {
      filter: grayscale(0);
    }
  }
}
