// Absolute positions within an A4 page

.header {
  position: absolute;
  left: 12.7mm;
  top: 12.7mm;
  width: 185mm;
  height: 14.3mm;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
