.chatrooms-layout {
  display: flex;
  margin-top: -15px;
  width: 100vw;
  margin-left: -2vw;

  .btn-chatroom-mobile {
    border: none;
    display: none;
    padding-top: 5px;
    margin-top:7px;
    text-align: center;
    width: 15px;
    height: 28px;
    border-radius: 12px;
    background-color: $blue-25;
    color: $blue-500;
    padding-right: 20px;
    padding-left: 12px;
    margin-right: 10px;
  }

  .btn-mobile-chat {
    display: none;
  }

  .link-blue-back.back-button {
    display: none;
    border: none;
    width: 27px;
    height: 29px;
  }

  .btn-display-offer {
    position: absolute;
    right: 10px;
    top: 18%;
    width: 80px;
    font-size: 11px;
    margin-top: -20px;
    height: 22px;
    color: rgba($blue-500, 0.5);
    padding: 5px;
    border-radius: 10px;
    background-color: $light_gray;
  }

  .btn-display-offer.btn-display-offer-show {
    top: 6%;
  }

  .btn-close-offer {
    position: absolute;
    right: 16px;
    top: 15%;
  }
}

.btn-close-offer.btn-close-offer-show {
  top: 3%;
}

.title-section-margin {
  margin-top: 37px;
}

.chatroom-link {
  font-weight: normal;
}

.chatrooms-user {
  height: calc(100vh - 155px);
}

.chatrooms-pro {
  height: calc(100vh - 110px);
}

.chatroom-list-pro {
  height: calc(100vh - 85px);
}

.chatroom-list-user {
  height: calc(100vh - 170px);
}

.chatrooms-list {
  margin-top: 30px;
  width: 30%;
  border-right: 1px solid #ccc;
  overflow-y: auto;

  h2 {
    margin-bottom: 40px;
  }

  .info-message-index {
    margin-left: 10%;
    width: 100%;

    p {
      margin-top: 0px;
    }

    .pro-name {
      justify-content: space-between;
      margin-right: 20px;

      .message-date {
        font-size: 13px;
        margin-top: 3px;
      }
    }
  }
}

.messages-panel {
  flex: 1;
  margin-top: 30px;
}

.chatroom-item.selected {
  background-color: $light_apricot;
}

.chatroom-item {
  padding: 10px;
  padding-left: 20px;
  margin-bottom: 20px
}

.chatroom-layout {
  display: flex;
  width: 100%;
}

.chatroom-messages-container {
  flex: 2;
  border-right: 1px solid #ccc;
  padding: 1rem;
  padding-top: 0px;
  margin-top: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .img-user-chat {
    border-radius: 50%;
    margin-right: 20px;
    margin-left: 10px;
  }

  .user-img-name {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .date-heading {
    font-size: 13px;
    text-align: center;
    margin-top: 20px;
  }

  .messages {
    overflow: scroll;
    padding: 1rem;
    height: 59vh;
  }

  .messages-pro {
    height: 68vh;
  }

  .message {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .message-info {
    opacity: 0.5;
    text-align: center;
    width: fit-content;
  }

  .message-content {
    border-radius: 10px;
    margin-bottom: 1rem;
    padding: 0.5rem;
    width: fit-content;
    word-wrap: break-word;
  }

  .input-message {
    width: 97%;
    border: 1px solid rgba($blue-500, 0.5);
    border-radius: 20px;
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
    margin-bottom: 20px;
    resize: none;
  }

  .message-chatroom-mail {
    display: none;
    margin-top: 10px;
  }

  .input-message::placeholder {
    color: $grass;
    opacity: 50%;
    line-height: 40px;
  }

  .message.sender-style {
    .message-content {
      align-self: flex-start;
      background-color: $light_apricot;
    }

    .message-info {
      text-align: right;
      width: 100%;
    }
  }

  .message.receiver-style .message-content {
    align-self: flex-start;
    background: $blue-50;
  }

  .message-row.display-sender {
    justify-content: flex-end;
  }

  .message-row.display-receiver {
    justify-content: flex-start;
  }
}

.chatroom-offer-details {
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;

  .img-offer {
    width: 100%;
  }

  .btn-chat {
    width: 190px;
    padding: 10px;
    border: none;
    height: 100%;
    line-height: 20px;
    margin: auto;

    img {
      margin-right: 15px;
      width: 18px;
    }
  }
}

@media (max-width: 700px) {
  .chatroom-show {
    display: block;
  }

  .chatroom-offer-details {
    display: none;
  }

  .display-block {
    display: block !important;
  }

  .chatrooms-list {
    width: 45%;
  }

  .chatroom-messages-container {
    border: none;

    .form-message-mobile {
      width: 97%;
      border: 1px solid rgba(101, 163, 249, 0.5);
      border-radius: 20px;
      height: 40px;
      line-height: 45px;
      padding-left: 10px;
      margin-bottom: 20px;

      .input-message {
        border: none;
        overflow: hidden;
        height: 35px;
        outline: none;
      }

      .btn-chatroom-mobile {
        display: block;
      }
    }
  }

  .chatrooms-layout {
    .btn-mobile-chat {
      display: block;
    }

  }
}

@media (max-width: 500px) {
  .chatrooms-layout {
    flex-direction: column;

    .link-blue-back.back-button {
      display: inline-block;
    }

    .chatroom-messages-container {
      margin-top: -5px;
    }
  }

  .chatrooms-list {
    width: 100%;
    border-right: none;
  }

  .messages-panel {
    width: 100%;
    display: none;
  }

  .messages-panel.visible {
    display: block;
  }

  .chatrooms-list.hidden {
    display: none;
  }
}

@media (max-height: 800px) {
  .chatrooms-layout {
    .chatroom-messages-container {
      .messages {
        height: 55vh;
      }

      .messages-pro {
        height: 62vh;
      }
    }
  }
}

@media (max-height: 700px) {
  .chatrooms-layout {
    .btn-display-offer {
      top: 6%;
    }
  }

  .chatrooms-layout {
    .chatroom-messages-container {
      .messages {
        height: 48vh;
      }
    }
  }
}


