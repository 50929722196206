.tag {
  font-family: $body-font;
  outline: none;
  margin: 1vh 0;
  border: none;
  color: $dark_gray;
  background-color: $sun-transparency-50;
  border-radius: 100rem;
  text-align: center;
}

.tag-olive {
  font-family: $body-font;
  outline: none;
  margin: 1vh 0;
  outline: none;
  border: none;
  color: $dark_gray;
  background-color: $olive;
  border-radius: 100rem;
  text-align: center;
}

.card-offer {
  .tag-olive {
    margin: 5px 5px !important;
  }
}

.tag-grass {
  font-family: $body-font;
  outline: none;
  margin: 1vh 0;
  outline: none;
  border: none;
  color: $dark_gray;
  background-color: $grass;
  border-radius: 100rem;
  text-align: center;
}

.tag-sun {
  font-family: $body-font;
  outline: none;
  margin: 1vh 0;
  outline: none;
  border: none;
  color: $dark_gray;
  background-color: $sun;
  border-radius: 100rem;
  text-align: center;
}

.tag-light-apricot {
  font-family: $body-font;
  outline: none;
  margin: 1vh 0;
  outline: none;
  border: none;
  color: $dark_gray;
  background-color: $light_apricot;
  border-radius: 100rem;
  text-align: center;
}

.tag-peach {
  font-family: $body-font;
  outline: none;
  margin: 1vh 0;
  outline: none;
  border: none;
  color: $dark_gray;
  background-color: $peach;
  border-radius: 100rem;
  text-align: center;
}

.tag-small {
  height: 1rem;
  font-size: 0.75rem;
  font-weight: lighter;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-top: 0;
  padding: 0.2rem 0.8rem;
}

.tag-big {
  font-size: 0.9rem;
  font-weight: bold;
  flex-shrink: 0;
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 0.2rem;
  padding: 0.4rem 1rem
}

.tag-sunny {
  background-image: $sunny;
  color: $white;
  border: none;
}

.card-tag-list {
  display: flex;
  overflow: scroll;
}

.card-tag-list::-webkit-scrollbar {
  display: none;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
