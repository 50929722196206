// Import page-specific CSS files here.
@import "components/_index";
@import "pages/_index";

// WARNING! Add media queries for tablets (landscape) & mobile in all web moni-site pages !

.content-wrapper {
  min-height: 100vh;
}

// ##Device = Desktops
// ##Screen = 1824px to higher resolution desktops

@media (min-width: 1824px) {
  .content-wrapper {
    margin: 0 15vw;
  }
}

// ##Device = Desktops
// ##Screen = 1281px to higher resolution desktops

@media (min-width: 1281px) and (max-width: 1824px) {
  .content-wrapper {
    margin: 0 8vw;
  }
}

// ##Device = Laptops, Desktops
// ##Screen = B/w 1025px to 1280px

@media (min-width: 1025px) and (max-width: 1280px) {
  .content-wrapper {
    margin: 0 5vw;
  }
}

// ##Device = Tablets, Ipads (portrait)
// ##Screen = B/w 768px to 1024px

@media (min-width: 768px) and (max-width: 1024px) {
  .content-wrapper {
    margin: 0 4vw;
  }
}

// ##Device = Tablets, Ipads (landscape)
// ##Screen = B/w 768px to 1024px

@media (max-width: 767px) {
  .content-wrapper {
    margin: 0 1vw;
  }
}
// @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

//   /* CSS */

// }

// ##Device = Low Resolution Tablets, Mobiles (Landscape)
// ##Screen = B/w 481px to 767px

// @media (min-width: 481px) and (max-width: 767px) {

//   /* CSS */

// }

// ##Device = Most of the Smartphones Mobiles (Portrait)
// ##Screen = B/w 320px to 479px

// @media (min-width: 320px) and (max-width: 480px) {

//   /* CSS */

// }
