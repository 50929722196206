.tag-job-specialities {
  border-radius: 100rem;
  padding: 0.4rem 1rem;
}

.tag-sun-transparent {
  background-color: $sun-transparency-50;
}

.tag-grassy {
  background-color: $light_grass;
}
