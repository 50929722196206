.web-logo {
  width: 4rem;
}

.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  img {
    height: 6.5rem;
  }
  margin: $margin-xl-large 0;
}

@media (max-width: 1024px) {
  .web-logo {
    width: 5rem;
  }
}
