.tabs-team-container{
  .hidden-checkbox-input {
    display: none !important;
  }

  .service-location-checked {
    border-bottom: 0.3rem solid #39639f;
    color: #39639f;
  }

  @media (max-width: 550px) {
    .input-location-service {
      flex-direction: column !important;
    }
  }
}

