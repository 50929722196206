@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,600;0,700;0,800;1,600&family=Pacifico&display=swap');

.calin-skin {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 13pt;
  line-height: normal;
  color: #44657f;

  .watermark {
    .A4::after {
      font-family: 'Montserrat', sans-serif !important;
    }
  }

  .title {
    font-family: "Pacifico";
    font-size: 40pt;
    color: #32679b;
  }

  .entries {
    .name {
      color: #44657f;
      font-size: 11pt;
      font-family: "Nunito";
      font-weight: 800;
      justify-content: flex-start;
    }

    .title {
      color: #44657f;
      font-size: 13pt;
      font-family: "Pacifico";
    }

    .date {
      color:  #fff;
      font-size: 14pt;
      font-family: 'Pacifico';
    }

    .photo {
    }

    .photo-illustration {
      border: 1.5pt solid #ed6c4d;
      border-radius: 0 10mm;

      img {
        border-radius: 0 6mm;
      }
    }

    .date-illustration {
      background: #ed6c4d;
      border-radius: 4mm 0;
    }

    .text-0-photo-landscape, .text-0-photo-portrait {
      .date-illustration {
        width: 60.6mm;
        border-radius: 4mm 0 0 0;
      }
    }

    .name-bg {
      left: -12mm;
    }

    .content {
      font-size: 12pt;
    }
  }

  .journal-section-title {
    font-family: "Pacifico";
    font-size: 40pt;
    color: #ff5e5e;

    &.journal-t1, &.journal-delivery {
      color: #ed6c4d;
    }

    &.journal-t2 {
      color: #ffce40;
    }

    &.journal-t3 {
      color: #ff5e5e;
    }

    &.journal-first-months {
      color: #44657f;
    }
  }

  .chapter {
    .number, .title {
      font-family: "Pacifico";
      color: #f9cdca;
    }

    .number {
      font-size: 150pt;
    }

    .title {
      font-size: 35pt;
    }
  }



  .cover {
    &.cover_fourth {
      .photo {
        top: 59mm;
        left: 40mm;
        height: 88mm;
        width: 129mm;
      }
    }

    .photo-variant {
      .title {
        font-size: 35pt;
        color: #44657f;
        font-family: 'Pacifico', serif;

        span {
          background: #fff;
          border-radius: 4mm;
          padding: 0 4mm;
          height: 105%;
          display: flex;
          align-items: center;
        }
      }

      .subtitle {
        font-size: 21pt;
        color: #ffffff;
        font-family: 'Nunito', sans-serif;
        font-weight: 800;
      }

      .author {
        font-size: 15pt;
        color: #ffffff;
        font-family: 'Nunito', serif;
        font-style: italic;
        font-family: 600;
      }

      .date {
        font-size: 16pt;
        color: #ed6c4d;
        font-family: 'Nunito';
        font-weight: 700;
        background: white;
        border-radius: 2mm;
        padding: 0 2mm;
      }
    }

    .text-variant {
      .title {
        font-size: 35pt;
        color: #44657f;
        font-family: 'Pacifico', serif;

        span {
          display: flex;
          align-items: center;
          text-align: center;
        }
      }

      .subtitle {
        font-size: 21pt;
        color: #ed6c4d;
        font-family: 'Nunito', sans-serif;
        font-weight: 800;
      }

      .author {
        font-size: 15pt;
        color: #ed6c4d;
        font-family: 'Nunito', serif;
        font-style: italic;
        font-family: 600;
      }

      .date {
        font-size: 16pt;
        color: #ed6c4d;
        font-family: 'Nunito';
        font-weight: 700;
        background: white;
        border-radius: 2mm;
        padding: 0 2mm;
      }
    }
  }

  .parents {
    .title, .name, .nickname {
      .profile-duo {
        color: #44657f;
      }

      .profile-solo {
        color: #ed6c4d;
      }
    }

    .title, .name {
      font-family: "Pacifico";
    }

    .title {
      font-size: 53pt;
    }

    .name {
      font-size: 20pt;
    }

    .nickname {
      font-size: 12pt;
      font-family: 'Nunito';
      font-weight: 600;
    }

    .photo {
      border-radius: 50%;
    }

    .description {
      padding-top: 2mm;

      top: 170mm;
      left: 24mm;
      width: 163.5mm;
      height: 74mm;
    }
  }

  .quotes {
    .quote-open, .quote-close {
      font-size: 34pt;
      color: #44657f;
      font-family: "Pacifico";
    }

    .quote-text {
      font-family: "Pacifico";
      font-size: 18pt;
      color: #44657f;
      line-height: 1.3;
    }

    .quote-author {
      font-size: 12pt;
      color: #44657f;
      font-family: "Nunito";
    }
  }

  .letter {
    .photo {
      border-radius: 50%;

      img {
        object-fit: cover;
      }
    }

    .title {
      font-family: 'Pacifico';
      font-weight: 400;
      font-size: 20pt;
      color:  #44657f;
    }

    .signature {
      font-family: 'Pacifico';
      font-weight: 400;
      font-size: 14pt;
      color:  #44657f;
    }

    .text, .subtitle {
      font-size: 12pt;
      font-weight: 600;
      line-height: 1.3rem;
      font-style: "Nunito";
      color: #ed6c4d;
    }
  }

  .summary {
    color: #44657f;
    font-weight: normal;

    .title {
      top: 17mm;

      font-size: 70pt;
      color: #ffce40;
      font-family: 'Pacifico';
    }

    .summary-chapter {
      .name {
        font-size: 19pt;
        font-family: 'Pacifico', serif;
        line-height: 1.75;
      }

      .number {
        font-size: 40pt;
        font-family: 'Nunito', sans-serif;
        line-height: 1.15;
        font-weight: 700;
      }

      .page {
        font-size: 17.5pt;
        font-family: 'Pacifico', serif;
        line-height: 1.75;
      }
    }

    .summary-section {
      font-family: 'Nunito', sans-serif;
      font-size: 15.5pt;
      font-weight: 700;

      .number {
        font-size: 28px;
      }

      .page {
        font-size: 17.5pt;
        font-weight: normal;
        font-family: 'Pacifico', serif;
        line-height: 1.75;
      }
    }

  }

  .mosaic {
    .title {
      font-size: 25pt;
    }

    &.mosaic-20 > .title {
      color: #ed6c4d;
    }

    &.mosaic-40 > .title {
      color: #ffce40;
    }
  }

  .special-events {
    &.special-events-10 {
      .bloc-illustration {
        top: -1mm;
        position: absolute;
      }
    }
    &.special-events-16 {
      .bloc-photo-1, .bloc-photo-2 {
        top: 4.5mm;
      }
    }

    .title {
      font-size: 25pt;
      color: #44657f;
    }

    .bloc-date {
      color: #ed6c4d;
      font-size: 14pt;
      font-family: "Pacifico";
    }

    .bloc-text {
      font-weight: 600;
      color: #44657f;
    }
  }

  .stats {
    .title {
      font-size: 30pt;
      color: #ed6c4d;
    }

    .graph-title {
      font-size: 14pt;
      color: #ed6c4d;
      font-family: "Nunito";
      font-weight: 700;
    }
  }

  .babies {
    .title {
      font-family: "Pacifico";
      font-size: 53pt;
      line-height: 1;
    }

    .name {
      font-family: "Pacifico";
      font-size: 25pt;
    }

    .date, .height, .weight, .message {
      font-family: "Nunito";
      font-size: 15pt;
      font-weight: 600;
    }

    &.variant-1 {
      color: #44657f;
    }

    &.variant-2 {
      & > *:not(.header, .footer) {
        color: #fde8de !important;
      }
    }

    .baby-photo {
      left: 24mm;
      top: 75mm;
      width: 158.5mm;
      height: 80mm;
    }
  }

  .header {
    font-family: 'Nunito', sans-serif;
    font-size: 12pt;
    color: #44657f;
    font-weight: 700;

    span {
      background: #fff;
      padding: 0.5mm 2.5mm;
      width: fit-content;
      border-radius: 5mm;
    }
  }

  .footer {
    .logo-footer {
      path {
        fill:#44657f;
      }
    }

    .page-number {
      font-family: "Pacifico";
      font-size: 12pt;
      color: #44657f;

      object {
        height: 10mm;
        width: 10mm;
      }
    }
  }
}
