.card-contributor {
  p {
    color: $grass;
    margin-bottom: 0px;
    margin-top: 10px;

    span {
      color: $blue-500;
    }
  }

  h3 {
    margin-bottom: -5px;
  }

  .date-participant {
    width: 100%;
  }

  .card-participants-infos {
    width: 100%;
    justify-content: space-between;
  }
}

