.appointment-pool {
  .appointment-pool-block {
    border: 1px solid $blue-20;
    background-color: $blue-50;
    border-radius: 16px;
    padding: 20px;
  }

  .text-blue {
    text-align: left;
  }

  p {
    font-size: 13px;
  }

  .icone-btn {
    width: 18px;
    margin-right: 5px;
  }
}
