.card-show-offer {
  width: 90%;
  margin: auto;
  margin-bottom: 20px;

  .tag-offer {
    width: 45%;
    justify-content: flex-start;
    display: flex;
    margin: auto;
    margin-right: 10px;
    margin-left: 20px;
  }

  .btn-card-offer {
    margin-right: 10px;
  }

}

.info-card-offer {
  width: 70%;
}

.div-width-photo-offer {
  max-width: 40%;
}

.event-block {
  border: 1px solid $blue-20;
  background-color: $blue-50;
  border-radius: 16px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .info-card-offer {
    h3 {
      text-align: left;
      font-size: 16px;
      margin-top: 0px;
      margin-bottom: 5px;
    }

    .p-border {
      border-bottom: 1px solid $blue-20;
      padding-bottom: 4px;
    }

    p {
      text-align: left;
      color: $grass;
      font-size: 13px;
      margin-top: 0px;
    }
  }

  h3 {
    font-size: 13px;
  }

  .tags-event {
    .tag-active {
      background: $green-50;
      color: $green-200;
      border-radius: 7px;
      margin-right: 10px;
      font-weight: 500;
      font-size: 11px;
      height: 24px;
    }

    .tag-inactive {
      background: $palepink;
      color: $crimson-red;
      border-radius: 7px;
      margin-right: 10px;
      font-weight: 500;
      font-size: 11px;
      height: 24px;
    }

    .tag-collective {
      background: $medium_apricot;
      color: $white;
      border-radius: 7px;
      margin-right: 10px;
      font-weight: 500;
      font-size: 11px;
      height: 24px;
    }

    .tag-event {
      background: $blue-100;
      color: $grass;
      border-radius: 7px;
      margin-right: 10px;
      font-weight: 500;
      font-size: 11px;
      height: 24px;
    }
  }

  .btn-service {
    border: 1px solid $grass;
    border-radius: 8px;
    color: $white;
    background-color: $grass;
    height: 35px;
    align-items: center;
    width: 40px;
    display: flex;
    justify-content: center;
    margin-left: 5px;
  }

  .btn-like-offer {
    border: 1px solid $grass;
    border-radius: 8px;
    background-color: $blue-50;
    height: 35px;
    align-items: center;
    width: 40px;
    display: flex;
    justify-content: center;
  }

  .icone-btn-offer {
    width: 18px;
  }

  .btn-card-offer {
    display: flex;
    justify-content: center;
    align-items: center;

    .fa-heart {
      width: 18px;
    }
  }

  .text-like {
    margin-left: 10px;
    margin-top: 25px;
    margin-bottom: 0px;
    color: $dim_grey;
    font-size: 11px;

    .fa-heart {
      color: $brick_red;
    }
  }

  .h3-title {
    border-bottom: 1px solid $blue-20;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .event-infos {
    display: flex;
    width: 75%;

    .card-event-infos {
      width: calc(100% - 112px);
    }
  }
}

@media (max-width: 600px) {
  .card-show-offer {
    .tag-offer {
      width: 100%;
    }
  }
  .event-block {
    display: block;

    .event-infos {
      width: 100%;
    }

    .text-like {
      text-align: center;
    }
  }
}

