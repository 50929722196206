.babies {
  .tabs {
    display: flex;
    border-bottom: 2px solid $light_gray;
    margin-bottom: 20px;
  }

  .tab-link {
    background-color: $light_gray;
    border: 1px solid $light_gray;
    border-bottom: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 5px;
    border-radius: 5px 5px 0 0;
    font-size: 16px;
  }

  .tab-link:hover {
    background-color: $light_gray;
  }

  .tab-link.active {
    background-color: $white;
    font-weight: bold;
  }

  .tab-content {
    border: 1px solid $light_gray;
    padding: 20px;
    border-radius: 0 5px 5px 5px;
  }

  .tab-pane {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .tab-pane.active {
    display: block;
    opacity: 1;
  }

  .tab-link:focus {
    outline: 2px solid $black;
  }
}
