.text-left-pic-right {
  margin-top: $web-margin-large;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  h1 {
    text-align: left;
  }
  .fas {
    font-size: 11rem;
    text-align: center;
    color: $grass;
  }
  .item {
    width: 40rem;
  }
}

.text-left-pic-right > :last-child {
  text-align: center;
}

@media (max-width: 1024px) {
  .text-left-pic-right {
    flex-direction: column-reverse;
    align-items: center;
    h1,
    p,
    .fas {
      text-align: center;
      margin-left: 0;
    }
  }
  .text-left-pic-right > :first-child {
    margin-right: auto;
    margin-left: auto;
    width: 80%;
  }
}
