// Absolute positions within an A4 page

.parents {
  .title, .name, .nickname, .photo {
    position: absolute;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .title {
    left: 39mm;
    top: 38mm;
    width: 134mm;
    height: 18mm;
  }

  .profile-1, .profile-2 {
    .photo {
      top: 78mm;
      width: 55.5mm;
      height: 55.5mm;
      object-fit: cover;
    }

    .name {
      top: 145mm;
    }

    .nickname {
      top: 152mm;
    }

    .name, .nickname {
      width: 74mm;
      height: 7.5mm;
    }
  }

  .profile-1 {
    .photo {
      left: 31mm;
    }

    .name, .nickname {
      left: 21.8mm;
    }
  }

  .profile-2 {
    .photo {
      left: 123mm;
    }

    .name, .nickname {
      left: 114mm;
    }
  }

  .profile-solo {
     .photo {
      left: 69mm;
      top: 76mm;
      width: 72mm;
      height: 72mm;
      object-fit: cover;
    }

    .name {
      left: 21.8mm;
      top: 154mm;
      width: 168mm;
      height: 7.5mm;
    }

    .nickname {
      left: 21.8mm;
      top: 162mm;
      width: 168mm;
      height: 7.5mm;
    }
  }

  .description {
    position: absolute;
    left: 21.8mm;
    top: 170.5mm;
    width: 168mm;
    height: 80mm;
    white-space: pre-wrap;
  }

  .description-solo {
    position: absolute;
    left: 21.8mm;
    top: 182.5mm;
    width: 168mm;
    height: 80mm;
    white-space: pre-wrap;
  }
}
