.data-finance-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  h1 {
    text-align: center;
  }

  p {
    text-align: justify;
  }

  .fas {
    font-size: 8rem;
    margin-right: 2rem;
  }
  .finance-description {
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .data-finance-container {
    flex-direction: column;
  }
}

// TODO : Fix this.
.img-gradient-shadow {
  width: 70%;
  height: 70%;
  background-image: $sunny;
  border-radius: $component-radius;
  transform: rotate(338deg);
}

.double-arg-container,
.single-arg-container {
  margin: $web-margin-large 0;

  .rigth-text,
  .left-text {
    display: flex;
    align-items: center;
  }

  .image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 3 1 0;
  }

  .img-gradient-shadow {
    width: 70%;
    height: 70%;
    background-image: $sunny;
    border-radius: $component-radius;
    transform: rotate(338deg);
  }

  img {
    border-radius: $component-radius;
    transform: rotate(22deg);
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .argument {
    flex: 3 1 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    h3 {
      margin-top: 0;
      text-align: center;
    }

    p {
      text-align: justify;
    }
  }
}

.double-arg-container {
  display: grid;
  grid-template: 47% 6% 47% / repeat(2, 1fr);
  min-height: 95vh;

  .rigth-text {
    grid-area: 1/1/2/3;
  }

  .left-text {
    grid-area: 3/1/4/3;
  }
}

.single-arg-container {
  display: flex;
  min-height: 45vh;
}

.arg-point {
  display: flex;
  align-items: center;

  img {
    width: 3vh;
    height: 3vh;
  }
}
