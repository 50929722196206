.avatar-lg {
  height: 8em;
  width: 8em;
  overflow: hidden;
  margin: 0 auto;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
