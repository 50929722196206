// intro
.picture-moods {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 1rem;
  img {
    width: 45%;
    border: 1px solid $light_apricot;
    border-radius: $component-radius;
  }
}

.text-no-three-moods {
  margin-top: 17%;
}

.mood-legend {
  display: flex;
  justify-content: space-between;
  margin-left: 166px;
  margin-right: 16%;
  margin-bottom: -5%;

  .mood-index {
    color: white;
    padding: 5px;
    border-radius: 5px;
  }

  .positive {
    background-color: $red;
  }

  .negative {
    background-color: $grass;
  }
}

// Today's emotions
.btn-add-mood {
  margin-top: 17%;
  margin-top: 30px;

  a {
    border-radius: 10px;
  }
}

.text-no-three-moods {
  p {
    font-size: 14px;
    margin-top: 5%;
  }
}
.emotions-of-the-day {
  display: flex;
}

.user-emotion-day {
  width: 50%;
    .mood-day-subtitle {
      font-size: 1rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: 0rem;
    }
  .emotion-name-of-today-message {
      margin-top: -1.25rem;
  }
  .edit-mood-icon {
    margin-top: -2.25rem
  }
}

.emotion-today-comparision-board {
  border: $light-divider;
  border-radius: $component-radius;
  text-align: center;
  padding: 1.56rem 1rem;
}

.invite-gif {
  width: 5.25rem;
  height: 5.25rem;
  border-radius: $component-radius-round;
  margin-bottom: 3.5rem;
  margin-top: 2rem;
}

.mood-gif {
  width: 6.25rem;
  height: 8.25rem;
  border-radius: $component-radius-round;
  margin-bottom: 2.5rem;
  object-fit: contain;
}

// Mood stats day to day


// Mood stats dominant
.top-three-mood-board {
  padding: 1.25rem 1.25rem;
  border: $grey-divider;
  border-radius: $component-radius;
  .top-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .top-mood-gif {
    border-radius: $component-radius-round;
  }
  .top-mood-gif-first {
    width: 7.2rem;
    height: 9rem;
  }
  .top-mood-gif-other{
    width: 5.6rem;
  }
}

// generic mood stats
.mood-stats-container {
  margin: $margin-medium auto;
  margin-bottom: 60px;

  .toggle {
    margin: 0.5rem auto;
  }
  h2 {
      margin-top: 2rem;
  }

  .loader-discovery-size {
    height: auto;
    width: auto;

    .lds-ring {
      margin: 0 4.625rem;

      height: 1.25rem;
      width: 1.25rem;
    }
  }
}

.top-emotion-container {
  margin: $margin-medium 0;
}

.mood-separator-line {
  width: 40%;
  border: 1px solid $grass;
  border-radius: 10px;
  margin: $margin-medium auto;
}

// Mood stats Dominant Pie
.top-rank-emotion-name {
  margin-top: 0.7rem;
  text-align: center;
}

// Other for mood page index
.emotion-timelines {
  display: flex;
}

.member-emotion-timeline {
  width: 50%;
}
// emotional evolution
.score-period-moods {
  display: flex;
  align-items: center;
  justify-content: center;
  .item-period-moods {
    margin-right: 0.62rem;
    margin-bottom: 0.43rem;
  }
}

.mood-picker-container {
  position: relative;
}

// wheel
#wheel {
  margin: 2rem auto;
  width: var(--wheelsize);
  height: var(--wheelsize);
  position: relative;
  bottom: calc(100vh - var(--wheelsize) - 35vh);
  top: 0;
  left: 0;
  right: 0;
  .wheel-labels {
    position: relative;
    height: 100%;
  }

  svg {
    filter: drop-shadow(0px 0px 7px rgba(80, 80, 80, 0.4));
    transition: all 1s ease;
    position: absolute;
    top: 0;
  }

  &.wheel-bottom {
    position: relative;
    bottom: calc(var(--wheelsize) * -0.6);
    transition: bottom 1s ease;
  }

  .dimension-box {
    position: absolute;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    font-size: small;
    margin: auto;
    padding: 0.2rem;
    z-index: 1;
    transition: all 1s ease;
    pointer-events: none;
  }

  .position-1 {
    left: 36%;
    top: 3%;
    width: 25%;
    height: 24%;
  }

  .position-2 {
    left: 64%;
    top: 19%;
    width: 26%;
    height: 18%;
  }

  .position-3 {
    left: 70%;
    top: 47%;
    width: 25%;
    height: 19%;
  }

  .position-4 {
    left: 50%;
    top: 68%;
    width: 25%;
    height: 24%;
  }

  .position-5 {
    left: 21%;
    top: 68%;
    width: 25%;
    height: 24%;
  }

  .position-6 {
    left: 2%;
    top: 47%;
    width: 25%;
    height: 19%;
  }

  .position-7 {
    top: 19%;
    left: 8%;
    width: 26%;
    height: 18%;
  }
}

.emotion-list {
  display: flex;
  justify-content: space-evenly;
  height : var(--wheelsize);
  margin: 0 1rem;
  border: $border-medium solid;
  border-radius: $component-radius-big;
  padding: 0.2rem;

  &.hidden {
    display: none;
  }

  .positive-emotions, .negative-emotions {
    overflow-x: scroll;
    text-align: center;
    width: 50%;
    margin-top: 2rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .negative-emotions {
    border-left: $border-small $light_gray solid;
  }

  .touched.sign-header {
    font-weight: 900;
    position: absolute;
    top: 0;
    width: 48%;
    background: transparent;
    margin: 0 auto;
  }
}

.emotion-list-container {
  margin-top: 2rem;
  position: relative;
}

.emotion-1 {
  border-color: $peach;
  box-shadow: 0 0 0.5rem 0 $peach;

  .touched {
    color: $peach;
    background: $peach-transparency-20;
  }
}

.emotion-2 {
  border-color: $dark-apricot;
  box-shadow: 0 0 0.5rem 0 $dark-apricot;

  .touched {
    color: $dark_apricot;
    background: $dark_apricot-transparency-20;
  }
}

.emotion-3 {
  border-color: $apricot;
  box-shadow: 0 0 0.5rem 0 $apricot;

  .touched {
    color: $dark_apricot;
    background: $apricot-transparency-20;
  }
}

.emotion-4 {
  border-color: $sun;
  box-shadow: 0 0 0.5rem 0 $sun;

  .touched {
    color: $apricot;
    background: $sun-transparency-50;
  }
}

.emotion-5 {
  border-color: $light_grass;
  box-shadow: 0 0 0.5rem 0 $light_grass;

  .touched {
    color: $dark_grass;
    background: $light_grass-transparency-50;
  }
}

.emotion-6 {
  border-color: $grass;
  box-shadow: 0 0 0.5rem 0 $grass;

  .touched {
    color: $dark_grass;
    background: $grass-transparency-20;
  }
}

.emotion-7 {
  border-color: $dark_grass;
  box-shadow: 0 0 0.5rem 0 $dark_grass;

  .touched {
    color: $dark_grass;
    background: $dark_grass-transparency-20;
  }
}

.touched {
  transition: all 0.8s cubic-bezier(.12,.46,.52,1.5);
  font-size: 1.1rem;
  border-radius: 25px;
  padding: 0.4rem 0.2rem;
  margin: 1.3rem 0;
}

.mood-subgroup-separator {
    display: flex;
    margin: 0;
    height: 0.25rem;
}

.day_to_day_graph_container {
  width: 100%;
  height: 125px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  font-size: 0;
  .day-mood-container {
    display: inline-block;
    margin-top: 20px;
    height: 200px;
    width: 8%;
    font-size: 0.8rem;
    position: relative;
  };
  span {
    padding-left: 4px;
    position: absolute;
    top: -20px;
  };
  span:after {
    content: "";
    background: grey;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40%;
    width: 2px;
  }
}

.day_to_day_legend_container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  div {
    margin: 5px 25px;
  }
  .legend-pastil {
    margin-right: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
  }
}

.mood-bg-neutral {
  background-color: #BFBDBC;
};
.mood-bg-positive {
  background-color: #22477c;
};
.mood-bg-negative {
  background-color: #FDA663;
};
.mood-bg-anger_calm {
  background-color: #FF806C;
};
.mood-bg-sadness_hapiness {
  background-color: #FFD995;
};
.mood-bg-disgust_eargerness {
  background-color: #FFAF65;
};
.mood-bg-relationship {
  background-color: #769bd0;
};
.mood-bg-self_esteem {
  background-color: #a0b7d8;
};
.mood-bg-fear_surprise {
  background-color: #39639f;
};
.mood-bg-physical {
  background-color: #FFECDC;
}
