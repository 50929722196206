.progress-bar-pool {
  display: flex;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #ddd;

  .credit-pool-friend {
    background-color: $medium_apricot;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .credit-pool-user {
    background-color: $grass;
  }

  .credit-pool-employer {
    background-color: $blue-500;
  }

  .border-pool {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.credit-pool-values {
  display: flex;

  .friend-value {
    color: $medium_apricot;
    font-weight: bold;
  }

  .user-value {
    color: $grass;
    font-weight: bold;
  }

  .employer-value {
    color: $blue-500;
    font-weight: bold;
  }

  span {
    color: $gray_850;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px
  }
}

.progress-bar-pool div {
  height: 100%;
}
