.offers-choices-disclaimer {
  padding: 0px 10px 10px 10px;
}

.list-offer-vs-service li {
   counter-increment: muffins;
   margin-bottom: 10px;
}

.list-offer-vs-service {
   list-style: none;
   counter-reset: muffins;
}

.list-offer-vs-service li:before {
   content: counter(muffins);

   margin-right: 10px;
   width: 35px;
   height: 35px;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   font-size: 16px;
   background-color:#e3edf5;
   border-radius: 50%;
   color:#fff;
}

.flower-container {
   width: 100%;
   height: 400px;
   padding: 0;
   margin: 0;
   color: #EEEEEE;
   text-align: center;
   position: relative;

   a {
      color: inherit;
   }

   .menu-item,
   .menu-open-button {
      background: $apricot;
      border-radius: 100%;
      width: 80px;
      height: 80px;
      margin-left: -40px;
      position: absolute;
      color: #FFFFFF;
      text-align: center;
      line-height: 80px;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -webkit-transition: -webkit-transform ease-out 200ms;
      transition: -webkit-transform ease-out 200ms;
      transition: transform ease-out 200ms;
      transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
   }

   .menu-open .menu-open-button .line-1 {
      -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
      transform: translate3d(0, 0, 0) rotate(45deg);
   }

   .menu-open .menu-open-button .line-2 {
      -webkit-transform: translate3d(0, 0, 0) scale(0.1, 1);
      transform: translate3d(0, 0, 0) scale(0.1, 1);
   }

   .menu-open .menu-open-button .line-3 {
      -webkit-transform: translate3d(0, 0, 0) rotate(-45deg);
      transform: translate3d(0, 0, 0) rotate(-45deg);
   }

   .menu {
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 80px;
      height: 80px;
      text-align: center;
      box-sizing: border-box;
      font-size: 26px;
   }

   .menu-item:hover {
      background: $apricot;
      color: $peach;
   }

   .menu-item:nth-child(3) {
      -webkit-transition-duration: 180ms;
      transition-duration: 180ms;
   }

   .menu-item:nth-child(4) {
      -webkit-transition-duration: 180ms;
      transition-duration: 180ms;
   }

   .menu-item:nth-child(5) {
      -webkit-transition-duration: 180ms;
      transition-duration: 180ms;
   }

   .menu-item:nth-child(6) {
      -webkit-transition-duration: 180ms;
      transition-duration: 180ms;
   }

   .menu-item:nth-child(7) {
      -webkit-transition-duration: 180ms;
      transition-duration: 180ms;
   }

   .menu-item:nth-child(8) {
      -webkit-transition-duration: 180ms;
      transition-duration: 180ms;
   }

   .menu-item:nth-child(9) {
      -webkit-transition-duration: 180ms;
      transition-duration: 180ms;
   }

   .menu-open-button {
      z-index: 2;
      -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      -webkit-transition-duration: 400ms;
      transition-duration: 400ms;
      -webkit-transform: scale(1.1, 1.1) translate3d(0, 0, 0);
      transform: scale(1.1, 1.1) translate3d(0, 0, 0);
      cursor: pointer;
      box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
   }

   .menu-open-button:hover {
      -webkit-transform: scale(1.2, 1.2) translate3d(0, 0, 0);
      transform: scale(1.2, 1.2) translate3d(0, 0, 0);
   }

   .menu-open-button {
      margin: auto;
      -webkit-transition-timing-function: linear;
      transition-timing-function: linear;
      -webkit-transition-duration: 200ms;
      transition-duration: 200ms;
      -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
      transform: scale(0.8, 0.8) translate3d(0, 0, 0);
   }

   .menu-item {
      -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
      transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
   }

   .menu-item:nth-child(3) {
      transition-duration: 180ms;
      -webkit-transition-duration: 180ms;
      -webkit-transform: translate3d(0.08361px, -104.99997px, 0);
      transform: translate3d(0.08361px, -104.99997px, 0);
   }

    .menu-item:nth-child(4) {
      transition-duration: 280ms;
      -webkit-transition-duration: 280ms;
      -webkit-transform: translate3d(90.9466px, -52.47586px, 0);
      transform: translate3d(90.9466px, -52.47586px, 0);
   }

    .menu-item:nth-child(5) {
      transition-duration: 380ms;
      -webkit-transition-duration: 380ms;
      -webkit-transform: translate3d(90.9466px, 52.47586px, 0);
      transform: translate3d(90.9466px, 52.47586px, 0);
   }

    .menu-item:nth-child(6) {
      transition-duration: 480ms;
      -webkit-transition-duration: 480ms;
      -webkit-transform: translate3d(0.08361px, 104.99997px, 0);
      transform: translate3d(0.08361px, 104.99997px, 0);
   }

    .menu-item:nth-child(7) {
      transition-duration: 580ms;
      -webkit-transition-duration: 580ms;
      -webkit-transform: translate3d(-90.86291px, 52.62064px, 0);
      transform: translate3d(-90.86291px, 52.62064px, 0);
   }

    .menu-item:nth-child(8) {
      transition-duration: 680ms;
      -webkit-transition-duration: 680ms;
      -webkit-transform: translate3d(-91.03006px, -52.33095px, 0);
      transform: translate3d(-91.03006px, -52.33095px, 0);
   }

    .menu-item:nth-child(9) {
      transition-duration: 780ms;
      -webkit-transition-duration: 780ms;
      -webkit-transform: translate3d(-0.25084px, -104.9997px, 0);
      transform: translate3d(-0.25084px, -104.9997px, 0);
   }

   .petal-container {
     width: 100%;
     height: 100%;
       p {
         padding: 2px;
         margin: auto;
         line-height: 10px;
         font-size: 12px;
         font-weight: 600;
       }
   }

   .objectives {
      background-color: $peach;
      box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
   }

   .jobs {
      background-color: $dark_apricot;
      box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
   }

   .pregnancy_steps {
      background-color: $sun_510;
      box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
   }

   .prestation_types {
      background-color: $grass;
      box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
   }

   .prestation_formats {
      background-color: $grass_650;
      box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
   }

   .doulas {
      background-color: $peach_700;
      box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
   }
}
