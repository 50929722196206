.text-light {
  color: $middle-gray;
  font-weight: normal;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-apricot {
  color: $apricot !important;
}

.text-sun {
  color: $sun !important;
}

.faq-articles {
  h4 {
    font-size: 16px;
  }
}

.text-light-apricot {
  color: $apricot;
  font-weight: normal;
}

.text-blue {
  color: $grass;
}

.text-red {
  color: $brick_red !important;
}

.text-light-grass {
  color: $grass;
  font-weight: normal;
}

.text-bold-apricot {
  color: $apricot;
  font-weight: bold;
}

.text-bold-grass {
  color: $grass;
  font-weight: bold;
}

.text-middle_gray {
  color: $middle_gray;
}

.text-grass {
  color: $grass;
}

.text-peach {
  color: $peach !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.color-white {
  color: $white;
}

.text-dark-gray {
  color: $dark-gray;
}

.text-middle-gray {
  color: $middle_gray;
}

.underline {
  text-decoration: underline;
}

.mandatory-field {
  color: #413f3e;
  font-family: "Quicksand", "Helvetica", "sans-serif";
  font-size: 0.88em;
  font-weight: bold;
  margin: 1vh;
  margin-left: 0;
}

.fake-link {
  cursor: pointer;
  .fake-link-apricot {
    color: $apricot;
  }

  .fake-link-grass{
    color: $grass;
  }
}
