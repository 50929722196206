#company-details-container {
  p, h2, h3 {
    text-align: justify;
  }
  margin: $web-margin-large;

  a {
    color: $dark-gray;
    font-weight: normal;
  }
}
