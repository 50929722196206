.email-body {
  padding: 0 5vw;
  display: flex;
  flex-direction: column;
  text-align: center;

  .disclaimer {
    color: $middle-gray;
    font-size: 0.8rem;
    text-align: center;
    padding: 0 10vw;
    margin-top: 3rem;
  }
}

.mailer-header {
  text-align: center;
}

.mailer-footer {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: $light-gray;
  height: 5rem;
  text-align: center;
  color: $middle-gray;

  .footer-links {
    justify-content: center;
    display: flex;
  }

  a {
    margin: 0 1rem;
    color: $middle-gray;
    font-weight: normal;
  }

  .admin-info {
    font-size: 0.8rem;

  }
}
