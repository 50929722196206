.price, .price-range, .price-table {
  &.price-tny {
    font-size: 0.2em;
  }
  &.price-vsml {
    font-size: 0.4em;
  }
  &.price-sml {
    font-size: 0.6em;
  }
  &.price-med {
    font-size: 1.5em;
  }
  &.price-lrg {
    font-size: 2em;
  }
  &.price-vlrg {
    font-size: 2.5em;
  }
  &.price-big {
    font-size: 4em;
  }
  &.price-vbig {
    font-size: 6em;
  }
  &.price-hug {
    font-size: 10em;
  }
  &.price-vhug {
    font-size: 16em;
  }
}

.price {
  margin: 0;

  display: flex;
  flex-direction: row;
  align-items: baseline;

  font-size: 1em;

  &::after {
    margin-left: 0;

    position: relative;
    left: 0.05em;

    font-size: 0.7em;
  }

  &.euro {
    &::after {
      content: "€";
    } 
  }

  &.dollar {
    &::after {
      content: "$";
    } 
  }

  &.pound {
    &::after {
      content: "£";
    } 
  }

  &.price-canceled {
    color: $middle_gray;
    text-decoration: line-through;
    text-decoration-thickness: 0.1em;
  }
}

.price-range {
  display: flex;
  flex-direction: row;

  color: $apricot;

  &.range-col{
    flex-direction: column;
  }

  &.range-stairs {
    flex-direction: column;

    .price-from {
      align-self: flex-start;
      text-align: left;
    }

    .operand {
      align-self: center;
      text-align: center;
    }

    .price-to {
      align-self: flex-end;
      text-align: right;
    }
  }

  .price-from {
    font-size: 0.9em;
  }

  .operand {
    font-size: 0.5em;
    color: $middle_gray;
  }

  .price-to {
    color: $dark-apricot;
  }
}

.price-with-rebate {
  display: flex;
  flex-direction: column;

  .rebate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-style: italic;
    color: $grass;

    .percentage {
      margin: 0;

      font-size: 1.7em;

      &::before, &::after {
        color: $dark_grass;
      }

      &::before {
        margin-right: -0.4em;

        content: "-";
        font-size: 0.8em;
      }

      &::after {
        margin-left: -0.7em;

        position: relative;
        bottom: -0.2em;

        content: "%";
        font-size: 0.5em;
        font-weight: 900;
      }
    }
  }

  .price-main {
    margin-top: 0.2em;

    font-weight: bold;
    align-self: flex-end;

    color: $dark-apricot;
  }

  &.without-rebate {
    .price-main {
      align-self: center;
    }
  }
}

.price-detail {
  color: $middle_gray;
  font-style: italic;

  .price-detail-section {
    .price-detail-header, .price-detail-elt{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .price-detail-header {
      color: $gray_850;

      .price-detail-tag, .price {
        font-size: 1em;
      }
    }

    .price-detail-sub-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .price-detail-elt {
        width: 80%;

        .price-detail-tag, .price {
          font-size: 0.8em;
        }
      }
    }
  }
}

.price-table {
  tbody {
    tr {
      td {
        .price {
          margin: 0.5em;

          justify-content: flex-end;
          text-align: right;
        }
      }
    }
  }

  @media(max-width: 642px) {
    td {
      .price {
        margin: 0.2em !important;
        
        letter-spacing: normal !important;
      }
    }
  }
}
