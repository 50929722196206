.three-column-testimonies {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: $web-margin-large 0;
  min-height: 60rem;

  img {
    text-align: center;
  }

  .item {
    margin: 0 1rem;
    text-align: center;
    align-self: flex-start;
    width: 18rem;
    min-height: inherit;
  }

  .item h1 {
    margin: 2rem 0;
  }

  p {
    text-align: justify;
  }
}

.argument-edito-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: $web-margin-medium;

  .item {
    margin: 0 1rem;
    text-align: center;
    align-self: flex-start;
    width: 30rem;
  }
}

.edito-link-image-video {
  width: 300px;
}
