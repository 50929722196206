.webfooter-container {
  padding: 1vw 5vw;
  background-color: $light_apricot;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);

  .footer-stores {
    height: 15vh;
    grid-column: 1/2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .footer-stores .app-stores {
    justify-content: start;
  }

  .footer-medias {
    grid-column: 2/3;
    display: flex;
    justify-content: center;
    margin: 1.5rem 0;
  }

  .footer-medias i {
    color: $dark_apricot;
    font-size: 1.5rem;
    margin: 0 $margin-small;
  }

  .footer-legals {
    grid-column: 3/4;
  }

  .footer-legals h3 {
    margin: $margin-medium 0;
    text-align: right;
  }

  .footer-legals p {
    margin: $margin-medium 0;
    text-align: right;
  }

  .web-logo {
    width: 10rem;
  }
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

/* Animation wave */

.parallax-wave > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax-wave > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax-wave > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax-wave > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax-wave > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  .webfooter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .footer-legals {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
