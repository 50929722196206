.comp-values,
.comp-vision,
.comp-team {
  padding-top: $menu-height-big;
}

.comp-values,
.comp-vision,
.comp-team {
  margin-bottom: $web-margin-large;
}

.comp-values,
.comp-vision {
  display: flex;
  flex-direction: column;
  align-items: center;
}
