.title-intro-demand-offer {
  color: $black;
  margin-top: 25px;
}

.create-offer {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 20px;

  p {
    width: 720px;
    color: $grass;
    font-size: 14px;
    margin-right: 10px;
  }

  .btn-grassy {
    margin-left: 10px;
  }
}

.pro-intro-demand-offer {
  display: flex;
  flex-wrap: wrap;

  p {
    width: 200px;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 0px;
    font-size: 14px;

    span {
      height: 27px;
      width: 30px;
      margin: auto;
      background-color: $grass;
      padding-top: 3px;
      border-radius: 50%;
      color: white;
      margin-bottom: 10px;
      margin-top: 0px;
    }
  }
}
