.book-page-wrapper {
  width: fit-content;
  margin: 0 auto;

  &.watermark {
    .A4::after {
      content: "~ * beomai * ~";
      position: absolute;
      top: 109mm;
      left: -11mm;
      font-size: 8rem;
      color: gray;
      opacity: 0.3;
      transform: rotate(-45deg);
      width: 110%;
      z-index: 10;
    }
  }

  .A4 {
    height: 297mm;
    width: 210mm;
    padding: 0;

    position: relative;

    background-color: $white;

    .full-page {
      height: 297mm;
      width: 210mm;

      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.overlay, .overlay-illustration {
  z-index: 1;
}

.book-deletion-button {
  width: fit-content;
  margin: 0 auto;
  padding: 2vh;

  cursor: pointer;

  p {
    width: fit-content;
    margin: 0;
  }
}

.book-page-wrapper {
  &.bleed-3 {
    .A4 {
      padding: 3mm;
    }

    .full-page {
      height: 303mm;
      width: 216mm;
    }
  }
}

.book-pdf-cover {
  width: fit-content;
  position: relative;

  .cover-side-wrapper {
    position: absolute;

    .book-page-wrapper,
    .book-page-wrapper .A4,
    .book-page-wrapper .A4 .full-page {
      height: 303mm;
      width: 213mm;
    }
  }

  .book-slice {
    box-sizing: border-box;
    height: 303mm;
  }
}
