// Graphical variables
@import "config/variables/_index";

// // External libraries
@import "swiper/swiper";
@import "dragula/dist/dragula";
@import "simple_calendar";
@import "mapbox-gl";
@import url("https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.css");
// @import "flatpickr/dist/flatpickr";

// // Your CSS partials
@import "book_page_templates/_index";
@import "book_skins/_index";
@import "components/index";
@import "pages/_index";
@import "showcase-site/index";
@import "workspace_pro/index";
@import "pools/commun";
@import "offers/commun";

// Your utility classes
@import "utilities/decorator";
@import "utilities/position";
@import "utilities/display";
@import "utilities/flex";
@import "utilities/text";
@import "utilities/typography";
@import "utilities/simple_calendar_custom.scss";
@import "utilities/tooltip";

body {
  font-family: $body-font;
  padding: 0;
  margin: 0;
  color: $dark-gray;
  line-height: $line-height;

  &::-webkit-scrollbar {
    display: none;
  }
}

.background-orange {
  background-color: $light_apricot;
}

.page-wrapper {
  width: $page-container-width;
  padding: $page-container-padding;
  position: relative;

  .unpadded-page-full-size {
    margin: $page-container-negative-padding;
  }
}

.desktop-page-wrapper {
  padding: 0 12vw;
  height: initial;
  min-height: $page-container-height;

  .unpadded-page-full-size {
    min-height: $desktop_page-container-height_no-padding;
  }
}

.native-page-wrapper {
  display: flex;
  flex-direction: column;
}

.mobile-web-page-wrapper {
  margin-bottom: 50px;

  &::-webkit-scrollbar {
    display: none;
  }

  .unpadded-page-full-size {
    height: $mobile_page-container-height_no-padding;
  }
}

// iOS Mandatory to avoid zoom-in on input
input:not([type="submit"]),
input,
select,
textarea {
  font-size: 16px;
}
