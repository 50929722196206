.journal-description {
  h2 {
    margin-bottom: 0;
  }

  .cards-container {
    .card {
      margin: 1rem;
    }
  }

  .table {
    margin-top: 1rem !important;

    th, td {
      * {
        width: 5em;
      }
    }


  }
}
