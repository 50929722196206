.fixed-button-container {
  position: fixed;
  top: 190px;
  right: 0px;
  z-index: 1000;
  width: 70px;
  height: 70px;

  .btn-static {
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    border: 2px solid $white;
    border-right: none;
    display: flex;
    background-color: $grass;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }

  .icone-btn-pool {
    width: 36px;
    margin-left: 5px;
  }
}

@media (max-width: 600px){
  .fixed-button-container {
    top: 160px;
    width: 50px;
    height: 50px;

    .icone-btn-pool {
      width: 26px;
    }
  }
}

