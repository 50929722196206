.tabs-team-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: auto;
    width: 100%;
  }

  .tabs label {
    text-align: center;
    font-family: "Quicksand", "Helvetica", "sans-serif" !important;
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    cursor: pointer;
    font-weight: bold;
    transition: all ease 0.3s;
    background-color: $light-grass;
    &:hover {
      background: $light-grass;
      color: $grass;
    }
  }

  .tabs .tab {
    order: 9;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    min-height: 30rem;
    display: none;
    padding: 1rem;
    background: $white;
    padding: 1.25rem;
  }

  .tabs .tab .content-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    .item-content-tab p {
      text-align: justify;
    }

    .item-content-tab h3 {
      text-align: left;
    }

    .item-content-tab:last-child {
      align-self: flex-start;
    }

    .multiple-pic-content-tab {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .multiple-pic-content-tab a {
      font-weight: normal;
    }
  }

  .tabs input[type="radio"] {
    display: none;
  }

  .tabs input[type="radio"]:checked + label {
    border-bottom: 0.3rem solid $grass;
    color: $grass;
  }

  .tabs input[type="radio"]:checked + label + .tab {
    display: block;
  }
}

@media (max-width: 465px) {
  .tabs .tab,
  .tabs label {
    order: initial;
  }

  .tabs label {
    width: 100%;
  }
}
