.desktop-navbar {
  position: sticky;
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid $border-small $light_gray;
  background-color: $white;
  height: 3.5rem;

  .desktop-navigation {
    display: flex;
    align-items: center;
    padding: 0 0.25rem;
    white-space: nowrap;

    a, form {
      margin-right: 0.5rem;
    }
  }
}
