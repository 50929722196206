.edit-pool {
  .pool-container {
    width: 95%;
    margin: auto;
    display: flex;
    margin-top: 20px;
  }

  .pool-contributions {
    flex-direction: column;
    width: 50%;
  }

  .link-all-offer {
    img {
      width: 20px;
      margin-left: 10px;
    }
  }

  .offer-contributor {
    display: flex;
    margin: auto;
    width: 95%;
    margin-top: 50px;

    .offers-favorites {
      width: 50%;
      margin-right: 15px;
    }

    .contributors {
      width: 50%;
      margin-left: 15px;
    }

    .cards-contributors {
      margin-top: 36px;
    }
  }

  .header-pool {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    position: relative;

    .title-pool {
      display: flex;

      h1 {
        margin-left: 10px;
      }
    }
  }

  .link-blue {
    display: flex;
    border-radius: 12px;
    background-color: $blue-25;
    color: $blue-500;
    font-size: 13px;
    height: 32px;
    align-items: center;
    padding-right: 8px;
    padding-left: 8px;
    margin-top: -6px;

    .icone-link {
      height: 18px;
      margin-right: 5px;
    }
  }

  .text-blue {
    color: $grass;
    margin-top: 0px;
    text-align: left;
  }

  .title-offers {
    display: flex;
    margin-bottom: 10px;

    .icone-event-collective {
      height: 27px;
      margin-right: 10px;
      margin-top: -7px;
    }
  }
}

@media (max-width: 720px) {
  .edit-pool {
    h2 {
      font-size: 18px
    }

    h1 {
      font-size: 20px;
    }

    .link-blue {
      p {
        font-size: 13px;
      }
    }

    .pool-contributions {
      width: 100%;
    }

    .offer-contributor {
      flex-direction: column;

      .offers-favorites {
        width: 100%;
      }

      .contributors {
        width: 100%;
        margin: auto;
        margin-top: 30px;
      }
    }
  }
}

@media (max-width: 450px) {
  .edit-pool {
    h1 {
      width: 135px;
    }
  }
}
