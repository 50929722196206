// BASE //
$peach_900:     #642318;
$peach_700:     #ec663a;
$peach_510:     #f7806c;
$peach:         #fc8873;
$red:           #EF4040;


$baby_blue: #89CFF0;
$dark_apricot:  #f88545;
$medium_apricot:#F9966D;
$apricot_510:   #f9af65;
$apricot:       #feb372;
$apricot_200:   #ffddca;
$light_apricot: #ffeedf;
$apricot_300:   #FACE92;

$sun_510:       #fcd995;
$sun:           #fadb9e;

$grass_900:     #163662;
$dark_grass:    #22477c;
$grass_650:     #769bd0;
$grass:         #39639f;
$light_grass:   #a0b7d8;
$olive: #e3edf5;
$blue-500: #65A3F9;
$blue-cobalt: #2D59C0;
$blue-50: #FAFCFF;
$blue-25: #EEF5FF;
$blue-100: #E8F1FF;
$blue-200: #B2D1FC;
$blue-20: #65A3F980;
$color-light-blue: #E0EDFF;

$green-50: #E1FCE5;
$green-200: #399F60;



$dark_gray:     #413f3e;
$gray_850:      #5a5a5a;
$middle_top_grey: #7E7E7E;
$middle_gray:   #bfbdbc;
$dim_grey: #6A6A6A;
$grey_tooltip: #C1C5CE;
$brick_red: #CA5858;
$palepink: #FCE1E1;
$crimson-red: #CB191E;
$gray_300:      #ddd;
$light_gray:    #ededed;
$gray_middle:   #22477c;

$black: #000;
$white: #fff;

// ORPHANS //
$slightly-transparent_apricot: #c9874afd;

// 50% TRANSPARENT //
$light_apricot-transparency-50: rgba($light_apricot, 0.5);

$sun-transparency-50: rgba($sun, 0.5);

$light_grass-transparency-50: rgba($light_grass, 0.5);

// 20% TRANSPARENT //
$peach-transparency-20: rgba($peach, 0.2);

$dark_apricot-transparency-20: rgba($dark_apricot, 0.2);
$apricot-transparency-20: rgba($apricot, 0.2);

$dark_grass-transparency-20: rgba($dark_grass, 0.2);
$grass-transparency-20: rgba($grass, 0.2);
