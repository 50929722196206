#book_global_authorizations_wrapper {
  h2 {
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: 0.1rem;

    &.wrong {
      color: $peach_900;
    }

    &.right {
      color: $grass_900;
    }
  }
}

.book-authorizations {
  &.horizontal-users-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  form.book_global_authorizations{
    width: auto;
  }

  .avatar-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // width: min-content;

    cursor: pointer;

    .avatar {
      background-position: center center;
      background-size: 100%;
      filter: grayscale(0);

      border: none;
      padding: 3px;

      transition: filter 0.3s;
    }

    .avatar-photo-wrapper {
      position: relative;

      .avatar-ornament {
        $ornament-size: 1rem;

        height: $ornament-size;
        width: $ornament-size;
        padding: 1px;

        border-radius: 50%;

        position: absolute;
        top: 0;
        right: 8%;

        background-color: $dark_gray;

        .fa, .fas {
          display: block;
          height: $ornament-size;
          width: $ornament-size;

          font-size: $ornament-size;
          line-height: $ornament-size;

          padding: 0;
        }
      }
    }

    .user-full-name {
      text-align: center;
      font-weight: bold;
    }

    &:not(.authorized) {
      .avatar {
        filter: grayscale(1);
        background-size: 0 0;

        &:hover {
          background-size: 100%;
          filter: grayscale(0);

          transition: filter 0.1s;
        }
      }
    }

    &.authorized {
      .avatar {
        filter: grayscale(0);

        &:hover {
          background-size: 0;
          filter: grayscale(1);

          transition: filter 0.1s;
        }
      }
    }
  }
}
