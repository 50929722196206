.flip-cards-wrapper.blurry {
  filter: blur(0.25rem);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.flip-cards-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  .flip-card {
    width: 20rem;
    height: 20rem;
    margin: 1rem;
    perspective: 85rem;

    .content {
      height: 100%;
      transform-style: preserve-3d;
      transition: transform 0.45s cubic-bezier(0.75, 0, 0.85, 1);
    }

    p {
      color: $white;
    }
  }

  .more {
    display: none;

    &:checked ~ .content {
      transform: rotateY(180deg);
    }
  }

  .front,
  .back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // uncomment to hide backface
    // backface-visibility: hidden;
    transform-style: preserve-3d;
    border-radius: $component-radius;

    .inner {
      height: 17rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transform: translateZ(80px) scale(0.94);
      padding: 1.5rem;
    }
  }

  .front {
    background-size: cover;

    h2 {
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      color: $white;
      line-height: 1.5rem;
      text-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
    }

    p {
      text-align: center;
      line-height: 1.5rem;
      margin: 0;
    }
  }

  .back {
    transform: rotateY(180deg);
    background-color: rgba($apricot, 0.5);
    border: 2px solid $white;

    p {
      text-align: justify;
    }

    .description {
      border-radius: $component-radius;
      font-weight: 600;
      line-height: 1.4rem;
      overflow: auto;
      color: $white;
      padding-right: 1rem;
    }
  }

  .back.back-grass {
    background-color: rgba($grass, 0.5);
  }

  .flip-card-btn {
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    height: 3rem;
    width: 8rem;
    line-height: 3rem;
    margin: 0 auto;
    margin-top: 1rem;
    border: solid 2px $white;
    color: $white;
    border-radius: $component-radius;
    text-align: center;
    transition: 0.1s ease-in-out;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);

    &:hover {
      box-shadow: 0 0 10rem rgba(0, 0, 0, 0.1);
      text-shadow: none;
      color: $white;
    }

    &.return {
      width: 5rem;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: $slightly-transparent_apricot;
    border-radius: $component-radius;
  }
  ::-webkit-scrollbar-thumb {
    background: darken($white, 5%);
    border-radius: $component-radius;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: $white;
  }
}
