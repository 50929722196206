.close-modal {
  background-color: $white;
  border: none;
  margin-top: 0px;
  font-size: 20px;
  position: absolute;
  right: 7%;
  padding: 5px;
}

.btn-offer-demand {
  display: flex;
  width: 162px;
}

.modal-user {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  justify-content: center;
  align-items: center;
}

.modal-content-user {
  width: 90%;
  max-height: 90%;
  padding-bottom: 30px;
  background-color: $white;
  overflow: auto;
  border-radius: 10px;
}

.modal-header-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-modal {
  cursor: pointer;
}

.modal-body-user {
  margin-top: 10px;
}

.card-professional {
  width: 90%;
  border: solid 1px $middle_gray;
  border-radius: 15px;
  margin-top: 20px;


  .btn-more {
    color: $white;
    border-radius: 100rem;
    padding: 0.4rem 1rem;
    margin-bottom: 20px;
    background-image: linear-gradient(to right, #fadb9e 0%, #feb372 20%, #fd9f72 55%, #fc8873 100%);
    border: 0.5px solid rgba(250, 219, 158, 0.5);
  }

  .availability-btn {
    margin-top:20px;
  }

  .description-pro {
    line-height: 19px;
    margin-top: 22px;
  }

  .two-next-dates {
    color: $white;
    background-color: $grass;
    font-size: 12px;
    margin: 0px;
    height: 26px;
    border-radius: 15px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 5px;
    margin-right: 3%;
    margin-top: 5px;
  }

  img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin: 10px;
  }

  .pro-name {
    color: $grass;
    font-weight: 700;
    margin-bottom: 0px;
  }

  .user-info {
    margin-left: 10%;
    width: 100%;

    .pro-job {
      flex-wrap: wrap;

      p {
        background-color: $sun-transparency-50;
        padding: 2%;
        border-radius: 15px;
        padding-right: 6%;
        font-weight: bold;
        margin-top: 2%;
        margin-right: 6%;
        margin-left:-1%;
        margin-bottom: 2%;
        padding-left: 6%;
      }
    }

    .location-img {
      margin-left: -10px;

      p {
        margin-top: 11px;
      }

      img {
        border-radius: 0px;
        height: 18px;
        width:16px;
      }
    }
  }

}
