.img-pro-menu {
  margin-left: 18%;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;           // couvre toute la fenêtre
  background: rgba(0, 0, 0, 0.5); // gris semi-transparent
  z-index: 9998;           // un peu moins que le menu
}

.burger-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 40vw;
  height: 100vh;
  background: white;
  z-index: 9999;
  overflow-y: scroll;

  .img-partner {
    margin-top: 7px;
  }

  img.avatar-img-topbar {
    margin-top: 7px;
  }
}

.top-menu {
  display: flex;
  position: absolute;
  justify-content: space-around;
  border-radius: 16px;
  left: 9%;
  width: 80%;
  padding: 2%;
  border: 1px solid rgba($blue-500, 0.5);

  .link-babies {
    margin-top: 1px;

    p {
      margin-top: 3px;
    }
  }
}

.top-menu-all-link {
  top: 10%;
}

.top-menu-pool {
  top: 28%;
  background-color: $light_apricot;

  .pool-total {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.text-first-user {
  color: $medium_apricot;
}

.text-second-user {
  color: $blue-500;
}

.text-link-top-menu {
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.link-pool {
  margin-top: 4px;

  img {
    margin-left: 10%;
  }
}

.link-pregnancy {
  margin-top: 4px;

  img {
    margin-left: 15%;
  }

}

.burger-menu-profil {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  top: 20px;

  .link-profil {
    position: sticky;
    z-index: 900;
    margin-top: 7px;
  }

  .burger-menu-img {
    align-items: center;
  }
}

.topbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  border-bottom: $border-extra-small solid $light_gray;
  position: sticky;
  top: 0;
  width: 100%;
  height: $navbar-height;
  background: $white;
  z-index: 999;

  .topbar-logo-navbar {
    width: 14%;
    margin-left: 29%;
    position: absolute;
    left: 18%;
  }

  .topbar-logo-navbar-pro {
    margin-left: 22%;
    position: absolute;
    left: 17%;
  }

  .burger-menu-img {
    border-radius: 50%;
    border-color: $black;
  }

  .close-menu {
    margin-top: 10px;
    width: 28px;
  }

  .avatar-default {
    margin-left: -12px;
  }
}

@media (max-width: 850px) {
  .burger-menu {
    width: 50%;
    left: 50%;
  }
}

@media (max-width: 650px) {
  .burger-menu {
    width: 75%;
    left: 25%;
  }
}

@media (max-width: 450px) {
  .burger-menu {
    width: 100%;
    left: 0;
  }
}
