.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;

    background-color: $black;
    opacity: 0.8;
  }

  .modal {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    position: fixed;
    z-index: 1;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 0.9rem; // you can scale it by modifying this rule
  
    width: 20em;
    height: 18em;
    background-color: $white;
    border-radius: 1em;

    padding: 1em 1.5em 1em;

    &.modal-huge {
      height: 80vh;
      width: 80vw;

      top: 50%;
    }

    .modal-content-wrapper {
      height: 100%;
      width: 100%;

      padding-bottom: 2rem;
    }

    &.modal-scrollable {
      overflow-y: hidden;
      overflow-x: visible;

      .modal-content-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .modal-text {
      height: 80%;
      width: 100%;

      h1 {
        font-size: 2em;
        line-height: 1em;

        margin-top: 0;
        margin-bottom: 0.5em;
        letter-spacing: 0.1em;
      }

      .modal-message {  
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
          margin: 0.5rem 0;
          text-align: justify;

          &:nth-child(1) {
            margin-top: 0;
          }
  
          &.main-content {
            font-weight: bold;
            font-size: 1.5em;
            text-align: left;
            letter-spacing: 0.1em;
            line-height: 1.5em;
          }
        }
      }
    }

    .modal-buttons-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: end;

      height: 20%;
      width: 100%;

      .btn {
        display: inline;

        background: none;
        border: none;

        margin: 0 0 0 1rem;

        vertical-align: baseline;

        cursor: pointer;
        line-height: 0;

        &.cancel-action {
          height: 100%;
          padding: 1rem 2rem;
          margin-left: 0;

          font-size: 1.2em;
          color: $gray_850;
        }

        &.main-action {
          height: 100%;
          padding: 1rem 2rem;

          border-radius: 0.5rem;

          font-size: 1.5em;
          color: $white;
        }
      }
    }

    &.right-action {
      h1 {
        color: $grass;
      }
      .modal-message {
        color: $grass_900;
      }
      .modal-buttons-wrapper {
        .main-action {
          background-color: $grass;
        }
      }
    }

    &.wrong-action {
      h1 {
        color: $peach;
      }
      .modal-message {
        color: $peach_900;
      }
      .modal-buttons-wrapper {
        .main-action {
          background-color: $peach;
        }
      }
    }
  }

  &:not(.active) {
    display: none;
  }
}
