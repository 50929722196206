// Specific CSS for your home-page
.home_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .home-grid {
    width: 100%;
  }

  .search-event-form {
    width: 100%;
    background-color: $blue-50;
    border: 1px solid $blue-20;
    border-radius: 7px;
    display: flex;
    height: 45px;

    img {
      height: 16px;
      margin: auto;
      margin-left: 20px;
      margin-right: 10px;
    }

    input::placeholder {
      color: $blue-20;
    }
  }

  .title-offers {
    margin-top: 25px;
    display: flex;
    margin-bottom: 10px;
  }

  .events-home {
    display: flex;
    justify-content: space-around;

    .text-offer {
      font-size: 11px;
    }

    .title-offer {
      font-size: 24px;
    }

    .h3-title-offer {
      font-size: 13px;
    }
  }

  .div-link-offers {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .search-event-input {
    width: 100%;
    background-color: $blue-50;
    border-radius: 7px;
    color: $blue-20;
    border: none;
  }

  .block-home {
    margin-bottom: 30px;
  }

  .block-name {
    color: $blue-200;
    margin: auto;
    margin-top: 10px;
    text-align: center;
  }

  .link-blue {
    display: flex;
    border-radius: 12px;
    background-color: $blue-25;
    color: $blue-500;
    font-size: 13px;
    height: 32px;
    align-items: center;
    padding-right: 8px;
    padding-left: 8px;
    margin-top: -6px;

    .icone-link {
      height: 16px;
      margin-right: 5px;
    }
  }

  .tags-article {
    display: flex;

    .tag-article {
      background-color: $blue-100;
      border-radius: 7px;
      margin-left: 0px;
      margin-right: 10px;
    }
  }

  .tags-article.tags-mobile {
    display: none;
  }

  .icone-event-collective {
    height: 27px;
    margin-right: 10px;
    margin-top: -7px;
  }

  .collective-event {
    width: 49%;
  }

  #search-section {
    margin-top: 20px;
  }

  .my-readings {
    width: 95%;
    margin-left: auto;
    margin-right: auto;

    .block-reading {
      display: flex;

      .btn-reading {
        width: 160px;
        margin: auto;
        margin-left: 30px;
      }
    }

    .article-infos {
      display: flex;
      width: 75%;

      img {
        height: 105px;
        border-radius: 8px;
        margin-right: 15px;
      }

      .h3-title-reading {
        border-bottom: 1px solid $blue-20;
        padding-bottom: 15px;
        margin-bottom: 10px;
      }

      .date-article {
        color: $blue-500;
      }
    }

    .div-link-reading {
      width: 50%;
      display: flex;
      justify-content: flex-end;
    }

    .title-my-readings {
      margin-top: 25px;
      display: flex;
      margin-bottom: 10px;

      h2 {
        width: 50%;
      }

      .icone-read {
        height: 32px;
        margin-right: 10px;
        margin-top: -7px;
      }
    }
  }

  .appointment-pool {
    background-color: $white;
    border-radius: 24px;
    text-align: left;
    padding-bottom: 20px;

    .text-blue {
      text-align: left;
    }

    .title-appointment {
      margin-top: 25px;
      display: flex;

      img {
        margin-right: 10px;
        margin-top: -2px;
      }
    }

    .title-pool {
      margin-top: 25px;
      display: flex;

      img {
        margin-right: 10px;
        margin-top: -7px;
      }
    }

    .appointment {
      width: 45%;
      margin-left: auto;
      margin-right: auto;
    }

    .pool {
      width: 45%;
      margin-left: auto;
      margin-right: auto;
    }

    .appointment-pool-block {
      .h3-title {
        margin-bottom: 5px;
      }
    }

    .link-dashboard {
      font-size: 13px;
      color: $blue-500;
    }

    .appointment-infos {
      display: flex;

      img {
        height: 95px;
        border-radius: 8px;
        margin-right: 15px;
      }

      .appointment-date {
        border-top: 1px solid $blue-500;
        padding-top: 5px;
        margin-top: 5px;
      }
    }

    p {
      font-size: 13px;
    }

    .icone-booking {
      width: 26px;
      height: 26px;
    }

    .icone-pool {
      width: 32px;
      height: 32px;
    }

    .icone-btn {
      width: 18px;
      margin-right: 5px;
    }

    .icone-none {
      display: none;
    }
  }

  .participants-name {
    color: $medium_apricot;
    margin-top: 20px;
    margin-bottom: 8px;
  }

  .text-blue {
    color: $grass;
    margin-top: 0px;
  }

  .appointment-pool {
    display: flex;
  }

  .timeline-pic {
    width: 100%;
    margin-top: 30px;
  }

  .bar-pointer-timeline {
    width: 100%;
    height: 15px;
    margin-bottom: 5px;
    position: relative;
    .pointer-timeline {
      position: absolute;
      height: 22px;
      height: 22px;
      background-color: #f88545;
      width: 22px;
      border: 3px solid $apricot;
      border-radius: 50%;
    }
  }

  .hidden-message {
    display: none;
  }

  .home-title {
    grid-row: 1 / 2;
    grid-column: 1;
  }

  .preview-article {
    grid-column: 1;
  }

  .btn-event-mobile {
    margin-bottom: 30px;
    display: none;
  }

  .btn-mobile {
    border: 1px solid $blue-200;
    border-radius: 16px;
    color: $blue-500;
    background-color: $white;
    height: 50px;
    display: flex;
    justify-content: space-around;
    font-weight: 650;
    width: 30%;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;

    img {
      margin-right: 10px;
    }

    .img-pro {
      width: 28px;
      height: 16px;
      margin-top: 17px;
    }

    .img-collectif {
      width: 28px;
      height: 22px;
      margin-top: 13px;
    }

    .img-individual {
      margin-top: 13px;
      width: 24px;
      height: 24px;
    }

    p {
      margin: auto;
      font-size: 13px;
      text-align: center;
    }
  }
}

@media (max-width: 450px) {
  .div-link-reading {
    .link-blue {
      text-align: center;
      line-height: 12px;
    }
  }

  .home_container {
    .btn-mobile {
      width: auto;
      padding-left: 4px;
      padding-right: 4px;
      margin-right: 3px;
    }

    .my-readings {
      .article-infos {
        .img-article{
          width: 50%;
          height: auto;
        }
      }

      .title-mobile-read {
        width: 50%;
      }
    }
  }
}

@media (max-width: 550px) {
  .home_container {
    .appointment-pool {
      flex-direction: column;

      .appointment {
        width: 90%;
      }

      .pool {
        width: 90%;
      }

      .tags-article.tags-mobile {
        margin-top: 10px;
        display: flex;
      }

      .tags-article.tags-desktop {
        display: none;
      }

      .appointment-pool-block {
        display: none;
      }

      .block-reading {
        display: block !important;
      }

      .text-desktop {
        display: none;
      }

      .btn-dashboard {
        margin-top: 0px;
      }

      .btn-blue {
        border: 1px solid $blue-500;
        color: $blue-500;
        background-color: $white;
      }

      .btn-reading {
        color: $white;
        background-color: $grass;
        border: 1px solid $grass;
        margin: auto !important;
      }

      .article-infos {
        width: 100%;
      }

      .icone-none {
        display: block;
      }
    }
  }
}

@media (max-width: 850px){
  #block-event-desktop {
    display: none;
  }

  #btn-event-mobile {
    display: flex;
  }
}

@media (min-width: 700px){
  .cartridge-container {
    .dates-pregnancies-home {
      width: 70% !important;
      margin-top: 35px !important;
    }
  }
}
