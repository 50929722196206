$avatar-extra-big-size:   104px;
$avatar-big-size:         56px;
$avatar-default-size:     32px;
$avatar-small-size:       24px;

$avatar-small-no-outline-scale: 0.9;
$avatar-small-no-outline-size: #{$avatar-default-size * $avatar-small-no-outline-scale};

.avatar-container {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 2vh;
  justify-content: center;
}

// Avatar Offer
.photo-cercle-offer {
  width: 92px;
  height: 92px;
  border: 1px solid $grass;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  background-size: cover;
  background-position: center;
}

.photo-cercle-offer-default {
  width: 92px;
  border-radius: 50%;
  height: 92px;
  border: 1px solid $grass;
  margin-right: 20px;
}

// Users-avatars
.user-avatar {
  z-index: 100;

  .text-link-top-menu {
    margin-top: 3px;
  }
}

.user-avatar-pro {
  display: flex;
  justify-content: space-between;
  width: 90vw;
}

.other1-avatar {
  z-index: 90;
}

.other2-avatar {
  z-index: 80;
}

.other3-avatar {
  z-index: 70;
}

.avatar {
  border-radius: 50%;
}

.avatar-rectangle {
  border-radius: 10%;
}

// Avatar sizes
.avatar-extra-big {
  height: $avatar-extra-big-size;
  width: $avatar-extra-big-size;
  min-height: $avatar-extra-big-size;
  margin: 0 auto;
}

.avatar-big {
  height: $avatar-big-size;
  width: $avatar-big-size;
}

.avatar-default {
  height: $avatar-default-size;
  width: $avatar-default-size;
}

.avatar-small {
  height: $avatar-small-size;
  width: $avatar-small-size;
}

.avatar-small-no-outline {
  height: $avatar-small-no-outline-size;
  width: $avatar-small-no-outline-size;
}

// Avatar image canevas
.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

img.avatar-img {
  height: 100%;
  border-radius: 50%;
}

img.avatar-img-topbar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: 25%;
}
