.comp-team {
  h3 {
    color: $apricot;
    margin: $margin-medium 0;
  }

  p {
    margin: $web-margin-large 0;
  }

  .team-funder {
    margin-top: $web-margin-large;
  }

  .team-no-funder {
    display: flex;
    justify-content: center;
    margin: $margin-large 0;

    .team-doula,
    .team-dev {
      margin: 0 $margin-large;
    }

    .avatar-lg {
      margin: 0;
    }

    .team-doula {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      h3 {
        text-align: end;
      }
    }

    .team-dev {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h3 {
        text-align: start;
      }
    }
  }
}
