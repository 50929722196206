@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Style+Script&display=swap");

.boheme-skin {
  font-family: "Roboto Condensed";
  font-size: 12pt;
  font-weight: 300;
  color: #212424;

  .cover, .parents, .babies, .letter {
    .background-illustration {
      z-index: 1;
    }
  }

  .cover {
    .title, .author, .subtitle, .date {
      color: #212424;
    }

    .title {
      justify-content: left;
      font-family: "Style Script";
      font-size: 44pt;
    }

    .author {
      font-family: "Roboto Condensed";
      font-weight: 300;
      font-size: 18pt;
    }

    .subtitle {
      font-family: "Amatic SC";
      font-weight: 700;
      font-size: 28pt;
    }

    .date {
      font-family: "Amatic SC";
      font-weight: 700;
      font-size: 24pt;
      color: #f6eee7;

      span {
        position: relative;
        top: -1mm;
        left: -1.2mm;
      }
    }

    &.photo-variant {
      .title, .author, .subtitle {
        left: 91mm;
      }

      .title {
        top: 213mm;
      }

      .subtitle {
        top: 231mm;
      }

      .author {
        top: 240.5mm;
      }
    }

    &.text-variant {
      .title, .subtitle, .author, .date {
        left: 50%;
        transform: translateX(-50%);

        justify-content: center;
      }

      .title, .subtitle, .author {
        width: 80mm;
      }

      .date {
        top: 10.5mm;
      }
    }

    &.cover_fourth {
      &.photo-variant {
        .photo {
          height: 95mm;
          width: 130mm;

          top: 56mm;
          left: 42.1mm;
        }
      }
    }
  }

  .babies {
    .title, .name, .date, .weight, .height, .message {
      z-index: 2;
    }

    .title, .name {
      font-family: "Style Script";
      color: #f6eee7;
    }

    .title {
      font-size: 53pt;
    }

    .name {
      top: 160mm;

      font-size: 35pt;
    }

    .date, .weight, .height, .message {
      font-size: 22pt;
      font-family: "Amatic SC";
      font-weight: 700;
    }

    .baby-photo {
      height: 88mm;
    }
  }

  .summary {
    font-family: "Style Script";

    .summary-chapter .number, .summary-section {
      font-family: "Amatic SC";
      font-weight: 700;
    }

    .title {
      font-size: 96pt;
    }

    .summary-chapter {
      .name {
        font-size: 25pt;
        line-height: 1.75;
      }

      .number {
        font-size: 44pt;
        line-height: 1;
      }

      .page {
        font-size: 20pt;
        line-height: 1.75;
      }
    }

    .summary-section {
      font-size: 17pt;

      .page {
        font-family: "Style Script";
        font-weight: 400;
        font-size: 20pt;
      }
    }
  }

  .header {
    height: initial;

    font-family: "Amatic SC";
    font-weight: 700;
    font-size: 18pt;
    line-height: 1.1;
    color: #212424;

    span {
      padding: 0 5mm;
      border-bottom-style: dashed;
    }
  }

  .footer {
    .logo-footer {
      path {
        fill:#22477c;
      }
    }

    .page-number {
      height: 11mm;
      width: 11mm;
      border-radius: 50%;

      font-family: "Style Script";
      font-size: 16pt;

      color: #f6eee7;
      background-color: #212424;

      &.page-number-left {
        left: 10mm;
      }

      &.page-number-right {
        right: 10mm;
      }
    }
  }

  .quotes {
    .quote-open, .quote-close {
      font-family: "StyleScript";
      font-size: 20pt;
      color: #212424;
    }

    .quote-text {
      font-family: "Style Script";
      font-size: 20pt;
      line-height: 1.3;
      color: #212424;
    }

    .quote-author {
      span {
        font-size: 19pt;
        font-weight: 700;
        color: #afb78f;
        font-family: "Amatic SC";
      }
    }
  }

  .parents {
    .title, .description, .name, .nickname {
      z-index: 2;
    }

    .title, .name {
      font-family: "Style Script";
      color: #212424;
    }

    .title {
      font-size: 53pt;
    }

    .name {
      font-size: 25pt;
    }

    .nickname {
      font-family: "Amatic SC";
      font-weight: 700;
      font-size: 19pt;
      color: #432a26;
    }

    .description {
      height: 87.5mm;
      width: 151.5mm;
      top: 172mm;
      left: 27mm;

      font-size: 15pt;
    }

    .profile-solo {
      .name {
        top: 146mm;
      }

      .nickname {
        top: 154mm;
      }
    }

    .profile-duo {
      .photo {
        top: 80mm;
      }
    }

    .profile-1 {
      .photo {
        left: 32mm;
      }
    }
  }

  .chapter {
    .number {
      font-family: "Amatic SC";
      font-size: 150pt;
      font-weight: 700;
      color: #432a26;
    }

    .title {
      top: 208mm;

      font-family: "Style Script";
      font-size: 60pt;
      line-height: 1;
    }
  }

  .journal {
    .journal-section-header {
      object-fit: cover;
      object-position: bottom;
    }

    .journal-section-title {
      top: 30mm;
      font-family: "Style Script";
      font-size: 45pt;
    }
  }

  .entries {
    .name-illustration {
      width: 55mm;

      left: 0;

      border-bottom: 3px dashed #212424;
      overflow: visible;

      .name {
        width: initial;

        position: absolute;
        right: -2mm;

        font-family: "Amatic SC";
        font-weight: 700;
        font-size: 25pt;
      }
    }

    .title {
      font-size: 14pt;
      font-weight: 400;
    }

    .date-illustration {
      align-items: flex-end;

      font-family: "Style Script";
      font-size: 16pt;
      color: #966538;
    }

    .text-s-photo-landscape {
      .photo {
        height: 48.5mm;
        width: 78mm;
      }
    }

    .text-s-photo-portrait {
      .photo {
        height: 56mm;
        width: 33mm;
      }
    }

    .text-m-photo-landscape {
      .photo {
        height: 47mm;
        width: 80mm;
      }
    }

    .text-m-photo-portrait {
      .photo {
        height: 56mm;
        width: 33mm;
      }
    }

    .text-l-photo-landscape {
      .photo {
        height: 47mm;
        width: 76mm;
      }
    }

    .text-l-photo-portrait {
      .photo {
        height: 81.5mm;
        width: 49.5mm;
      }
    }

    .text-0-photo-landscape {
      .entry-filler-illustration {
        top: 27mm;
        left: 16mm;
        width: initial;
      }

      .photo {
        height: 73mm;
        width: 117mm;
      }
    }

    .text-0-photo-portrait {
      .entry-filler-illustration {
        width: initial;
      }

      .photo {
        height: 75mm;
        width: 46.5mm;
      }
    }

    .filler-illustration-1 {
      width: 183mm;
      height: 105mm;
    }
  }

  .letter {
    .photo {
      img {
        object-fit: cover;
      }
    }

    .title, .signature, .text, .subtitle {
      z-index: 2;
    }

    .title {
      font-family: 'Style Script';
      font-size: 36pt;
    }


    .text, .subtitle, .signature {
      line-height: 1.3rem;
    }

    .text, .subtitle {
      font-size: 14pt;
    }

    .signature {
      font-size: 18pt;
      font-weight: 400;
    }
  }

  .special-events {
    &.special-events-10 {
      .bloc-illustration {
        top: -1mm;
        position: absolute;
      }
    }
    &.special-events-16 {
      .bloc-photo-1, .bloc-photo-2 {
        top: 4.5mm;
      }
    }

    .timeline-illustration {
      height: 297mm;
      width: 210mm;
      object-fit: fill;

      top: 0;
      z-index: 3;
    }

    .title {
      top: 32mm;

      font-family: "Style Script";
      text-align: center;
      font-size: 40pt;
    }

    .bloc-date {
      font-family: "Amatic SC";
      font-weight: 700;
      font-size: 20pt;
      color: #212424;
    }
  }

  .mosaic {
    .overlay {
      display: none;
    }

    .title {
      top: 30mm;

      font-family: "Style Script";
      font-size: 35pt;
    }

    .logo-footer {
      path {
        fill:#dcdddc;
      }
    }
  }

  .stats {
    .title {
      font-family: "Style Script";
      font-size: 32pt;
      color: #afb78f;
    }

    .graph-title {
      font-size: 16pt;
      color: #212424;
      font-family: "Style Script";
      font-weight: 700;
    }
  }
}

.book-pdf-cover {
  .boheme-skin {
    .cover {
      &.photo-variant {
        .title {
          top: 218mm;
        }
        .subtitle {
          top: 236mm;
        }
        .author {
          top: 246mm;
        }
      }
    }
  }
}
