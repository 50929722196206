// Shared css class
.outline-gradient {
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.component-container {
  border-radius: $component-radius;
}

.text-h4 {
  font-size: 0.875rem;
  font-family: $headers-font;
  font-weight: bold;
  margin: 1vh 0;
}

.text-p {
  font-size: 0.75rem;
  font-family: $body-font;
  font-weight: normal;
  margin: 1vh 0;
  text-align: center;
}

.text-p-apricot {
  color: $apricot;
}

.text-p-grass {
  color: $grass;
}

.a-dark-apricot {
  color: $dark_apricot;
}

.a-dark-grass {
  color: $dark_grass;
}

.i-sunny {
  background: $sunny;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.align-center {
  margin: 0 auto;
}

// Links
.back-link {
  margin-bottom: 0.5rem;
}

.cancel-link {
  margin-top: 1rem;
}

