// Absolute positions within an A4 page

.quotes {
  .quote-text, .quote-author, .quote-open, .quote-close {
  position: absolute;
  }

  .quote-open, .quote-close {
  width: 8.3mm;
  height: 23mm;
  display: flex;
  align-items: center;
  }

  .quote-text, .quote-author {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  }

  .quote-text {
  width: 160mm;
  height: 35mm;
  }

  .quote-author {
  width: 160mm;
  height: 12.5mm;
  }

  .quote_1 {
    .quote-text {
      left: 24.3mm;
      top: 137mm;
    }

    .quote-author {
      left: 24.3mm;
      top: 174mm;
    }

    .quote-open {
      left: 13mm;
      top: 129mm;
    }

    .quote-close {
      left: 188mm;
      top: 151mm;
    }
  }

  .quote_2 {
    .quote-text {
      left: 24.3mm;
      top: 203mm;
    }

    .quote-author {
      left: 24.3mm;
      top: 240mm;
    }

    .quote-open {
      left: 13mm;
      top: 195mm;
    }

    .quote-close {
      left: 188mm;
      top: 217mm;
    }
  }
}
