.navbar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: $border-extra-small solid $light_gray;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: $navbar-height;
  background: $white;
  z-index: 999;

  .navbar-icons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  // Navbar buttons
  .navbar-btn {
    font-family: "Quicksand", "Helvetica", "sans-serif";
    font-size: 1.5rem;
    background-color: white;
    color: $middle_gray;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    margin: 0.25rem 0;

    &:hover {
      color: $apricot;
    }
  }

  .current-btn {
    color: $apricot;
  }

}


