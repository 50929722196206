.offer-display-none {
  display: none;
}

.modal-thanks-booking {
  display: none;
}

.form-demand-offer {
  margin-top: 20px;

  .summary-pool {
    margin-top: 30px;
    margin-bottom: 30px;

    p {
      margin: 0px;
    }
  }

  .text-on-offer-demand-form {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
    color: $middle_top_grey;

    .btn-sunny {
      margin: auto;
    }
  }

  .simple_form {
    margin-top: 20px;
  }

  .message-offer {
    padding: 10px;
    margin: 20px;
  }

  .phone-pro {
    margin-bottom: 7px;
    margin-top: 25px;

    .fas {
      margin-right: 5px;
    }
  }
}
