.entry-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // Timeline
  .entries-timeline-control {
    display: flex;
    gap: 1.5rem;
    justify-content: space-between;
    margin: 15px 0;

    .timeline-date-selector-container {
      margin: 0;
      padding: 0;
      .timeline-date-selector {
        margin: 0;
        padding: 0;
        flex-grow: 1;
      }
      .timeline-date-submit {
        margin: 0;
        padding: 0;
        height: 38px;
        align-self: center;
      }
    }
  }

  .timeline-tutoriel-slider {
    color: $grass;
    font-size: 12px;
    font-weight: light;
    margin-bottom: 30px;

  }

  .timeline-main-container {
    width: 100%;
    height: 45px;

    .scroll-timeline-chevron {
      color: $apricot;
      font-size: 28px;
    }
  }

  .entry-timeline {
    padding: 2px 5px;
    height: 45px;
    width: auto;
    direction: rtl;
    overflow-x: auto;
    white-space: nowrap;
    display: grid;
    grid-template-rows: 1vh 1vh 1vh;

    #timeline_line {
      grid-row: 2 / 3;
      margin-top: 0.5vh;
      height: 0.5vh;
      margin-right: 5vw;
      background: $middle_gray;
      border-radius: $component-radius-small;
    }

    .timeline_spots {
      display: flex;

      .timeline-day {
        height: 3.5vh;
        width: 3.5vh;
        border-radius: 50%;
        margin-left: 2vw;
        box-sizing: border-box;
        font-size: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $light_gray;
      }

      .has-entries {
        border: $border-big solid $apricot;
        background-color: $apricot;
        color: $white;
      }
      .no-entries {
        border: $border-big solid $middle_gray;
        background-color: $middle_gray;
      }

      .active {
        background-color: $apricot;
        color: $apricot;
        border: $border-big solid $apricot;
      }

      .first {
        margin-right: 5vw;
      }
    }

    .month-divider {
      height: 2.5vh;
      width: 2.5vh;
      display: flex;
      justify-content: center;
      color: $apricot;
      padding: 0 1.5vh;
      background-color: $white;
    }
  }

  .entry-timeline::-webkit-scrollbar {
    display: none;
  }

  // Others elements than the timeline
  #entries_for_a_day {
    width: 100%;
  }

  .entry_grid {
    display: grid;
    grid-template-rows: auto 5vh 5vh auto;
    grid-template-columns: 5vw 86vw 5vw;
    width: 100%;
  }

  // Photos
  .entry-images {
    justify-self: center;
    grid-area: 1/2/3/3;
    width: 100%;
    height: 27vh;
    margin-bottom: 2vh;
    border-radius: $component-radius;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .image-pending {
      width: 100%;
      height: 100%;
      background-color: $light_gray;

      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      h3 {
        color: $middle_gray;
      }
    }

    .swiper-container {
      width: 100%;
      height: 100%;

      .swiper-pagination {
        position: absolute;
        top: 1%;
        left: 25%;
        text-align: center;
        transition: 0.3s opacity;
        transform: translate3d(0, 0, 0);
        z-index: 10;
        width: 50%;
      }

      .swiper-pagination-bullet-active {
        opacity: 1 !important;
        background: $apricot !important;
        width: 0.6rem !important;
        height: 0.6rem !important;
      }

      .swiper-pagination-bullet {
        width: 0.5rem;
        height: 0.5rem;
        display: inline-block;
        border-radius: 50%;
        background: $dark_gray;
        opacity: 0.2;
        margin: 0 1vw;
      }
    }

    .swiper-pagination-bullet:only-child {
      visibility: hidden;
    }

    .swiper-slide {
      img {
        width: 100%;
        height: 100%;
        border-radius: $component-radius;
        object-fit: cover;
        object-position: center;
      }

      .delete-btn-position {
        display: flex;
        position: absolute;
        top: 2%;
        right: 1%;
        color: $apricot;
        border: none;
        margin: 0;
        width: 1.6rem;
        height: 1.6rem;
        padding: 0;
        background-color: transparent;
      }
    }
  }

  // Navigation
  .entry-navigation {
    grid-area: 2/1/4/4;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .entry-date {
      width: 10vh;
      height: 10vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      z-index: 3;

      h3 {
        color: $white;
        margin: 0.2rem;
      }
    }

    .transparent-navigation-btn {
      color: transparent;
      font-size: 2rem;
      text-align: center;
    }

    .navigation-btn {
      font-size: 2rem;
      color: $apricot;
      text-align: center;
      z-index: 3;
    }
  }
  .entry_content,
  .entry_title {
    textarea {
      resize: none;
    }

    .custom-input {
      padding: 0;
      padding-bottom: 0.2rem;
    }
  }

  .entry_content > textarea {
    height: 8 * $line-height;
  }

  .entry_title > textarea {
    height: 2 * $line-height;
  }
}

// Users text entries
.members-entries {
  grid-area: 3/2/5/3;
  width: 100%;

  .entry-components {
    height: auto;
    border-radius: $component-radius;
    border-width: $outline-medium-width;
    margin-top: 2vh;
    padding-bottom: 2vh;
    box-shadow: $outline-box-shadow-long;

    .delete-btn {
      font-weight: normal;
      margin-top: 2vh;
    }

    .member-infos {
      display: flex;
      align-items: center;
      justify-content: left;
      margin: 0 2vw;

      .user-avatar {
        border-radius: 50%;
        height: 3.5vh;
        width: 3.5vh;
        background-color: $light_gray;
      }

      .user-name {
        margin-left: 5vw;
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  .entry-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
  }

  .entry-details {
    display: flex;
    flex-direction: column;
    margin: 0 2vw;

    h3 {
      color: $dark_gray;
      font-size: 1rem;
    }

    p {
      color: $dark_gray;
      white-space: pre-line;
    }

    i {
      padding: 1rem 0;
    }

    .pending {
      color: $middle_gray;
      font-size: 0.9rem;
      text-align: center;
    }

    .entry-form-links {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .btn {
      margin: 0.5rem auto;
    }
  }
}
