.frise-cagnotte {
  img {
    width: 50px;
    margin-right: 5px;
  }
}

.pool-contribution-form-container {
  margin: 2rem auto;
  width: 80%;
  display: flex;
  flex-flow: column wrap;
  padding: 3rem 0;
  max-width: 400px;
  margin: auto;

  .input-contribution {
    input {
      width: 55%;
    }

    select {
      margin-left: auto;
      width: 57%;
    }
  }

  .input-block {
    display: flex;
    align-items: left;
    margin-bottom: 2rem;
    input {
      margin-left: auto;
    }

    .btn-contribution {
      width: 100%;
      text-align: center;
      box-shadow: 0px 0px 10px 0px $grey-box-shadow;
      border: 2px solid $grass;
      background-color: $white;
      border-radius: 10px;
      color: $grass;
      font-weight: 900;
      padding: 15px 5px;
      margin: 5px;
      font-size: 1.5rem;
    }
  }
}

.frise-pool-edit {
  img {
    width: 100px;
    margin-right: 8px;
  }
}

.pool-target-progress-bar {
  position: relative;
  width: 60%;
  height: 50px;
  border: 1px solid $apricot;
  border-radius: 10px;
  margin: 1rem auto;
  position: relative;

  h1 {
    color: grey;
    position: absolute;
    left: 44%;
  }

  .pool-target-reached-target {
    height: 50px;
    background-image: $sunny;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.pool_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.pool-plus {
  border-radius: 25px;
  padding: 5px;
  color: $grass;
  font-size: 30pt;
  position: absolute;
  bottom: -25px;
  background-color: white;
}

.pool-credit-amount-button {
  width: 80px;
  text-align: center;
  box-shadow: $grey-box-shadow;
  border: 2px solid $grass;
  border-radius: 10px;
  color: $grass;
  font-weight: 900;
  padding: 15px 5px;
  margin: 5px;
  font-size: 1.5rem;
}

.pool-participant-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .pool-participant-options {
    padding: 5px 30px;
    div {
      margin: 0 auto;
    }
    .can-spend-submit {
      border: 2px solid $peach;
      background-color: white;
      border-radius: 25px;
      color: $peach;
      font-weight: 600;
      padding: 5px 15px;
    }
    .cannot-spend-submit {
      border: 2px solid $grass;
      background-color: white;
      border-radius: 25px;
      color: $grass;
      font-weight: 600;
      padding: 5px 15px;
    }
  }
}

// TRANSACTION HISTORY

.transaction-card {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  background: white;
  padding: 8px 16px;
  border: 1px solid rgb(235,235,235);
}

.transaction-card .transaction-content {
  flex-grow: 1;
  padding: 0 24px;
  text-align: center;
}

.btn-faq-pool {
  text-align: center;
  margin-top: 25px;
}

.transaction-card p {
  margin: 0;
}

.transaction-card small {
  color: #999999;
  font-weight: bold;
}
.transaction-card {
  display: flex;
}

.pool-transaction {
  margin-top: 25px;
}
