.journal-section-header {
  height: 100mm;
  width: 210mm;

  position: absolute;
  top: 0;
  object-fit: contain;
  object-position: top;
  z-index: 1;
}

.journal-section-title-wrapper {
  height: 82.5mm;
  width: 210mm;
}

.journal-section-title {
  height: 36mm;
  width: 143mm;

  position: absolute;
  left: 33.6mm;
  top: 20mm;

  display: flex;
  align-items: center;
  z-index: 2;
}

// Absolute positions within an A4 page

.entries {
  height: 256.5mm;
  width: 210mm;

  position: absolute;
  top: 27mm;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.entries-remainder {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

.filler-illustraton {
  object-fit: contain;
}

.filler-illustration-1 {
  height: 118mm;
  width: 210mm;
}

.filler-illustration-2 {
  height: 20.5mm;
  width: 114mm;
}

.filler-illustration-3 {
  height: 61mm;
  width: 57mm;
}

.filler-illustration-4 {
  height: 37.5mm;
  width: 114.5mm;
}

.filler-illustration-5 {
  height: 45.3mm;
  width: 69mm;
}

.filler-illustration-6 {
  height: 64.5mm;
  width: 185mm;
}
