#personal-info {
  margin-top: 1vh;
}

.simple_form.edit_user {

  .form-group {
    margin-bottom: 1vh;
  }

  // force white background on autofill
  input:-webkit-autofill {
    box-shadow: $outline-box-shadow;
  }
}

#delete-link {
  margin-bottom: 2rem;
}

.upload-pic-forms-container {
  position: relative;
  margin-bottom: 5rem;
}
.upload-pic-btn {
  font-size: 1.5rem;
  position: absolute;
  top: 90px;
  left: 48%;
  padding: 0;
  margin: 0;
  color: $grass;
  border: none;
  width: 40px;
  height: 40px;
  background-color: transparent;
}

.delete-pic-btn {
  font-size: 1.5rem;
  position: absolute;
  top: 90px;
  left: 48%;
  border: none;
  padding: 0;
  margin: 0 0 0 35px;
  color: $peach;
  width: 40px;
  height: 40px;
  background-color: transparent;
}
