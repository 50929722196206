.hover-tooltip {
    position: relative;
    display: inline-block;
    border-radius: 20px;
    padding: 2px 8px;
    margin-left: 10px;
    background-color: $apricot;
    color: white;
    font-weight: bold;
  }
  
  .hover-tooltip .open-tooltip {
    visibility: hidden;
    width: 220px;
    border-radius: 6px;
    color: grey;
    background-color: white;
    border: 1px solid grey;
    padding: 15px 20px;
    position: absolute;
    right: 8px;
    top: 15px;
    z-index: 1;
    text-align: left;
    font-size: 0.75em;
    font-weight: lighter;
  
    div {
      padding: 2px;
      i {
        width: 50px;
        text-align: center;
      }
    }
  }
  
  .hover-tooltip:hover .open-tooltip {
    visibility: visible;
  }


.grass-box {
  background: $outline-grassy;
  border-radius: 5px;
  color: white;
  padding: 8px 22px;
  font-weight: 900;
}

.apricot-blue-border-box {
  background: $light_apricot;
  border-radius: 10px;
  border: 2px solid steelblue;
  color: grey;
  font-weight: 600;
  padding: 30px 10px;
}