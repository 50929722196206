.select2-container--default .select2-selection--single {
  width: 145px;
  display: flex;
  border-radius: 12px;
  background-color: $blue-25;
  border: none;
  color: $blue-500;
  font-size: 13px;
  font-weight: bold;
  height: 32px;
  align-items: center;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: $blue-500 transparent transparent transparent;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: $blue-500 transparent transparent transparent;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  display: none;
}

.field-select2 {
  width: 145px;
  display: flex;
  border-radius: 12px;
  background-color: $blue-25;
  border: none;
  color: $blue-500;
  font-size: 13px;
  margin-left: 10px;
  font-weight: bold;
  height: 32px;
  align-items: center;
  padding-right: 8px;
  padding-left: 8px;
  margin-top: -6px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  display: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: $blue-500;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  border: none !important;
  width: 16px;
  height: 16px;
  background-image: url("../../assets/images/offers/arrow-offers.png");
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 7px;

  b {
    display: none !important;
  };
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: $white;
  color: $blue-500;
}

.select2-container--open .select2-dropdown--below {
  color: $blue-500;
  border-radius: 7px;
  font-weight: bold;
  font-size: 13px;
  box-shadow: 0 0 6px $blue-500;
}

.select2-container--default .select2-results__option.custom-option.type-class {
  background-color: $blue-25;
  color: $blue-500;
}

.select2-container--default .select2-results__option.custom-option.moment-class {
  background-color: $blue-25;
  color: $blue-500;
}

.select2-container--default .select2-results__option.custom-option.format-class {
  background-color: $blue-25;
  color: $blue-500;
}

.select2-container--default .select2-results__option.custom-option.location-class {
  background-color: $blue-25;
  color: $blue-500;
}

.select2-container--default .select2-results__option.custom-option.profession-class {
  background-color: $blue-25;
  color: $blue-500;
}

.select2-container--default .select2-results__option.custom-option.geography-class {
  background-color: $blue-25;
  color: $blue-500;
}

.select2-container--default .select2-results__option.custom-option.service-class {
  background-color: $blue-25;
  color: $blue-500;
}

#filter-form {
  margin-top: 10px;
}

.offers-filters {
  .text-blue {
    color: $grass;
  }

  .h3-title {
    text-align: left;
  }

  .display-type-offers {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .title-pregnancy-step {
      margin-left:15px;
      text-align: left;
      margin-bottom: -5px;
    }

    .text-pregnancy-step {
      margin-left:15px;
      color: $grass;
      font-size: 13px;
    }

    .citation-offers {
      display: flex;
      align-items: center;
    }
    .block-white {
      width: 49%;

      .words-img  {
        height: 32px;
        margin-top: 15px;
        margin-right: 15px;
        margin-left: 15px;
      }

      .prestation-type-title {
        margin-top: 20px;
      }

      .citation {
        width: 85%;
      }
    }
  }
}

.display-type-offers {
  .map-offers {
    width: 100%;
    justify-content: space-between;

    .map {
      border: 1px solid green;
      border-radius: 24px;
      margin-top: 30px;
    }
  }

  .offers {
    .title-offers-format {
      justify-content: flex-start;

      .icone-title-offers {
        margin-right: 0px;
      }

      .icone-home {
        margin-top: -6px;
      }

      .icone-remote {
        margin-top: -5px;
      }
    }
  }
}

.offers {
  .filter-offer {
    label {
      font-weight: 600;
      color: $grass;
      font-size: 13px;
    }
  }

  .offers-scrollable {
    max-height: 500px;
    overflow-y: auto;
    width: 95%;
    margin: auto;
    margin-top: -10px;
  }

  .offers-scrollable-geography {
    margin-top: 10px;
  }

  .all-filters {
    width: 95%;
    margin-top: 15px;
    display: flex;
    margin: auto;
  }

  .dropdown-summary::-webkit-details-marker {
    display: none;
  }

  .dropdown-summary::marker {
    display: none;
  }

  .dropdown-offers[open] .dropdown-summary::after {
    display: none;
  }

  .dropdown-icon-close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: $grass;
    font-size: 16px;
    transition: opacity 0.2s ease;
    display: none;
    margin-right: 10px;
  }

  .dropdown-offers[open] .dropdown-icon-close {
    display: inline;
  }

  .dropdown-summary {
    margin-top: 10px;
    margin-right: 20px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid $blue-500;
    border-radius: 4px;
    color: $grass;
    background-color: $blue-50;
    font-size: 16px;
    width: 130px;
  }

  .dropdown-summary::after {
    content: '';
    right: 10px;
    transform: translateY(-50%) rotate(45deg); /* Positionner et orienter la flèche */
    width: 4px;
    height: 8px;
    border-right: 2px solid $grass;
    border-bottom: 2px solid $grass;
    top: 38%;
  }

  .dropdown[open] .dropdown-summary::after {
    border-top-color: transparent;
    border-bottom-color: #333;
  }

  .dropdown-content {
    position: absolute;
    width: 180px;
    padding: 10px;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    box-sizing: border-box;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 0 6px #ccc;
    border-radius: 7px;

    label {
      color: $grass;
      font-size: 13px;
      font-weight: bold;
    }

    input {
      opacity: 25%;
      width: 12px !important;
      height: 12px !important;
      margin: 5px;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance:none;
      outline: 2px solid $blue-500;
      box-shadow: none;
      font-size: 2em;

      &::after {
        content: '';
        display: none;
        width: 5px;
        height: 10px;
        border: solid $blue-500;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        margin-left: 2px;
      }

      &:checked {
        &::after {
          display: block;
        }
      }
    }


  }
  .dropdown[open] .dropdown-content {
    display: block;
  }

  .form-check {
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
    margin-bottom: 5px;
    justify-content: space-between;
  }

  .form-check-input {
    margin-right: 8px;
  }

  .form-check-label {
    cursor: pointer;
  }

  .dropdown-summary:hover {
    background-color: #f8f9fa;
  }

  .form-check-input:focus + .form-check-label {
    outline: 2px solid #66afe9;
  }

  .search-toggle {
    width: 95%;
    display: flex;
    justify-content: space-between;
    margin: auto;

    .text-active {
      color: $blue-500;
      font-weight: bold;
      font-size: 15px;
    }

    .text-active-mobile {
      display: none;
      color: $blue-500;
      font-weight: bold;
      font-size: 15px;
    }

    .switch-container {
      margin-top: 40px;
    }

    .active-opacity {
      opacity: 50%;
    }

    .text-all-offers {
      font-weight: bold;
      font-size: 15px;
      color: $medium_apricot
    }

    .text-all-offers-mobile {
      display: none;
      font-weight: bold;
      font-size: 15px;
      color: $medium_apricot
    }

    .text-blue {
      color: $grass;
      font-size: 13px;
    }

    .search-label {
      width: 45%;

      label {
        font-weight: 600;
      }
    }

    .search-offers {
      border: 1px solid $blue-500;
      background-color: $blue-50;
      border-radius: 7px;
      color: $blue-500;
      margin-top: 10px;

      img {
        width: 16px;
        margin-left: 15px;
        margin-right: 7px;
        margin-bottom: -2px;
      }

      input {
        border: none;
        background-color: $blue-50;
        color: $blue-500;
        height: 37px;
      }

      input::placeholder {
        color: $blue-500;
        opacity: 50%;
      }
    }
  }

  .title-offers {
    display: flex;
    width: 95%;
    padding-top: 30px;
    margin: auto;

    .icone-professional {
      margin-top: -5px;
    }

    .select2-search__field {
      display: none;
    }

    .title-offers-search-type {
      display: flex;
    }

    .btn-white {
      margin-top: -10px;
      width: 170px;
    }

    .text-blue {
      color: $grass;
      margin-top: 0px;
      text-align: left;
      margin-left: 10px;
    }
  }

  .toggle-container {
    position: relative;
    display: inline-block;
    width: 180px;
    height: 50px;
  }

  #active_only {
    display: none;
  }

  .toggle-button {
    position: relative;
    width: 56px;
    height: 28px;
    background-color: #ccc;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    outline: none;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0px;
  }

  .toggle-button.active {
    background-color: $blue-500;
  }

  .toggle-handle {
    position: absolute;
    top: 1px;
    left: 5px;
    width: 26px;
    height: 26px;
    background-color: $white;
    border-radius: 50%;
    transition: transform 0.3s;
  }

  .toggle-button.active .toggle-handle {
    transform: translateX(22px);
  }

  .offers-filters {
    p {
      text-align: left;
    }

    span {
      color: $dark_apricot;
    }

    .link-select-all-filters {
      border-right: solid 2px $apricot;
      height: 47px;
    }

    .link-display {
      width: 100%;
      display: flex;
      justify-content: center;

      a {
        color: $black;
        text-decoration: underline;
        font-weight: normal;
      }
    }

    .tag-tab-list-controller {
      overflow-x: scroll;
    }

    .none-filters {
      display: none;
    }

    .tag-tab-offers {
      display: flex;
      flex-wrap: wrap;
    }

    .tag-tab-list {
      border-left: none;
    }

    .prestations-links {
      display: flex;
      overflow-x: scroll;
      scrollbar-width: none;
      margin-bottom: 15px;
      margin-left:10px;

      .link-offers-filtres {
        width: 100px;
        margin-top: 20px;
        height: 100px;
      }

      .img-link-offers {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: -8px;
      }

      p {
        font-size: 12px;
        color: $grass;
        width: 100px;
        text-align: center;
        line-height: 15px;
      }

      .active {
        background-color: $grass;
        border-radius: 10px;
        height: 105px;
        padding: 3px;
        margin-left: 5px;
        margin-right: 5px;

        p {
          color: $white;
        }

        .img-link-offers {
          margin-top: -15px;
        }
      }

      .words-img-offers {
        width: 59px;
      }

      .activity-img-offers {
        width: 59px;
      }

      .care-img-offers {
        width: 45px;
      }

      .information-img-offers {
        width: 45px;
      }

      .advice-img-offers {
        width: 52px;
      }
    }
  }
}

// Réduction des tailles pour les petits mobiles
@media (max-width: 1100px){
  .offers {
    .all-filters {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 880px){
  .offers {
    .text-all-offers-mobile {
      display: block !important;
    }

    .text-all-offers {
      display: none;
    }

    .text-active {
      display: none;
    }

    .text-active-mobile {
      display: block !important;
    }
  }
}

@media (max-width: 790px){
  .offers-filters {
    .display-type-offers {
      flex-direction: column;

      .block-white {
        width: 95%;
        margin: auto;
        margin-top: 30px;
      }
    }
  }

  .display-type-offers {
    .map-offers {
      flex-direction: column;

      .mapboxgl-map {
        width: 95% !important;
        margin: auto;
        margin-top: 30px;
      }
    }
  }

  .offers {
    h1 {
      font-size: 18px;
    }

    .filter-offer {
      label {
        font-size: 11px;
      }

      .dropdown-summary {
        font-size: 11px;
      }
    }

    .all-filters {
      justify-content: space-around;
    }

    .search-toggle {
      .text-all-offers-mobile {
        font-size: 11px;
      }

      .form-group {
        flex-wrap: nowrap;
      }

      .text-blue {
        font-size: 11px;
      }

      .search-offers {
        display: flex;

        input {
          width: 90px;
        }

        img {
          height: 16px;
          margin-top: 12px;
        }
      }

      .text-active-mobile {
        font-size: 11px;
      }

      input::placeholder {
        font-size: 11px
      }
    }
  }
}

@media (max-width: 435px){
  .search-toggle {
    .text-all-offers-mobile {
      font-size: 8px;
      margin-left: 15px;
      width: 57px;
    }

    .text-active-mobile {
      font-size: 8px;
    }
  }

  .offers-filters{
    .offers {
      .all-filters {
        margin-top: 15px;
      }
    }
  }

  .offers-filters {
    .location-filter {
      a {
        font-size: 15px;
        padding: 0.4rem 0.5rem !important;
      }
    }

    .prestations-links {
      .link-offers-filtres {
        width: 130px;
      }

      .active {
        height: 97px;
      }

      h2 {
        font-size: 15px;
        line-height: 20px;
      }

      .link-offers {
        width: 100px;
        height: 100px;
      }
    }
  }
}

@media (max-width: 355px){
  .mobile-location {
    display: flex;
    flex-direction: column;
  }

  .offers-filters {
    .location-filter {
      a {
        margin-top: 10px !important;
      }
    }
  }
}
