.arguments-container {
  margin: $margin-xl-large 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  h3 {
    text-align: justify;
  }

  h1 {
    margin: $web-margin-medium 0;
    text-align: justify;
  }

  .app-description {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-preview-img {
    height: 22rem;
  }

  .beohmy-container {
    margin-left: 4rem;
  }

  h3.dark-gray {
    color: $dark_gray;
  }
}

.line-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 1024px) {
  .arguments-container {
    h1,
    h2,
    h3 {
      text-align: center;
    }
    flex-direction: column;
    margin: $margin-xl-large $margin-medium;
  }

  .line-container {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }
}

.teasing-msg {
  background: $light-grass;
  border-radius: $component-radius;
  box-shadow: 5px rgba(0, 0, 0, 0.1);
  margin-bottom: $margin-large;
  padding: 1rem 2rem;
  h4 {
    color: $grass;
  }
}
