.create-account {
  text-align: center;
  margin-bottom: 30px;
}

.modal-create-account {
  display: flex;
  flex-direction: column;
  align-items: center;
}
