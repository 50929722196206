// We need to override trix.css’s image gallery styles to accommodate the
// <action-text-attachment> element we wrap around attachments. Otherwise,
// images in galleries will be squished by the max-width: 33%; rule.
.trix-content {
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  action-text-attachment {
    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }

  h1 {
    font-size: 1.25rem;
  }

  img {
    width: 100%;
    max-height: 15rem;
    object-fit: contain;
    height: auto;
  }
}

trix-editor {
  .attachment > img {
      max-width: 80%;
      max-height: 25rem;
      object-fit: contain;
  }
}

.trix-button {
  padding: 0.2rem 0.5rem;
  text-shadow: none;
  border-radius: 0;
  background: transparent;
  color: $dark-gray;
  border: none;
  box-shadow: none;
}

form button.trix-button:not(.disabled):hover {
  background: transparent;
  color: $dark-grass
}

trix-editor {
  background: $white;
  margin: 0.7rem;
  margin-top: -$border-small;
  padding: 0.6rem;
  border-radius: $component-radius-small;
  border: $border-small solid $middle-gray;
  max-height: 30rem;
  overflow-y: auto;
}

form.formtastic ol {

  .trix-content > ul, ol {
    margin: 0 1.5em 1.5em 0;
    padding-left: 1.5em;
  }

  .trix-content > ul {
    list-style: disc;
  }

}

.trix-content ul > li > ul {
  list-style: circle;
}

.trix-content ul > li > ul > li > ul {
  list-style: disc;
}

.trix-content > ol {
  list-style: decimal;
}

trix-toolbar {
  background: $light-gray;
  margin: 0.7rem;
  margin-bottom: -$border-small;
  padding: 0.2rem 0.6rem;
  margin-bottom: 0;
  border-radius: $component-radius-small;
  border: $border-small solid $middle-gray;

  .trix-button-group {
    margin-bottom: 0;
  }
}
