// Absolute positions within an entry partial i.e. A4 width, height defined here

.entry {
  position: relative;
  width: 210mm;
  margin-bottom: 3mm;

  .date-illustration, .name-illustration, .title, .content, .photo-illustration, .entry-filler-illustration {
    position: absolute;
  }

  .date-illustration, .name-illustration {
    left: 12.6mm;
    height: 12.2mm;
    width: 56.7mm;
  }

  .date-illustration, .name-illustration, .photo-illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .photo-illustration {
    z-index: 2;
  }

  .name-illustration {
    top: 12.2mm;
  }

  .date, .name {
    height: 7mm;
    width: 49.3mm;
    justify-content: center;
    text-align: center;
  }

  .date, .name, .title {
    display: flex;
    align-items: center;
    z-index: 2;
  }

  .photo {
    object-fit: cover;
  }

  .photo-bg, .date-bg, .name-bg {
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.text-0-photo-0 {
  height: 30mm;

  .title {
    left: 73mm;
    top: 12.2mm;
    width: 125mm;
    height: 12mm;
  }
}

.text-0-photo-portrait {
  height: 81mm;

  .title {
    left: 137mm;
    top: 26mm;
    width: 50mm;
    height: 31mm;
  }

  .photo {
    width: 40.5mm;
    height: 70.3mm;
  }

  .photo-illustration {
    left: 72.9mm;
    top: 0mm;
    width: 52.7mm;
    height: 81mm;
  }

  .entry-filler-illustration {
    left: 133mm;
    top: 29mm;
    width: 76mm;
    height: 52mm;
  }
}

.text-s-photo-portrait {
  height: 79mm;

  .title {
    left: 12.6mm;
    top: 28mm;
    width: 144mm;
    height: 7.5mm;
  }

  .content {
    left: 12.6mm;
    top: 35.5mm;
    width: 144mm;
    height: 42.5mm;
  }

  .photo {
    width: 29mm;
    height: 52mm;
  }

  .photo-illustration {
    left: 159.5mm;
    top: 17mm;
    width: 37.3mm;
    height: 60.5mm;
  }
}

.text-m-photo-portrait {
  height: 116mm;

  .title {
    left: 73mm;
    top: 12.6mm;
    width: 124mm;
    height: 12mm;
  }

  .content {
    left: 73mm;
    top: 24.6mm;
    width: 124mm;
    height: 91mm;
  }

  .photo {
    width: 44mm;
    height: 75.5mm;
  }

  .photo-illustration {
    left: 12.6mm;
    top: 28mm;
    width: 57mm;
    height: 88mm;
  }
}

.text-l-photo-portrait {
  height: 197mm;

  .title {
    left: 72.5mm;
    top: 12mm;
    width: 125mm;
    height: 12mm;
  }

  .content {
    left: 72.5mm;
    top: 26.5mm;
    width: 125mm;
    height: 169mm;
  }

  .photo {
    width: 43.5mm;
    height: 75.5mm;
  }

  .photo-illustration {
    left: 12.6mm;
    top: 27.5mm;
    width: 56.5mm;
    height: 87.5mm;
  }
}

.text-0-photo-landscape {
  height: 81mm;

  .title {
    left: 15.6mm;
    top: 26mm;
    width: 50mm;
    height: 31mm;
  }

  .photo {
    width: 107mm;
    height: 62mm;
  }

  .photo-illustration {
    left: 72.8mm;
    top: 0mm;
    width: 124.5mm;
    height: 80.3mm;
  }

  .entry-filler-illustration {
    left: 0mm;
    top: 35mm;
    width: 69mm;
    height: 45.3mm;
  }
}

.text-s-photo-landscape {
  height: 83mm;

  .title {
    left: 99mm;
    top: 12mm;
    width: 98.5mm;
    height: 13mm;
  }

  .content {
    left: 99mm;
    top: 25mm;
    width: 98.5mm;
    height: 57mm;
  }

  .photo {
    width: 71mm;
    height: 41.5mm;
  }

  .photo-illustration {
    left: 12.6mm;
    top: 26mm;
    width: 83mm;
    height: 53.5mm;
  }
}

.text-m-photo-landscape {
  height: 126mm;

  .title {
    left: 12.6mm;
    top: 38mm;
    width: 101mm;
    height: 14mm;
  }

  .content {
    left: 12.6mm;
    top: 55mm;
    width: 184.5mm;
    height: 70mm;
  }

  .photo {
    width: 69mm;
    height: 40mm;
  }

  .photo-illustration {
    left: 117mm;
    top: 0;
    width: 80.5mm;
    height: 52mm;
  }
}

.text-l-photo-landscape {
  height: 186mm;

  .title {
    left: 12.6mm;
    top: 36.5mm;
    width: 100mm;
    height: 15mm;
  }

  .content {
    left: 12.6mm;
    top: 55mm;
    width: 184.5mm;
    height: 130.5mm;
  }

  .photo {
    width: 69mm;
    height: 40mm;
  }

  .photo-illustration {
    left: 116.5mm;
    top: 0mm;
    width: 80.5mm;
    height: 52mm;
  }
}

.text-s-photo-0 {
  height: 70mm;

  .title {
    left: 73mm;
    top: 12.6mm;
    width: 125mm;
    height: 12mm;
  }

  .content {
    left: 12.6mm;
    top: 28mm;
    width: 185mm;
    height: 42mm;
  }
}

.text-m-photo-0 {
  height: 95mm;

  .title {
    left: 72.5mm;
    top: 12mm;
    width: 125mm;
    height: 12.5mm;
  }

  .content {
    left: 12.6mm;
    top: 27.5mm;
    width: 184.5mm;
    height: 70mm;
  }
}

.text-l-photo-0 {
  height: 161mm;

  .title {
    left: 72.5mm;
    top: 12.6mm;
    width: 125mm;
    height: 12mm;
  }

  .content {
    left: 12.6mm;
    top: 27mm;
    width: 184.5mm;
    height: 134mm;
  }
}
