.rounded {
  border-radius: 50%;
  color: white;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: $margin-small;
  text-align: center;
}

.rounded-lg {
  border-radius: 50%;
  color: white;
  width: 3.5rem;
  height: 3.5rem;
  margin-right: $margin-small;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: small;
  }
}

.rounded-orange {
  background: $apricot;
}

.rounded-green {
  background: $grass;
}
