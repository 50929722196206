// OUTLINES //
$outline-width: 3px;
$outline-medium-width: 2px;
$outline-small-width: 1px;

// RADIOS //
$check-radio: 20px;
$check-radio-position: 6px;
$check-radio-check-top: -30px;

// DIVIDERS //
$light-divider: 1px solid $apricot;
$grey-divider: 1px solid $middle_gray;
