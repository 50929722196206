// Absolute positions within an A4 page

.chapter {
  .chapter-title {
    left:34mm;
    top:47mm;
    width: 143mm;
    height: 36mm;
    position: absolute;
    text-align: left;
  }

  .number {
    left:12.6mm;
    top: 39mm;
    width: 80mm;
    height: 102.5mm;
    position: absolute;
  }

  .title {
    left:12.6mm;
    top:192mm;
    width: 184.3mm;
    height: 46.2mm;
    position: absolute;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

}
