#pregnancy_outcome {
  margin: 0 $margin-large;
}

#pregnancy_announcement_photo {
  margin: 0 auto;
}

.recommendation-card {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.recommendation-card img {
  width: 30%;
  object-fit: contain;
}

.recommendation-infos {
  margin-left: $margin-medium;
}

.recommendation-infos p {
  margin: 0;
}

form.new_baby {
  .checkbox-group {
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: flex-start;
    margin: 0 auto;
  }
}

