.book-pricing-description {
  .price-table {
    th, td {
      * {
        width: 4em;
      }
    }

    tbody {
      th {
        .side-header {
          width: 10em;
        }
      }
    }

    &:first-of-type {
      tbody {
        tr {
          &:last-of-type {
            td {
              border-radius: 0;
            }
          }
        }
      }
    }

    &:last-of-type {
      tbody {
        tr {
          &:first-of-type {
            td {
              border-radius: 0;
            }
          }
        }
      }
    }
  }
}
