@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Caveat:wght@400;700&display=swap");

.pop-skin {
  font-family: "Caveat";
  font-size: 19pt;
  font-weight: 400;
  color: #000;

  // .cover, .parents, .babies, .letter {
  //   .background-illustration {
  //     z-index: 1;
  //   }
  // }

  .cover {
    .title, .subtitle {
      color: #5c4d9b;
    }

    .date, .author {
      color: #fff;
    }

    .title {
      justify-content: left;
      font-family: "Bebas Neue";
      font-size: 45pt;
      font-weight: 700;
    }

    .author {
    }

    .subtitle {
      font-weight: 700;
      font-size: 21pt;
    }

    .date {
      font-weight: 700;
      font-size: 21pt;

      span {
        position: relative;
        top: -1mm;
        left: -1.2mm;
      }
    }

    &.photo-variant {
      .title, .author, .subtitle {
        left: 91mm;
      }

      .title {
        top: 211mm;
        width: 115mm;
        height: 28mm;
      }

      .subtitle {
        width: 58mm;
        top: 245mm;
        left: 95mm;
      }

      .author {
        width: 62mm;
        top: 253.5mm;
        left: 92mm;

        justify-content: center;
      }

      .date  {
        left: 77mm;
        top: 222.5mm;
        font-size: 18pt;
        line-height: 14pt;
      }

      .photo {
        left: 34mm;
        top: 75mm;
        width: 95mm;
        height: 131mm;
      }
    }

    &.text-variant {
      .title, .subtitle, .author {        
        left: 50%;
        transform: translateX(-50%);

        justify-content: center;
      }

      .title {
        width: 110mm;
        top: 72mm;
      }

      .subtitle {
        top: 97mm;
      }

      .author {
        top: 109mm;
      }

      .date {
        top: 48.5mm;
        left: 40mm;
      }
    }

    &.cover_fourth {
      &.photo-variant {
        .photo {
          height: 95mm;
          width: 130mm;
    
          top: 56mm;
          left: 42.1mm;
        }
      }
    }
  }

  .babies {
    .title, .name, .date, .weight, .height, .message {
      z-index: 2;
    }

    .title, .name {
      font-family: "Bebas Neue";
      color: #00a392;
    }

    .title {
      font-size: 53pt;
    }

    .name {
      top: 158mm;
  
      font-size: 28pt;
    }

    .date, .weight, .height, .message {
      font-size: 19pt;
      color: #e72465;
    }

    .baby-photo {
      height: 88mm;
    }

    &.variant-2 {
      .title, .name {
        color: #e72465;
      }

      .date, .weight, .height, .message {
        color: #f8f099;
      }
    }
  }

  .summary {
    font-family: "Bebas Neue";

    .title {
      top: 19mm;
      left: 38mm;

      font-size: 69pt;
      color: #5c4d9b;

      &::after {
        content: "!";
        margin-left: 4mm;
      }
    }

    .summary-decoration {
      bottom: -4mm;
    }

    .summary-chapter {
      .name, .page {
        font-family: "Bebas Neue";
      }

      .name {
        font-size: 19pt;
        // line-height: 1.75;
      }

      .number {
        font-family: "Caveat";
        font-size: 40pt;
        font-weight: 700;
        color:#e72465;
        line-height: 1;
      }

      .page {
        font-size: 20pt;
        line-height: 1.75;
      }
    }

    .summary-section {
      font-size: 15pt;

      .number, .name {
        font-family: "Caveat";
      }
    }
  }

  .header {
    height: initial;

    font-weight: 700;
    font-size: 18pt;
    line-height: 1.1;

    color: #afdef6;
  }

  .footer {
    .logo-footer {
      path {
        fill:#5c4d9b;
      }
    }

    .page-number {
      height: 13mm;
      width: 11mm;
      bottom: 2mm;

      font-family: "Bebas Neue";
      font-size: 16pt;

      color: #e72465;

      &.page-number-left {
        left: 10mm;
      }

      &.page-number-right {
        right: 10mm;
      }

      object {
        height: 100%;
        width: 100%;
      }
    }
  }

  .quotes {
    .quote-open, .quote-close {
      font-family: "Bebas Neue";
      font-weight: 700;
      font-size: 20pt;
    }

    .quote-text {
      font-family: "Bebas Neue";
      font-size: 13pt;
      font-weight: 700;
    }

    .quote-author {
      span {
        font-size: 19pt;
        color: #e72465;
      }
    }
  }

  .parents {
    .title, .description, .name, .nickname {
      z-index: 2;
    }

    .title, .name {
      font-family: "Bebas Neue";
      color: #e72465;
    }

    .title {
      font-size: 53pt;
    }

    .name {
      top: 142.5mm;
      font-size: 20pt;
    }

    .nickname, .description {
      color: #5c4d9b;
    }

    .nickname {
      font-size: 19pt;
    }

    .description {
      height: 87.5mm;
      width: 151.5mm;
      top: 172mm;
      left: 27mm;
      
      font-size: 15pt;
    }

    .profile-duo {
      .photo {
        top: 83mm;
      }
    }

    .profile-solo {
      .photo {
        left: 72mm;
        top: 79mm;
        width: 64mm;
        height: 64mm;
      }

      .name {
        top: 149mm;
      }

      .nickname {
        top: 158mm;
      }
    }

    .profile-1 {
      .photo {
        left: 32mm;
      }
    }
  }

  .chapter {
    .number {
      left: 17.6mm;
      top: 53mm;

      font-size: 150pt;
      line-height: initial;
      color: #f8c9de;
    }

    .title {
      top: 209mm;

      font-family: "Bebas Neue";
      font-size: 43pt;

      color: #fff;
    }

    &.variant-2 {
      .number, .title {
        color: #e72465;
      }
    }

    &.variant-3 {
      .number, .title {
        color: #5c4d9b;
      }
    }

    &.variant-4 {
      .number, .title {
        color: #00a392;
      }
    }

    &.variant-5 {
      .number, .title {
        color: #f8f099;
      }
    }
  }

  .journal {
    .journal-section-header {
      object-fit: cover;
      object-position: bottom;
    }

    .journal-section-title {
      width: 98mm;

      top: 24mm;
      left: 0;

      justify-content: flex-end;

      font-family: "Bebas Neue";
      font-size: 40pt;

      &.journal-t1 {
        color: #f8f099;
      }

      &.journal-t2 {
        color: #e72465;
      }

      &.journal-t3 {
        color: #afdef6;
      }

      &.journal-delivery {
        color: #f8c9de;
      }

      &.journal-first-months {
        color: #5c4d9b;
      }
    }
  }

  .entries {
    .name-illustration {
      width: 55mm;
      
      overflow: visible;

      justify-content: flex-start;

      .name {
        width: initial;

        position: absolute;

        font-size: 18pt;
        color: #e72465;
      }

      .name-bg {
        height: initial;
        bottom: 0;
      }
    }

    .title {
      font-size: 18pt;
      font-weight: 400;
    }

    .date-illustration {
      align-items: flex-end;

      font-size: 16pt;
      color: #fff;

      .date {
        height: 12mm;
        font-size: 20pt;
      }
    }

    .text-s-photo-landscape {
      .photo {
        height: 48.5mm;
        width: 78mm;
      }
    }

    .text-s-photo-portrait {
      .photo {
        height: 56mm;
        width: 33mm;
      }
    }

    .text-m-photo-landscape {
      .photo {
        height: 47mm;
        width: 80mm;
      }
    }

    .text-m-photo-portrait {
      .photo {
        height: 56mm;
        width: 33mm;
      }
    }

    .text-l-photo-landscape {
      .photo {
        height: 47mm;
        width: 76mm;
      }
    }

    .text-l-photo-portrait {
      .photo {
        height: 81.5mm;
        width: 49.5mm;
      }
    }

    .text-0-photo-landscape {
      .entry-filler-illustration {
        top: 27mm;
        left: 16mm;
        width: initial;
      }

      .photo {
        height: 73mm;
        width: 117mm;
      }
    }

    .text-0-photo-portrait {
      .entry-filler-illustration {
        width: initial;
      }

      .photo {
        height: 75mm;
        width: 46.5mm;
      }
    }

    .filler-illustration-1 {
      width: 183mm;
      height: 105mm;
    }
  }

  .letter {
    .photo {
      left: 29.5mm;
      top: 49.5mm;
      width: 51mm;
      height: 51mm;

      img {
        object-fit: cover;
      }
    }

    .title, .signature, .text, .subtitle {
      z-index: 2;
    }

    .title, .signature {
      font-family: 'Bebas Neue';
    }

    .title {
      font-size: 20pt;
      font-weight: 700;
      color: #5c4d9b;
    }


    .text, .subtitle, .signature {
      line-height: 1.3rem;
    }

    .text, .subtitle {
      font-size: 14pt;
    }

    .subtitle, .signature {
      text-align: right;
    }

    .signature {
      font-size: 14pt;
      color: #5c4d9b;
    }
    .subtitle {
      color: #e72465;
    }
  }

  .special-events {
    &.special-events-10 {
      .bloc-illustration {
        top: -1mm;
        position: absolute;
      }
    }
    &.special-events-16 {
      .bloc-photo-1, .bloc-photo-2 {
        top: 4.5mm;
      }
    }

    .timeline-illustration {
      height: 297mm;
      width: 210mm;
      object-fit: fill;

      top: 0;
      z-index: 3;
    }

    .title {
      top: 32mm;

      font-family: "Bebas Neue";
      text-align: center;
      font-size: 25pt;
      color: #f8c9de;
    }

    .event-bloc {
      .bloc-date, .bloc-text {
        font-family: "Caveat";
      }

      .bloc-date {
        font-size: 14pt;
        color: #e72465;
      }

      .bloc-text {
        font-size: 12pt;
      }
    }
  }

  .mosaic {
    .overlay {
      display: none;
    }

    .title {
      top: 30mm;

      font-family: "Bebas Neue";
      font-size: 25pt;

      color: #5c4d9b;
    }

    .header {
      color: #fff;
    }
  }

  .stats {
    .title {
      font-family: "Bebas Neue";
      font-size: 32pt;
      color: #afb78f;
    }

    .graph-title {
      font-size: 16pt;
      color: #212424;
      font-family: "Bebas Neue";
      font-weight: 700;
    }
  }
}

.book-pdf-cover {
  .boheme-skin {
    .cover {
      &.photo-variant {
        .title {
          top: 218mm;
        }
        .subtitle {
          top: 236mm;
        }
        .author {
          top: 246mm;
        }
      }
    }
  }
}
