.simple_form.new_pregnancy_invite {
  margin: 0 15vw;
}

.new-preg-participation-container {
  margin: 0 5vw;

  p {
    margin-top: 0;
    text-align: justify;
  }

  h3 {
    margin-top: 2rem;
  }
}
