// Absolute positions within an A4 page

.footer {
  width: 185mm;
  height: 14.3mm;

  position: absolute;
  left: 12.7mm;
  top: 284.5mm;
  z-index: 4;

  display: flex;
  justify-content: space-between;
}

.logo-footer {
  width: 40.2mm;
  height: 11.3mm;
  object-fit: contain;
  position: absolute;

  left: 50%;
  transform: translateX(-50%);

  .svg-container {
    width: 33mm;
    height: 11.3mm;
    left: 4.5mm;
    object-fit: contain;
    position: absolute;
  }

  object {
    width: 46.6mm;
    height: 17.8mm;
    position: absolute;
    bottom: -1mm;
    left: -3mm;
  }
}

.page-number {
  width: 16mm;
  height: 11.3mm;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  &.page-number-left {
    left: 0;
  }

  &.page-number-right {
    right: 0;
  }

  img, object {
    position: absolute;
  }

  span {
    z-index: 1;
  }
}
