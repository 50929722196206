.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;

  .modal-content {
    background-color: $white;
    margin: 20px;
    padding: 20px;
    border: 1px solid $dim_grey;
    width: 80%;
    max-width: 395px;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);

    .label-employee {
      opacity: 50%;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .btn-copy {
    border: 1px solid $grass;
    border-radius: 8px;
    background-color: $grass;
    align-items: center;
    width: 50px;
    display: flex;
    justify-content: center;
    margin-left: 5px;
    margin-top: 1px;
    height: 41px;

    img {
      width: 24px;
    }
  }

  .input-group-copy {
    display: flex;
  }

  .btn-dashboard {
    margin-bottom: 15px;
  }

  .btn-blue {
    width: 100%;
    border: 1px solid $grass;
    margin-left: -2px;
    margin-top: 25px;
    height: 44px;
  }

  .icone-btn {
    width: 18px;
    margin-right: 5px;
  }

  .link-pool-invation {
    display: flex;
  }

  .text-blue-or {
    text-align: center;
    color: $blue-200;
  }

  .text-blue {
    margin-bottom: 5px;
  }

  p {
    font-size: 13px;
  }

  .form-invite {
    margin-top: 20px;
  }

  .input-group-copy {
    .form-control-copy {
      width: 85%;
      color: rgba($blue-500, 0.5) !important;
      font-size: 13px;
    }
  }

  .form-add-credit {
    flex-direction: column;
    display: flex;

    label {
      font-size: 13px;
      color: $grass;
      font-weight: normal;
    }

    input[type="text"] {
      background-color: $blue-50;
      border: 1px solid $blue-500;
      color: $grass;
      border-radius: 7px;
      height: 40px;
      margin-left: -2px;
      padding-left: 15px;
    }

    input[type="email"] {
      background-color: $blue-50;
      border: 1px solid $blue-500;
      color: $grass;
      border-radius: 7px;
      height: 40px;
      margin-left: -2px;
      padding-left: 15px;
    }

    input::placeholder {
      color: $blue-500;
      font-size: 13px;
      opacity: 50%;
    }
  }

  .title-pool {
    display: flex;
    margin-top: 10px;

    h3 {
      margin-left: 10px;
      margin-top: 2px;
    }
  }

  .title-pool-i {
    position: relative;
  }

  .text-grey {
    color: $dim_grey !important;
    font-size: 13px;
    margin-top: 5px;
  }
}

