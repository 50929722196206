
.service-simple-label input[type=checkbox]  {
  display: block;
  -webkit-appearance: checkbox;
}

.service-show {
  width: 70%;
  margin: auto;
}

.service-danger-zone {
  border-radius: $component-radius;
  border: 1px solid $peach;
  box-shadow: $grey-box-shadow;
  margin: $margin-medium 0;
  padding: 5px;
  vertical-align: middle;
}

// tag checkbox for subcategory (remote, face to face)
.tag-checkbox-service {
  display: none;
  margin-left: 0.625rem;
}

.tag-checkbox-service:checked + label {
  color: white;
  background-image: $pro;
}

.tag-service {
  margin-left: 1rem;
  background-color: $grass-transparency-20;
  border-radius: 100rem;
  padding: 0.4rem 1rem;
}

details > summary {
  padding: 4px;
  margin: auto;
  position: relative;
  text-align: justify;
  /* This list-style remove the default style */
  list-style: none;
  width: 80%;
}

details > summary::after {
  /* In this class we add an arrow looking down at the extrem right */
  content: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJhbmdsZS1kb3duIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtYW5nbGUtZG93biBmYS13LTEwIiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDMyMCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTE1MS41IDM0Ny44TDMuNSAyMDFjLTQuNy00LjctNC43LTEyLjMgMC0xN2wxOS44LTE5LjhjNC43LTQuNyAxMi4zLTQuNyAxNyAwTDE2MCAyODIuN2wxMTkuNy0xMTguNWM0LjctNC43IDEyLjMtNC43IDE3IDBsMTkuOCAxOS44YzQuNyA0LjcgNC43IDEyLjMgMCAxN2wtMTQ4IDE0Ni44Yy00LjcgNC43LTEyLjMgNC43LTE3IDB6Ij48L3BhdGg+PC9zdmc+);
  color: black;
  position: absolute;
  right: 0;
  width: 8px;
  height: 8px;
  padding-right: 4px;
}

details[open] > summary::after {
  /* In this class we replace the arrow looking down by and arrow looking up */
  content: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJhbmdsZS11cCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWFuZ2xlLXVwIGZhLXctMTAiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzIwIDUxMiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMTY4LjUgMTY0LjJsMTQ4IDE0Ni44YzQuNyA0LjcgNC43IDEyLjMgMCAxN2wtMTkuOCAxOS44Yy00LjcgNC43LTEyLjMgNC43LTE3IDBMMTYwIDIyOS4zIDQwLjMgMzQ3LjhjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwzLjUgMzI4Yy00LjctNC43LTQuNy0xMi4zIDAtMTdsMTQ4LTE0Ni44YzQuNy00LjcgMTIuMy00LjcgMTcgMHoiPjwvcGF0aD48L3N2Zz4=);
}

// SERVICE SHOW
.service-show-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 0.5rem;
}

.service-show-additionnal-information {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1rem 2rem;
}

.service-show-informations-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0em 1em;
  grid-template-areas:
    ". ."
    ". .";
  margin: 0 auto;
  width: 90%;
}

// Multiple select
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  position: relative !important;
}

.select2-selection__choice {
  .select2-selection__choice__remove {
    margin: 0 8px 0 0 !important;
  }
}

.select2-search__field {
  min-width: 250px !important;
}


.grid-people-who-booked {
  display: flex;
  flex-wrap: wrap;
  justify-items: space-around;
  align-items: center;
  gap: 2rem;
  .grid-people-who-booked-item {
    flex: 1 0 21%;
    min-width: 100px;
  }
  .grid-pending-bookings {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
  }
}
