.edit-book-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: clip;

  .edit-book-controls {
    display: flex;
    height: 90%;
  }

  .section-approval {
    display: flex;
    justify-content: space-between;
    background-color: $light-apricot;
    height: 10%;
    width: 100%;

    a {
      align-items: center;
    }

    :first-child {
      margin-left: 5%;
    }

    :last-child {
      margin-right: 5%;
    }
  }

  .outline-panel {
    width: 20%;
    height: 100%;
    overflow-y: auto;
    padding: 0 0.5rem;
    min-width: 16rem;
  }

  .edit-panel {
    width: 30%;
    height: 100%;
    overflow-y: auto;
    min-width: 21rem;
  }

  .preview-panel {
    width: 50%;
    padding: 3rem 0;
    overflow-y: auto;
    background-color: $light-gray;
    justify-content: space-between;
  }

  .nothing-to-edit {
    margin: 2rem;
    background: $light-gray;
    padding: 0.5rem 1rem;
    border-radius: $component-radius-big;
    height: fit-content;
  }
}

.preview-panel {
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: inset $middle-gray 0 0 1rem 0;

  &.mobile {
    box-shadow: none;

    #page_content {
      overflow: auto;
    }
  }

  .page-preview {
    transform-origin: top left;
    height: 100%;
  }

  form {
    display: flex;
    justify-content: center;
  }

  .preview-disclaimer {
    position: absolute;
    left: 0;
    top: 0;
    background: $light_grass;
    padding: 0.2rem 1rem;
    z-index: 1;
    font-size: small;
    box-shadow: $middle-gray 0 0 1rem 0;
    text-align: center;
  }
}

form.book_content {
  .book_content_quote_2_included > .form-check {
    width: 100%;
  }

  input[type="checkbox"] {
    width: auto;
    flex-grow: 0;
    border-bottom: none;
  }
}

form.book_content {
  .book_content_quote_2_included > .form-check {
    width: 100%;
  }

  input[type="checkbox"] {
    width: auto;
    flex-grow: 0;
    border-bottom: none;
  }
}

form.book_content {
  .book_content_quote_2_included > .form-check {
    width: 100%;
  }

  input[type="checkbox"] {
    width: auto;
    flex-grow: 0;
    border-bottom: none;
  }
}

// this should be media dependent ...
.mobile-web-page-wrapper {
  #mosaic_edit {
    height: calc(100vh - 18rem); // Welcome to a world of love => this should be fixed values - will do atm
  }

  #pregnancy_photos {
    .pregnancy-list-wrapper {
      h1 {
        height: 45px;
        margin: 5px 0;
      }

      .infinite-scroll {
        height: calc(100% - 55px)
      }
    }
  }
}

.section-description {
  padding: 0 3rem;
  margin-bottom: 2rem;

  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;

  color: $dark_gray;
}
